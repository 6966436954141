import { indexSurveys } from 'utils/algolia';
import {
  createSurveyVersionObject,
  getBasicSurveyInfo,
  processQuestionGroupItem,
  processQuestionItem,
  trimMultipleLanguageContent,
} from 'utils/actions';
import { SurveyElement, SurveyVersionStatus } from 'utils/surveyBuilder/enums';

import {
  createQuestionChoices,
  createQuestionTags,
  setQuestions,
  setSurveyVersion,
} from 'services/surveys/utils';
import { rollbackOperations } from 'utils';
import { saveErrorLog } from 'services/utils';
import { ServiceOperations, ServiceSubOperations } from 'utils/enums';

export const createSurveyVersionService = async (
  { surveyData },
  { getState }
) => {
  const operationsRollbacks = [];

  const {
    pages,
    items,
    actions,
    sections,
    pagesOrder,
    versionName,
    isCondensed,
    versionTitle,
    organizationId,
    defaultLanguage,
    availableLanguages,
    disableDeselect = false,
    languageCompletion = {},
    versionCount,
    surveyId: definedSurveyId,
    versions,
  } = surveyData;

  const { locale, createdAt, surveyVersionId, surveyId, fullName } =
    getBasicSurveyInfo({
      getState,
      surveyId: definedSurveyId,
    });

  const versionTitleParsed = trimMultipleLanguageContent(versionTitle);

  const surveyVersion = createSurveyVersionObject({
    name: versionName.trim(),
    title: versionTitleParsed,
    status: SurveyVersionStatus.ACTIVE,
    pages,
    pagesOrder,
    sections,
    items,
    createdAt,
    createdBy: fullName,
    availableLanguages,
    defaultLanguage,
    isCondensed,
    languageCompletion,
    disableDeselect,
  });

  const updatedSurvey = {
    versionCount: versionCount + 1,
    versions: versions.concat([
      {
        id: surveyVersionId,
        name: surveyVersion.name,
        status: surveyVersion.status,
        createdAt,
      },
    ]),
  };

  const newActions = [...actions];

  const questions = [];
  const questionTag = [];
  const questionChoices = [];

  Object.values(items).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      processQuestionItem({
        item,
        surveyId,
        surveyVersionId,
        organizationId,
        questions,
        questionTag,
        newActions,
        questionChoices,
        isCreatingVersionFromVersion: true,
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      processQuestionGroupItem({
        item,
        surveyId,
        surveyVersionId,
        organizationId,
        questions,
        questionTag,
        newActions,
        isCreatingVersionFromVersion: true,
      });
    }
  });

  surveyVersion.actions = newActions;

  try {
    const batchRollback = await setSurveyVersion({
      surveyId,
      surveyVersionId,
      survey: updatedSurvey,
      surveyVersion,
      locale,
    });

    operationsRollbacks.push(batchRollback);

    if (questions.length) {
      const questionsRollback = await setQuestions({ questions, locale });
      operationsRollbacks.push(questionsRollback);
    }

    const createQuestionTagsRollback = await createQuestionTags({
      questionTags: questionTag,
      locale,
    });

    operationsRollbacks.push(createQuestionTagsRollback);

    if (questionChoices.length) {
      const createQuestionChoicesRollback = await createQuestionChoices({
        questionChoices,
        locale,
      });

      operationsRollbacks.push(createQuestionChoicesRollback);
    }
  } catch (error) {
    if (operationsRollbacks.length) {
      await rollbackOperations(operationsRollbacks);
    }

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_VERSION,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_VERSION]
          .CREATE_SURVEY_VERSION,
      surveyId: surveyData.surveyId ?? null,
      createdBy: fullName ?? null,
    });

    throw error;
  }

  try {
    await indexSurveys
      .partialUpdateObject({ objectID: surveyId, ...updatedSurvey })
      .wait();
  } catch (error) {
    await rollbackOperations(operationsRollbacks);

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_VERSION,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_VERSION]
          .ALGOLIA_INDEX_UPDATE,
      surveyId: surveyData.surveyId ?? null,
      createdBy: fullName ?? null,
    });

    const errorMessage = error.message;

    throw errorMessage;
  }

  return surveyVersionId;
};
