import { SurveyElement } from 'utils/surveyBuilder/enums';

const onAddTranslation = (languageToAdd, setSurvey) => {
  setSurvey((prevState) => {
    const {
      pagesOrder,
      pages,
      sections,
      items,
      availableLanguages,
      versionTitle,
    } = prevState;

    pagesOrder.forEach((pageId) => {
      const { title: pageTitle, sectionIds } = pages[pageId];

      pageTitle[languageToAdd] = '';

      sectionIds.forEach((sectionId) => {
        const { title: sectionTitle, itemIds } = sections[sectionId];

        sectionTitle[languageToAdd] = '';

        itemIds.forEach((itemId) => {
          const {
            type: itemType,
            content: itemContent,
            question,
            questionGroup,
          } = items[itemId];

          if (itemType === SurveyElement.TEXT_BOX) {
            itemContent[languageToAdd] = '';
          }

          if (itemType === SurveyElement.QUESTION) {
            const { content, choices, dataSet } = question;

            content[languageToAdd] = '';

            const choicesAreFromDataSet = !!dataSet.id;

            if (!choicesAreFromDataSet) {
              choices.forEach((_, index) => {
                choices[index].option[languageToAdd] = '';
              });
            }
          }

          if (itemType === SurveyElement.QUESTION_GROUP) {
            const { questions } = questionGroup;

            questions.forEach(({ content }) => {
              // eslint-disable-next-line no-param-reassign
              content[languageToAdd] = '';
            });
          }
        });
      });
    });

    availableLanguages.push(languageToAdd);

    return {
      ...prevState,
      versionTitle: { ...versionTitle, [languageToAdd]: '' },
    };
  });
};

export { onAddTranslation };
