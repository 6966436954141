import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Actions.module.scss';
import Action from './Action';

const Actions = ({
  actions,
  onAddAction,
  onDeleteAction,
  onChangeAction,
  questionOptions,
  questionGroupOptions,
  allQuestionsOptions,
  questionsAndGroupsOptions,
  textBoxOptions,
  pageOptions,
  sectionOptions,
}) => {
  const canDelete = useMemo(() => actions.length > 1, [actions]);

  return (
    <div className={classes.actions}>
      <div className="card-header">
        <p className="card-header-title">Actions</p>
      </div>
      <div className={classNames('card-content', classes['actions-content'])}>
        {actions.map((action, index) => {
          const key = index;
          return (
            <Action
              key={key}
              action={action}
              index={index}
              onChangeAction={onChangeAction}
              onDeleteAction={onDeleteAction}
              canDelete={canDelete}
              questionOptions={questionOptions}
              questionGroupOptions={questionGroupOptions}
              allQuestionsOptions={allQuestionsOptions}
              questionsAndGroupsOptions={questionsAndGroupsOptions}
              textBoxOptions={textBoxOptions}
              pageOptions={pageOptions}
              sectionOptions={sectionOptions}
            />
          );
        })}
        <button
          id="add-action"
          type="button"
          className="button is-white"
          onClick={onAddAction}
        >
          <span className="icon">
            <i className="mdi mdi-plus-circle-outline" />
          </span>
          <span>Add action</span>
        </button>
      </div>
    </div>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAddAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  questionOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  questionGroupOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  allQuestionsOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  questionsAndGroupsOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any)
  ).isRequired,
  textBoxOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  sectionOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
};

export default Actions;
