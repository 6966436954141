/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint "react/forbid-prop-types": "warn" */
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Creatable from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import {
  createSurveyVersion,
  selectSurveyVersion,
} from 'state/actions/surveys';
import { selectSurveyBuilderState } from 'state/selectors/surveys';
import { selectUserDataState } from 'state/selectors/auth';
import getDeploymentData from 'utils/surveyBuilder/getDeploymentData';
import { SurveyType } from 'utils/surveyBuilder/enums';
import { ModalType } from 'utils/enums';
import useLocalStorage from 'hooks/useLocalStorage';
import Button from 'components/Button';
import assignPreviewData from 'utils/surveyBuilder/assignPreviewData';

const Header = ({
  onOpenModalHandler,
  survey,
  isUpdating,
  creatingVersion,
  versions,
}) => {
  const { firstName, lastName } = useSelector(
    selectUserDataState,
    shallowEqual
  );
  const { pathname } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [_, setPreviewState] = useLocalStorage('preview', null);

  const { loading, fetched } = useSelector(
    selectSurveyBuilderState,
    shallowEqual
  );

  const onChangeVersionHandler = useCallback(
    (version) => {
      dispatch(selectSurveyVersion(version.value));
    },
    [dispatch]
  );

  const onCreateVersionHandler = useCallback(
    (versionName) => {
      dispatch(createSurveyVersion({ ...survey, versionName }));
    },
    [dispatch, survey]
  );

  const onClickPreviewHandler = () => {
    const createdAt = new Date();
    const startDate = Date.parse(new Date(-8640000000000000));

    const { waveId, surveyId, organizationId, items, sections, actions } =
      survey;

    /* eslint-disable no-undef, prefer-const */
    const itemsClone = structuredClone(items);
    const sectionsClone = structuredClone(sections);
    let newActions = structuredClone(actions);
    /* eslint-enable no-undef, prefer-const */

    const { deployment } = getDeploymentData({
      languageCompletion: {},
      createdAt,
      startDate,
      endDate: null,
      url: 'preview',
      waveStatus: null,
      firstName,
      lastName,
      ...survey,
      items: itemsClone,
      sections: sectionsClone,
    });

    assignPreviewData({
      waveId,
      surveyId,
      organizationId,
      itemsClone,
      sectionsClone,
      newActions,
      deployment,
    });

    setPreviewState(deployment);

    window.open(`${pathname}/preview`, '_blank');
  };

  return (
    <section className="hero is-hero-bar">
      <div className="hero-body">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Survey Builder</h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {params.deploymentId && (
                <div className="control">
                  <Button
                    className="is-info"
                    disabled={loading || !fetched}
                    onClick={() =>
                      onOpenModalHandler(ModalType.CHANGE_DEPLOYMENT_DATES)
                    }
                  >
                    Change Start & End Dates
                  </Button>
                </div>
              )}
              {isUpdating && params.versionId && survey.type !== null && (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label survey-builder__version-select-label">
                      Survey Version:
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field is-grouped is-grouped-multiline">
                      <div className="control survey-builder__version-select">
                        <Creatable
                          placeholder="Versions"
                          value={survey.versionSelect}
                          isDisabled={creatingVersion || loading}
                          isLoading={creatingVersion}
                          options={versions}
                          onChange={onChangeVersionHandler}
                          onCreateOption={onCreateVersionHandler}
                        />
                      </div>
                      <div
                        className="control survey-builder__version-select-help"
                        data-tip="Type a name to create a new survey version."
                      >
                        <span className="icon is-medium">
                          <i className="mdi mdi-help-circle" />
                        </span>
                      </div>
                      {survey.type === SurveyType.ORGANIZATION && (
                        <div className="control">
                          <Button
                            className="is-info"
                            onClick={() =>
                              onOpenModalHandler(
                                ModalType.CREATE_SURVEY_DEPLOYMENT
                              )
                            }
                          >
                            Deploy Version
                          </Button>
                        </div>
                      )}
                      {survey.type === SurveyType.ORGANIZATION && (
                        <div className="control">
                          <Button
                            className="is-info"
                            onClick={onClickPreviewHandler}
                          >
                            Preview Deployment
                          </Button>
                        </div>
                      )}
                      {survey.type === SurveyType.TEMPLATE && (
                        <div className="control">
                          <Button
                            className="is-info"
                            onClick={() =>
                              onOpenModalHandler(
                                ModalType.CREATE_SURVEY_FROM_TEMPLATE,
                                {
                                  surveyId: params.surveyId,
                                  versionId: params.versionId,
                                }
                              )
                            }
                          >
                            Use Template
                          </Button>
                        </div>
                      )}
                      <ReactTooltip />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  onOpenModalHandler: PropTypes.func.isRequired,
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  creatingVersion: PropTypes.bool.isRequired,
  versions: PropTypes.arrayOf(PropTypes.any),
};

export default Header;
