import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_DELETE_DEPLOYMENT_FAIL,
  SURVEYS_DELETE_DEPLOYMENT_INIT,
  SURVEYS_DELETE_DEPLOYMENT_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';

import { indexSurveys } from 'utils/algolia';
import { saveErrorLog } from 'services/utils';
import { ServiceOperations, ServiceSubOperations } from 'utils/enums';

export const deleteDeployment = ({ deploymentId, surveyId }) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_DELETE_DEPLOYMENT_INIT());

    const { locale } = getState().preferences;
    const { firstName, lastName } = getState()?.auth?.userData ?? {};

    const fullName = `${firstName} ${lastName}`;

    try {
      const deployment = (
        await firebase
          .firestore()
          .collection('surveys')
          .doc(surveyId)
          .collection('deployments')
          .doc(deploymentId)
          .get()
      ).data();

      if (deployment.totalResponses > 0) {
        const errorMessage = 'This survey cannot be removed, it has responses';
        toastr.error('', errorMessage);
        return dispatch(
          SURVEYS_DELETE_DEPLOYMENT_FAIL({
            error: errorMessage,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.DELETE_SURVEY_DEPLOYMENT,
        subOperation:
          ServiceSubOperations[ServiceOperations.DELETE_SURVEY_DEPLOYMENT]
            .DELETE_SURVEY_DEPLOYMENT,
        createdBy: fullName ?? null,
        surveyId: surveyId ?? null,
        deploymentId: deploymentId ?? null,
      });

      return dispatch(
        SURVEYS_DELETE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    const surveyDeploymentRef = firebase
      .firestore()
      .collection('surveys')
      .doc(surveyId)
      .collection('deployments')
      .doc(deploymentId);

    const surveyRef = firebase.firestore().collection('surveys').doc(surveyId);

    const surveyDeployments = [];
    const algoliaSurveyDeployments = [];
    let deploymentCount = null;

    const surveyToUpdateInAlgolia = [];

    try {
      const surveyData = await firebase
        .firestore()
        .collection('surveys')
        .doc(surveyId)
        .get();

      const survey = surveyData.data();
      deploymentCount = survey.deploymentCount - 1;
      const actualSurveyDeployments = survey.deployments;

      actualSurveyDeployments.forEach((deployment) => {
        if (deployment.id !== deploymentId) {
          surveyDeployments.push(deployment);
          algoliaSurveyDeployments.push({
            ...deployment,
            startDate: deployment.startDate.toMillis(),
            endDate: deployment.endDate?.toMillis() || null,
            createdAt: deployment.createdAt.toMillis(),
            lastTimeProcessed: deployment.lastTimeProcessed?.toMillis() || null,
          });
        }
      });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.DELETE_SURVEY_DEPLOYMENT,
        subOperation:
          ServiceSubOperations[ServiceOperations.DELETE_SURVEY_DEPLOYMENT]
            .UPDATE_SURVEY_VERSION,
        createdBy: fullName ?? null,
        surveyId: surveyId ?? null,
        deploymentId: deploymentId ?? null,
      });

      return dispatch(
        SURVEYS_DELETE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const batch = firebase.firestore().batch();

      const data = {
        deployments: surveyDeployments,
        deploymentCount,
      };

      batch.delete(surveyDeploymentRef);
      batch.update(surveyRef, data);

      const dataAlgolia = {
        deployments: algoliaSurveyDeployments,
        deploymentCount,
      };

      surveyToUpdateInAlgolia.push({
        objectID: surveyId,
        ...dataAlgolia,
      });

      await Promise.all([
        indexSurveys.partialUpdateObjects(surveyToUpdateInAlgolia).wait(),
        batch.commit(),
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.DELETE_SURVEY_DEPLOYMENT,
        subOperation:
          ServiceSubOperations[ServiceOperations.DELETE_SURVEY_DEPLOYMENT]
            .ALGOLIA_INDEX_UPDATE,
        createdBy: fullName ?? null,
        surveyId: surveyId ?? null,
        deploymentId: deploymentId ?? null
      });

      return dispatch(
        SURVEYS_DELETE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The deployment was deleted.');
    return dispatch(SURVEYS_DELETE_DEPLOYMENT_SUCCESS());
  };
};
