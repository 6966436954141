import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import {
  createSurveyResponse,
  createSurveyResponseSave,
  surveysClearState,
  updateHitsOnSurvey,
  fetchDeployment,
  fetchPreview,
  clearSurveyPreviewStorage,
  clearSurveysResponseSaveState,
  clearSurveysResponseLoadState,
} from 'state/actions/surveys';
import { setUserLocale } from 'state/actions/preferences';
import {
  SurveyElement,
  SurveyDeploymentStatus,
  QuestionType,
} from 'utils/surveyBuilder/enums';
import {
  ActionBuilderConditionTypes,
  LanguageIsoCode,
  LanguageTranslation,
  OrderTypes,
  RegularOperators,
} from 'utils/enums';
import {
  alphabeticalOrderAsc,
  alphabeticalOrderDesc,
  browserLocale,
  findLastIndexFromArray,
  mergeDemographics,
  shuffleArray,
} from 'utils';
import liveSurveyApplyAction from 'utils/LiveSurvey/actions/applyAction';
import liveSurveyRemoveAppliedAction from 'utils/LiveSurvey/actions/removeAppliedAction';
import liveSurveyMeetCondition from 'utils/LiveSurvey/actions/meetCondition';
import {
  selectLiveSurveyDataState,
  selectFetchResponseInProgressState,
} from 'state/selectors/surveys';
import { clearLiveSurveyState } from 'state/actions/liveSurvey';
import { fetchResponseInProgress } from 'state/actions/surveys/fetch/fetchResponseInProgress';
import useNewModal from 'hooks/useNewModal';
import { useChangeInputHandler } from 'hooks/LiveSurvey';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import { processShownResponses, getValidQuestions } from 'utils/LiveSurvey';
import Page from 'components/LiveSurvey/Page';
import Section from 'components/LiveSurvey/Section';
import Pagination from 'components/LiveSurvey/Pagination';
import Sections from 'components/LiveSurvey/Sections';
import PageLoader from 'components/PageLoader';
import Footer from 'components/LiveSurvey/Footer';
import ConfirmNextPageModal from 'components/LiveSurvey/Modals/ConfirmNextPageModal';

import useLastAnsweredPageGetter from './hooks/useLastAnsweredPageGetter';
import useSurveyResponseStates from './hooks/useSurveyResponse';

import classes from './LiveSurvey.module.scss';

const randomizeQuestionChoices = (items) =>
  items.map((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { choicesOrder } = item.question;
      let { choices } = item.question;

      if (choicesOrder === OrderTypes.RANDOM) {
        choices = shuffleArray([...choices]);
      }

      return { ...item, question: { ...item.question, choices } };
    }

    return item;
  });

const randomizeItems = (items) => {
  if (items[0].type === SurveyElement.TEXT_BOX) {
    return [items[0], ...shuffleArray([...items.slice(1)])];
  }
  return shuffleArray([...items]);
};

const randomizeItemsAndChoices = (section) => {
  let items = section?.items;

  if (items.length > 0) {
    if (section.randomize) {
      items = randomizeItems(items);
    }

    items = randomizeQuestionChoices(items);
  }

  return items;
};

const orderQuestionChoicesAlphabetically = (section, surveyLanguage) => {
  let items = section?.items;

  items = items.map((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { choicesOrder } = item.question;
      let { choices } = item.question;

      if (choicesOrder === OrderTypes.ASC) {
        choices = alphabeticalOrderAsc([...choices], surveyLanguage);
      }

      if (choicesOrder === OrderTypes.DESC) {
        choices = alphabeticalOrderDesc([...choices], surveyLanguage);
      }

      return { ...item, question: { ...item.question, choices } };
    }

    return item;
  });

  return items;
};

const LiveSurvey = () => {
  const { deploymentUrl } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isPreviewPath = pathname.split('/').at(-1) === 'preview';

  const {
    respondedSurvey,
    creatingResponse,
    fetchingSurvey,
    errorFetching,
    fetchedSurvey,
    surveyPages,
    availableLanguages,
  } = useSelector(
    selectLiveSurveyDataState({ isPreview: isPreviewPath }),
    shallowEqual
  );

  const { responseInProgress, success } = useSelector(
    selectFetchResponseInProgressState
  );

  const [validateQuestions, setValidateQuestions] = useState(false);
  const [surveyLanguage, setSurveyLanguage] = useState({
    code: LanguageIsoCode.EN,
    select: {
      label: LanguageTranslation[LanguageIsoCode.EN],
      code: LanguageIsoCode.EN,
    },
  });

  const fetchedOrganizationName = useMemo(
    () => fetchedSurvey?.organizationName || null,
    [fetchedSurvey?.organizationName]
  );

  const {
    pagesWithActionsStateDispatcher,
    questionResponsesStateDispatcher,
    organizationNameStateDispatcher,
    pageIndexStateDispatcher,
    responseStateDispatcher,
    getQuestionsDemographicsState,
    getQuestionGroupsDemographicsState,
    isPreviewStateDispatcher,
  } = useSurveyResponseStates({
    surveyPages: surveyPages || [],
    questionResponses: null,
    organizationName: fetchedOrganizationName,
    pageIndex: 0,
    response: {
      valid: false,
      waveId: null,
      surveyId: null,
      deploymentId: null,
    },
    isPreview: isPreviewPath,
  });

  const [pagesWithActions, setPagesWithActions] =
    pagesWithActionsStateDispatcher;
  const [questionResponses, setQuestionResponses] =
    questionResponsesStateDispatcher;
  const [pageIndex, setPageIndex] = pageIndexStateDispatcher;
  const [organizationName, setOrganizationName] =
    organizationNameStateDispatcher;
  const [response, setResponse] = responseStateDispatcher;
  const [isPreview, setIsPreview] = isPreviewStateDispatcher;

  const [surveySections, setSurveySections] = useState([]);
  const [surveyActions, setSurveyActions] = useState(null);
  const [visiblePages, setVisiblePages] = useState(surveyPages || []);
  const [previewIsAnswered, setPreviewIsAnswered] = useState(false);

  const topOfThePage = useRef(null);

  const onChangeInputHandler = useChangeInputHandler(setQuestionResponses);

  const {
    isOpen: confirmNextPageModalIsOpen,
    toggle: confirmNextPageModalToggle,
  } = useNewModal();

  // clean up of stalled data before load new data
  useLayoutEffect(() => {
    dispatch(clearSurveyPreviewStorage());
    dispatch(clearSurveysResponseLoadState());
    dispatch(clearSurveysResponseSaveState());
    dispatch(clearLiveSurveyState());
    dispatch(surveysClearState());
  }, [dispatch]);

  useEffect(() => {
    setIsPreview(isPreviewPath);
  }, [isPreviewPath]);

  useEffect(() => {
    if (organizationName !== fetchedOrganizationName) {
      setOrganizationName(fetchedOrganizationName);
    }
  }, [fetchedOrganizationName, organizationName]);

  useEffect(() => {
    if (pageIndex >= 0) {
      if (topOfThePage.current?.scrollIntoView) {
        topOfThePage.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [pageIndex]);

  useEffect(() => {
    document.documentElement.className = '';
    document.body.className = '';

    return () => {
      document.documentElement.className =
        'has-aside-left has-aside-mobile-transition has-aside-expanded';

      document.body.className = 'has-navbar-fixed-top';
    };
  }, []);

  useEffect(() => {
    if (!isPreview && fetchedSurvey) {
      dispatch(updateHitsOnSurvey(fetchedSurvey.surveyId, fetchedSurvey.id));
    }
  }, [isPreview, fetchedSurvey]);

  useEffect(() => {
    if (isPreview || isPreviewPath) {
      dispatch(fetchPreview());
    } else {
      dispatch(fetchDeployment(deploymentUrl));
    }
  }, [dispatch, deploymentUrl, isPreview, isPreviewPath]);

  useEffect(() => {
    if (fetchedSurvey) {
      const questions = {};

      const notParsedQuestions = [];

      const validItems = getValidQuestions(fetchedSurvey);

      Object.values(fetchedSurvey.items).forEach((item) => {
        if (item.type === SurveyElement.QUESTION) {
          const { question, visible, id } = item;

          notParsedQuestions.push({
            question: { ...question, visible, id },
          });
        }

        if (item.type === SurveyElement.QUESTION_GROUP) {
          const {
            id,
            questionGroup: { questions: q, choices },
          } = item;

          q.forEach((question) => {
            notParsedQuestions.push({
              question: {
                ...question,
                choices,
                group: id,
              },
            });
          });
        }
      });

      notParsedQuestions.forEach(({ question }) => {
        const choices = {};
        const openEndedChoices = {};
        const openEndedChoiceAnswers = {};

        question.choices.forEach((choice) => {
          if (choice.questionChoices) {
            return choice.questionChoices.forEach((questionChoice) => {
              openEndedChoices[choice.dataSetId] = {
                ...(openEndedChoices?.[choice.dataSetId] || {}),
                [questionChoice.id]: questionChoice.openEnded,
              };

              openEndedChoiceAnswers[choice.dataSetId] = {
                ...(openEndedChoiceAnswers?.[choice.dataSetId] || {}),
                [questionChoice.id]: '',
              };

              choices[choice.dataSetId] = {
                ...(choices?.[choice.dataSetId] || {}),
                [questionChoice.id]: false,
              };
              choices[choice.dataSetId][questionChoice.id] = false;
            });
          }

          openEndedChoices[choice.id] = choice.openEnded;
          openEndedChoiceAnswers[choice.id] = '';

          if (question.type === 'rank-order') {
            choices[choice.id] = null;
          } else {
            choices[choice.id] = false;
          }

          return null;
        });

        if (
          validItems[question.id] ||
          (question.group && validItems[question.group])
        ) {
          questions[question.id] = {
            type: question.type,
            choices,
            openEndedChoices,
            openEndedChoiceAnswers,
            openAnswer: '',
            value: question.value ?? null,
            required: question.required,
            visible: question.visible,
            sliderProps: question.sliderProps
              ? {
                  ...question.sliderProps,
                  value:
                    question.sliderProps.defaultValue ??
                    question.sliderProps.min,
                }
              : null,
            regionalBreakdown: question.regionalBreakdown || false,
            group: question.group || null,
          };
        }
      });

      const surveyHasUserLanguage = fetchedSurvey.availableLanguages.some(
        (code) =>
          code === browserLocale &&
          fetchedSurvey.languageCompletion[code] === 100
      );

      setSurveyActions(fetchedSurvey.actions);
      setResponse((prevState) => ({
        ...prevState,
        waveId: fetchedSurvey.waveId,
        surveyId: fetchedSurvey.surveyId,
        deploymentId: fetchedSurvey.id,
      }));
      setQuestionResponses(questions);
      setSurveyLanguage((prevState) => ({
        ...prevState,
        code: surveyHasUserLanguage
          ? browserLocale
          : fetchedSurvey.defaultLanguage,
        select: surveyHasUserLanguage
          ? {
              label: LanguageTranslation[browserLocale],
              value: browserLocale,
            }
          : {
              label: LanguageTranslation[fetchedSurvey.defaultLanguage],
              value: fetchedSurvey.defaultLanguage,
            },
      }));
    }
  }, [fetchedSurvey]);

  useEffect(() => {
    const preventUserFromLeaving = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const handleBeforeUnload = (e) => {
      if (!isPreview && !respondedSurvey) {
        preventUserFromLeaving(e);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isPreview, respondedSurvey]);

  useEffect(() => {
    if (surveyPages) {
      const pages = [...surveyPages];
      setPagesWithActions(pages);
    }
  }, [surveyPages.length]);

  useEffect(() => {
    const visible = [];

    pagesWithActions.forEach((page) => {
      if (page.visible) {
        visible.push({ ...page });
      }
    });

    setVisiblePages(visible);
  }, [pagesWithActions]);

  useEffect(() => {
    const sections =
      visiblePages[pageIndex]?.sections.map((section) => {
        const items = randomizeItemsAndChoices(section);
        return { ...section, items };
      }) || [];

    const modifiedSections = sections.map((section) => {
      const items = orderQuestionChoicesAlphabetically(
        section,
        surveyLanguage.code
      );
      return { ...section, items };
    });

    setSurveySections(modifiedSections);
  }, [pageIndex, visiblePages]);

  useEffect(() => {
    const languageCode = surveyLanguage.code.toLowerCase().replace('-', '');

    dispatch(setUserLocale(languageCode));
  }, [surveyLanguage.code]);

  const removeSelectedOptions = useCallback(
    (questionsId) => {
      const allQuestionsIds = [];

      questionsId.forEach((element) => {
        if (Array.isArray(element)) {
          allQuestionsIds.push(...element);
        } else {
          allQuestionsIds.push(element);
        }
      });

      allQuestionsIds.forEach((questionId) => {
        const { choices: prevChoices } = questionResponses[questionId];
        const choices = {};

        Object.keys(prevChoices).forEach((key) => {
          if (prevChoices[key] === true || prevChoices[key] === undefined) {
            choices[key] = false;
          } else {
            Object.keys(prevChoices[key]).forEach((subKey) => {
              if (!choices[key]) {
                choices[key] = {};
              }

              choices[key][subKey] = false;
            });
          }
        });

        setQuestionResponses((prevState) => ({
          ...prevState,
          [questionId]: {
            ...questionResponses[questionId],
            choices,
          },
        }));
      });
    },
    [questionResponses]
  );

  const getQuestionsIdFromPage = useCallback(
    (pageId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        if (page.id === pageId) {
          page.sections.forEach((section) => {
            section.items.forEach(({ question, questionGroup }) => {
              if (question) {
                questionsIds.push(question.id);
              }
              if (questionGroup) {
                questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            });
          });
        }
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const getQuestionsIdFromSection = useCallback(
    (sectionId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        page.sections.forEach((section) => {
          if (section.id === sectionId) {
            section.items.forEach(({ question, questionGroup }) => {
              if (question) {
                questionsIds.push(question.id);
              }
              if (questionGroup) {
                questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            });
          }
        });
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const getQuestionsIdFromItem = useCallback(
    (itemId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        page.sections.forEach((section) => {
          section.items.forEach((item) => {
            if (item.id === itemId) {
              if (item.question) {
                questionsIds.push(item.question.id);
              }
              if (item.questionGroup) {
                item.questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            }
          });
        });
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const meetCondition = useCallback(
    (condition) => {
      liveSurveyMeetCondition(condition, questionResponses);
    },
    [questionResponses]
  );

  const meetsAllOrConditions = useCallback(
    (conditions) => {
      return conditions.some((condition) => meetCondition(condition));
    },
    [meetCondition]
  );

  const applyAction = useCallback(
    (actionToApply) => {
      liveSurveyApplyAction(
        actionToApply,
        pagesWithActions,
        setPagesWithActions
      );
    },
    [pagesWithActions]
  );

  const removeAppliedAction = useCallback(
    (actionToRemove) => {
      liveSurveyRemoveAppliedAction(
        actionToRemove,
        pagesWithActions,
        surveySections,
        setPagesWithActions,
        removeSelectedOptions,
        getQuestionsIdFromSection,
        getQuestionsIdFromPage,
        getQuestionsIdFromItem
      );
    },
    [
      surveySections,
      pagesWithActions,
      getQuestionsIdFromSection,
      getQuestionsIdFromItem,
      getQuestionsIdFromPage,
      removeSelectedOptions,
    ]
  );

  useEffect(() => {
    if (surveyActions && questionResponses && surveySections) {
      surveyActions.forEach(({ conditions, actions, applied }, indexAction) => {
        const separatedConditions = {};
        let index = 0;

        conditions.forEach((condition) => {
          if (condition.type === ActionBuilderConditionTypes.CONDITION) {
            let previousConditions = [];
            if (separatedConditions[index]) {
              previousConditions = [...separatedConditions[index]];
            }
            previousConditions.push(condition);
            separatedConditions[index] = previousConditions;
          } else if (
            RegularOperators[condition.operator] === RegularOperators.AND
          ) {
            index += 1;
          }
        });

        const meetsAndConditions = Object.values(separatedConditions).every(
          (orConditions) => meetsAllOrConditions(orConditions)
        );

        if (meetsAndConditions && !applied) {
          actions.forEach((action) => applyAction(action));
          const newActions = [...surveyActions];
          newActions[indexAction].applied = true;
          setSurveyActions(newActions);
        } else if (!meetsAndConditions && applied) {
          actions.forEach((action) => removeAppliedAction(action));
          const newActions = [...surveyActions];
          newActions[indexAction].applied = false;
          setSurveyActions(newActions);
        }
      });
    }
  }, [
    surveyActions,
    surveySections,
    questionResponses,
    meetsAllOrConditions,
    applyAction,
    removeAppliedAction,
  ]);

  const areRequiredChoiceQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            (question.type === QuestionType.SINGLE_RESPONSE ||
              question.type === QuestionType.MULTIPLE_RESPONSE ||
              question.type === QuestionType.DROPDOWN)
        )
        .every((requiredQuestion) => {
          const choicesValues = Object.values(requiredQuestion.choices);

          if (
            choicesValues.some(
              (choiceValue) => typeof choiceValue === 'boolean'
            )
          ) {
            return choicesValues.some((choiceValue) => choiceValue);
          }

          return choicesValues.every((choiceValue) =>
            Object.values(choiceValue).some((choice) => choice)
          );
        })
    );
  };

  const areRequiredRankOrderQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.RANK_ORDER
        )
        .every((requiredQuestion) =>
          Object.values(requiredQuestion.choices).every((choice) => choice)
        )
    );
  };

  const areRequiredTextAreaQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.TEXT_AREA
        )
        .every((requiredQuestion) => requiredQuestion.openAnswer !== '')
    );
  };

  const areRequiredSliderQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.SLIDER
        )
        .every((requiredQuestion) =>
          Number.isInteger(requiredQuestion.sliderProps?.value)
        )
    );
  };

  // Returns an array with the id of the required questions
  // Each position of the array correspond to each page of the survey
  const getRequiredQuestionsPerPage = () => {
    const requiredQuestions = [];

    visiblePages.forEach((page, index) => {
      page.sections.forEach((section) => {
        if (section.visible) {
          const questions = section.items
            .filter(
              (item) =>
                item.type !== QuestionType.TEXT_BOX &&
                item.question?.required &&
                item.visible
            )
            .map((item) => item.id);

          const questionsInGroup = [];
          section.items
            .filter(
              (item) =>
                item.type !== QuestionType.TEXT_BOX &&
                item.questionGroup &&
                item.visible
            )
            .forEach(({ questionGroup }) =>
              questionGroup.questions.forEach((question) => {
                if (question.required && question.visible)
                  questionsInGroup.push(question.id);
              })
            );

          requiredQuestions[index] = [
            ...(requiredQuestions[index] ? requiredQuestions[index] : []),
            ...questions,
            ...questionsInGroup,
          ];
        }
      });
    });

    return requiredQuestions;
  };

  // Returns the number of pages that the survey will show in the pagination
  // depending on if the required questions of the current page are answered or not.
  const getAnsweredPages = () => {
    let pagesToDisplay = 1;
    const requiredQuestionsPerPage = getRequiredQuestionsPerPage();

    for (let index = 0; index < requiredQuestionsPerPage.length; index += 1) {
      const currentPageQuestions = requiredQuestionsPerPage[index];

      if (currentPageQuestions) {
        const currentPageResponses = {};

        currentPageQuestions.forEach((question) => {
          const questionResponse = { [question]: questionResponses[question] };
          Object.assign(currentPageResponses, questionResponse);
        });

        const areCurrentPageQuestionsAnswered =
          areRequiredChoiceQuestionsAnswered(currentPageResponses) &&
          areRequiredTextAreaQuestionsAnswered(currentPageResponses);

        if (!areCurrentPageQuestionsAnswered) {
          return pagesToDisplay;
        }

        if (areCurrentPageQuestionsAnswered) {
          if (pagesToDisplay < visiblePages.length) {
            pagesToDisplay += 1;
          }
        }
      }
    }

    return pagesToDisplay;
  };

  // Returns an array of the questions ids that are required
  // and are not answered in the current page of the survey
  const getNotAnsweredQuestionsInCurrentPage = () => {
    const requiredQuestionsPerPage = getRequiredQuestionsPerPage();
    const currentPageQuestions = requiredQuestionsPerPage[pageIndex] || [];
    const notAnsweredQuestions = [];

    currentPageQuestions.forEach((question) => {
      const questionResponse = { [question]: questionResponses[question] };
      let isAnswered = false;

      if (questionResponse) {
        const { type: questionType } = questionResponse[question];

        if (questionType === QuestionType.RANK_ORDER) {
          isAnswered = areRequiredRankOrderQuestionsAnswered(questionResponse);
        }

        if (questionType === QuestionType.TEXT_AREA) {
          isAnswered = areRequiredTextAreaQuestionsAnswered(questionResponse);
        }

        if (questionType === QuestionType.SLIDER) {
          isAnswered = areRequiredSliderQuestionsAnswered(questionResponse);
        }

        if (
          questionType === QuestionType.SINGLE_RESPONSE ||
          questionType === QuestionType.MULTIPLE_RESPONSE ||
          questionType === QuestionType.DROPDOWN
        ) {
          isAnswered = areRequiredChoiceQuestionsAnswered(questionResponse);
        }
      }

      if (!isAnswered) {
        notAnsweredQuestions.push(question);
      }
    });

    return notAnsweredQuestions;
  };

  const disablePreviousPagesGoBackFrom = findLastIndexFromArray(
    visiblePages || [],
    (page, index) => page?.preventBacktracking && index < pageIndex
  );

  const disableNextPagesGoAheadOf = visiblePages.findIndex(
    (page, index) => page?.preventBacktracking && index > pageIndex
  );

  const onPreviousPageHandler = () => {
    if (pageIndex - 1 <= disablePreviousPagesGoBackFrom) {
      return null;
    }

    return setPageIndex((prevState) => prevState - 1);
  };

  const onNextPageHandler = () => {
    setValidateQuestions(true);

    if (getNotAnsweredQuestionsInCurrentPage().length === 0) {
      setValidateQuestions(false);

      if (visiblePages[pageIndex].preventBacktracking) {
        return confirmNextPageModalToggle();
      }

      return setPageIndex((prevState) => prevState + 1);
    }

    return null;
  };

  const onConfirmNextPage = () => {
    setPageIndex((prevState) => prevState + 1);

    confirmNextPageModalToggle();
  };

  const onGoToPageHandler = (index) => setPageIndex(index);

  const checkRequiredQuestionsAnswers = () =>
    areRequiredChoiceQuestionsAnswered(questionResponses) &&
    areRequiredTextAreaQuestionsAnswered(questionResponses) &&
    areRequiredRankOrderQuestionsAnswered(questionResponses) &&
    areRequiredSliderQuestionsAnswered(questionResponses);

  const onSubmitSurveyHandler = (event, saveResponse) => {
    // eslint-disable-next-line no-unused-expressions
    event?.preventDefault();

    if (!saveResponse) {
      setValidateQuestions(true);
    }

    const areRequiredQuestionsAnswered = checkRequiredQuestionsAnswers();

    const canSubmit =
      areRequiredQuestionsAnswered && visiblePages.length - 1 === pageIndex;

    if (saveResponse || canSubmit) {
      const demographics = mergeDemographics(
        getQuestionsDemographicsState(),
        getQuestionGroupsDemographicsState()
      );

      const shownResponses = processShownResponses({
        pagesWithActions,
        questionResponses,
      });

      if (isPreview) {
        setPreviewIsAnswered(true);

        return;
      }

      if (saveResponse) {
        dispatch(
          createSurveyResponseSave({
            ...response,
            pagesWithActions,
            questionResponses,
            shownResponses,
            demographics,
            organizationName,
            pageIndex,
          })
        );

        return;
      }

      dispatch(
        createSurveyResponse({
          ...response,
          questionResponses: shownResponses,
          demographics,
          organizationName,
        })
      );
    }
  };

  const deploymentStatus = getDeploymentStatus(
    fetchedSurvey?.startDate,
    fetchedSurvey?.endDate,
    fetchedSurvey?.waveStatus
  );

  const { getLastAnsweredPageIndex } = useLastAnsweredPageGetter({
    props: { visiblePages },
    helpers: {
      areRequiredChoiceQuestionsAnswered,
      areRequiredTextAreaQuestionsAnswered,
    },
  });

  useEffect(() => {
    if (success) {
      const {
        valid,
        waveId,
        surveyId,
        deploymentId,
        pageIndex: savedResponsePageIndex,
        questionResponses: savedQuestionResponses,
      } = responseInProgress;

      setResponse({ valid, waveId, surveyId, deploymentId });
      setPageIndex(savedResponsePageIndex);

      const validQuestions = getValidQuestions(fetchedSurvey);

      const validSavedQuestionResponses = {};

      Object.entries(savedQuestionResponses).forEach(
        ([questionId, questionResponse]) => {
          if (validQuestions[questionId]) {
            validSavedQuestionResponses[questionId] = questionResponse;
          }
        }
      );

      setQuestionResponses(validSavedQuestionResponses);

      onGoToPageHandler(
        savedResponsePageIndex ||
          getLastAnsweredPageIndex(savedQuestionResponses)
      );
      dispatch(clearSurveysResponseLoadState());
    }
  }, [success, responseInProgress]);

  const onSaveResponseHandler = () => {
    onSubmitSurveyHandler(null, true);
  };

  const onLoadResponseHandler = (responseCode) => {
    dispatch(fetchResponseInProgress({ responseCode }));
  };

  useEffect(() => () => dispatch(clearSurveysResponseSaveState()), [dispatch]);

  const shouldShowPages =
    ((!isPreview && !respondedSurvey) || (isPreview && !previewIsAnswered)) &&
    questionResponses &&
    deploymentStatus === SurveyDeploymentStatus.ACTIVE;

  return (
    <>
      <ConfirmNextPageModal
        isOpen={confirmNextPageModalIsOpen}
        toggle={confirmNextPageModalToggle}
        onSubmit={onConfirmNextPage}
      />
      <div className={classes.survey}>
        <PageLoader loading={fetchingSurvey} background="#0072bc" />
        {!fetchingSurvey && (
          <section className="section" ref={topOfThePage}>
            <form onSubmit={onSubmitSurveyHandler}>
              {shouldShowPages && (
                <Page
                  id="survey-page"
                  title={fetchedSurvey.title[surveyLanguage.code]}
                  subtitle={visiblePages[pageIndex]?.title[surveyLanguage.code]}
                  selectedLanguage={surveyLanguage.select}
                  availableLanguages={availableLanguages}
                  setSurveyLanguage={setSurveyLanguage}
                  onSaveResponse={onSaveResponseHandler}
                  onLoadResponse={onLoadResponseHandler}
                  languageCompletion={fetchedSurvey?.languageCompletion}
                  defaultLanguage={fetchedSurvey?.defaultLanguage}
                >
                  <Sections
                    fetchedSurvey={fetchedSurvey}
                    surveySections={surveySections}
                    surveyLanguage={surveyLanguage}
                    validateQuestions={validateQuestions}
                    questionResponses={questionResponses}
                    setQuestionResponses={setQuestionResponses}
                    onChangeInputHandler={onChangeInputHandler}
                    getNotAnsweredQuestionsInCurrentPage={
                      getNotAnsweredQuestionsInCurrentPage
                    }
                    disableDeselect={fetchedSurvey?.disableDeselect}
                  />
                  <Pagination
                    loading={creatingResponse}
                    pageIndex={pageIndex}
                    actualPages={getAnsweredPages()}
                    onNextPage={onNextPageHandler}
                    onPreviousPage={onPreviousPageHandler}
                    onGoToPage={onGoToPageHandler}
                    disablePreviousPagesGoBackFrom={
                      disablePreviousPagesGoBackFrom
                    }
                    disableNextPagesGoAheadOf={disableNextPagesGoAheadOf}
                    totalPages={visiblePages.length}
                  />
                </Page>
              )}
              {(respondedSurvey || (isPreview && previewIsAnswered)) && (
                <Page
                  id="thank-you-message"
                  title={fetchedSurvey.title[surveyLanguage.code]}
                  subtitle="Thank you!"
                >
                  <Section>
                    <p className="content is-medium">
                      Thank you for taking the time to fill out the survey. Your
                      time and attention to detail is greatly appreciated.
                    </p>
                  </Section>
                  <Section title="Comments">
                    <p className="content is-medium">
                      We&apos;d love to hear from you. Let us know how you found
                      the survey process or how you think we can improve it. If
                      you&apos;d like to get in touch with us, please email us
                      at{' '}
                      <a href="mailto: abc@example.com">
                        comments@presagegroup.com
                      </a>
                      . Please note that all comments are strictly anonymous and
                      confidential and are not included in the analysis of the
                      survey data.
                    </p>
                  </Section>
                </Page>
              )}
              {errorFetching && (
                <Page id="not-found-survey-message" title="Survey not found">
                  <p className="content is-medium">
                    The survey you are trying to access does not exist. If you
                    think you are receiving this message in error, please
                    contact your manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.PENDING && (
                <Page
                  id="survey-not-available-message"
                  title="Survey not available"
                >
                  <p className="content is-medium">
                    The survey you are trying to access is not yet available to
                    be responded. If you think you are receiving this message in
                    error, please contact your manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.CLOSED && (
                <Page id="survey-closed-message" title="Survey closed">
                  <p className="content is-medium">
                    The survey you are trying to access is closed. If you think
                    you are receiving this message in error, please contact your
                    manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.INACTIVE && (
                <Page id="survey-closed-message" title="Survey inactive">
                  <p className="content is-medium">
                    The survey you are trying to access is inactive. If you
                    think you are receiving this message in error, please
                    contact your manager or system administrator.
                  </p>
                </Page>
              )}
            </form>
            <Footer />
          </section>
        )}
      </div>
    </>
  );
};

export default LiveSurvey;
