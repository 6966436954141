import { isString } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';

const onUpdateTranslation = (
  {
    languageCode,
    elementType,
    elementId,
    updatedTranslation,
    choiceId,
    questionChoiceId,
  },
  setSurvey
) => {
  setSurvey((prevState) => {
    const { pages, sections, items } = prevState;

    if (elementType === SurveyElement.SURVEY_TITLE) {
      const { versionTitle } = prevState;

      return {
        ...prevState,
        versionTitle: {
          ...versionTitle,
          [languageCode]: updatedTranslation,
        },
      };
    }

    if (elementType === SurveyElement.PAGE) {
      const { title: pageTitle } = pages[elementId];

      pageTitle[languageCode] = updatedTranslation;

      return { ...prevState };
    }

    if (elementType === SurveyElement.SECTION) {
      const { title: sectionTitle } = sections[elementId];

      sectionTitle[languageCode] = updatedTranslation;

      return { ...prevState };
    }

    if (elementType === SurveyElement.TEXT_BOX) {
      const { richText: textBoxRichText } = items[elementId];

      textBoxRichText[languageCode] = updatedTranslation;

      return { ...prevState };
    }

    if (elementType === SurveyElement.QUESTION) {
      const { richText: questionRichText } = items[elementId].question;

      questionRichText[languageCode] = updatedTranslation;

      return { ...prevState };
    }

    if (elementType === SurveyElement.QUESTION_CHOICE) {
      const { choices } =
        items[elementId].question || items[elementId].questionGroup;

      if (choices[choiceId].questionChoices) {
        choices[choiceId].questionChoices[questionChoiceId].option[
          languageCode
        ] = updatedTranslation;
      } else {
        choices[choiceId].option[languageCode] = updatedTranslation;
      }

      return { ...prevState };
    }

    if (elementType === SurveyElement.QUESTION_CHOICE_DATASET_TITLE) {
      const { itemId, dataSetId } = elementId;

      const { dataSet } = items[itemId].questionGroup;
      const dataSetIndex = dataSet.findIndex((set) => set.id === dataSetId);

      if (
        isString(dataSet[dataSetIndex].title) ||
        !typeof dataSet[dataSetIndex].title
      ) {
        const newTitle = {
          [prevState.defaultLanguage]: dataSet[dataSetIndex].title || '',
          [languageCode]: updatedTranslation,
        };

        dataSet[dataSetIndex].title = newTitle;

        items[itemId].questionGroup.choices = items[
          itemId
        ].questionGroup.choices.map((choice) => ({
          ...choice,
          title: choice.dataSetId === dataSetId ? newTitle : choice.title,
        }));
      } else {
        dataSet[dataSetIndex].title[languageCode] = updatedTranslation;
        items[itemId].questionGroup.choices = items[
          itemId
        ].questionGroup.choices.map((choice) => ({
          ...choice,
          title:
            choice.dataSetId === dataSetId
              ? {
                  ...choice.title,
                  [languageCode]: updatedTranslation,
                }
              : choice.title,
        }));
      }

      return { ...prevState };
    }

    if (elementType === SurveyElement.QUESTION_FROM_QUESTION_GROUP) {
      const { itemId, questionIndex } = elementId;
      const { questions } = items[itemId].questionGroup;
      questions[questionIndex].content[languageCode] = updatedTranslation;

      return { ...prevState };
    }

    return { ...prevState };
  });
};

export { onUpdateTranslation };
