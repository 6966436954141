/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  createDeployment,
  updateDeploymentStartAndEnd,
  createOrganizationSurveyFromTemplate,
} from 'state/actions/surveys';
import { selectSurveyBuilderState } from 'state/selectors/surveys';
import { clearQuestionChoiceState } from 'state/actions/questionChoices';
import { ModalType } from 'utils/enums';
import {
  getItem,
  isChoiceInActions,
  isNotItemInActions,
} from 'utils/surveyBuilder';
import {
  onAddTranslation,
  onRemoveTranslation,
  onUpdateTranslation,
} from 'utils/surveyBuilder/translations';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import QuestionBuilder from 'components/SurveyBuilder/QuestionBuilder';
import Modal from 'components/Modal';
import ActionBuilderModal from 'components/ActionBuilderModal';
import CreateDeploymentModal from 'components/SurveyBuilder/CreateDeploymentModal';
import SurveyFromTemplateModal from 'components/SurveyFromTemplateModal';
import DeploymentsModal from 'components/SurveyBuilder/DeploymentsModal';
import Translations from 'components/SurveyBuilder/Translations';
import QuestionGroupBuilder from 'components/SurveyBuilder/QuestionGroupBuilder';
import CopySurveyModal from '../CopySurveyModal';
import ChangeDeploymentDatesModal from '../ChangeDeploymentDatesModal';

const Modals = ({
  survey,
  setSurvey,
  modal,
  modalType,
  params,
  deploymentId,
  onCloseModalHandler,
  defaultTranslation,
  translations,
  questionItems,
  questionGroupItems,
  textBoxItems,
}) => {
  const dispatch = useDispatch();

  const { creatingFromTemplate } = useSelector(
    selectSurveyBuilderState,
    shallowEqual
  );

  const onDeleteSectionHandler = useCallback(
    (pageId, sectionIndex) => {
      setSurvey((prevState) => {
        const { pages, sections } = prevState;

        const page = pages[pageId];
        const sectionIds = page.sectionIds.toSpliced(sectionIndex, 1);

        const {
          [page.sectionIds[sectionIndex]]: sectionToDelete,
          ...sectionsRest
        } = sections;

        return {
          ...prevState,
          pages: {
            ...pages,
            [pageId]: {
              ...page,
              sectionIds,
            },
          },
          sections: sectionsRest,
        };
      });
    },
    [setSurvey]
  );

  const onDeletePageHandler = useCallback(
    (pageIndex) => {
      setSurvey((prevState) => {
        const { pagesOrder, pages } = prevState;
        const newPagesOrder = pagesOrder.toSpliced(pageIndex, 1);

        const { [pagesOrder[pageIndex]]: pageToDelete, ...pagesRest } = pages;

        return { ...prevState, pages: pagesRest, pagesOrder: newPagesOrder };
      });
    },
    [setSurvey]
  );

  const onConfirmationDeleteSectionModalHandler = useCallback(() => {
    const { sectionIndex, pageId } = modal;
    onDeleteSectionHandler(pageId, sectionIndex);
    onCloseModalHandler();
  }, [modal, onDeleteSectionHandler, onCloseModalHandler]);

  const onConfirmationDeletePageModalHandler = useCallback(() => {
    const { pageIndex } = modal;
    onDeletePageHandler(pageIndex);
    onCloseModalHandler();
  }, [modal, onDeletePageHandler, onCloseModalHandler]);

  const canDeleteQuestion = useCallback(
    (questionId) =>
      survey?.actions.every((action) => isNotItemInActions(action, questionId)),
    [survey]
  );

  const onAddQuestionHandler = (question) => {
    setSurvey((prevState) => {
      const { sections, items, availableLanguages, isCondensed } = prevState;

      const { itemIndex, sectionId } = modal;

      const section = sections[sectionId];

      const item = getItem({
        itemType: SurveyElement.QUESTION,
        availableLanguages,
        condensed: isCondensed,
        itemId: question.id,
      });

      section.itemIds.splice(itemIndex, 0, item.id);

      return {
        ...prevState,
        sections,
        items: {
          ...items,
          [item.id]: {
            ...item,
            question,
          },
        },
      };
    });

    onCloseModalHandler();

    dispatch(clearQuestionChoiceState());
  };

  const canDeleteChoice = useCallback(
    (choiceId) => {
      const { itemId } = modal;
      const choiceHasNoActions = survey?.actions?.every(
        ({ actions, conditions }) => {
          return isChoiceInActions({ actions, conditions }, itemId, choiceId);
        }
      );
      if (choiceHasNoActions) {
        return true;
      }
      return false;
    },
    [modal, survey]
  );

  const onUpdateQuestionHandler = useCallback(
    (question) => {
      const newChoices = question.choices.map((choice) =>
        choice.newChoice ? { ...choice, newChoice: false } : choice
      );

      const newQuestion = {
        ...question,
        choices: newChoices,
      };

      setSurvey((prevState) => {
        const { itemId } = modal;

        const { sections, items } = prevState;

        onCloseModalHandler();

        return {
          ...prevState,
          sections,
          items: {
            ...items,
            [itemId]: {
              ...items[itemId],
              question: newQuestion,
            },
          },
        };
      });
    },
    [setSurvey, modal, onCloseModalHandler]
  );

  const onCreateDeploymentHandler = useCallback(
    (deployment) => {
      dispatch(createDeployment(survey, { ...survey, ...deployment }));
    },
    [dispatch, survey]
  );

  const onUpdateDeploymentStartAndEndHandler = useCallback(
    (deploymentData) => {
      dispatch(updateDeploymentStartAndEnd(deploymentData));
    },
    [dispatch]
  );

  const onEditActionHandler = useCallback(
    (editedAction) => {
      setSurvey((prevState) => {
        const newActions = [...prevState.actions];
        const index = newActions.findIndex(
          (action) => action.id === editedAction.id
        );
        newActions.splice(index, 1, editedAction);
        return { ...prevState, actions: newActions };
      });
    },
    [setSurvey]
  );

  const onDeleteActionHandler = useCallback(
    (actionId) => {
      setSurvey((prevState) => {
        const newActions = prevState.actions.filter(
          (actions) => actions.id !== actionId
        );
        return { ...prevState, actions: newActions };
      });
    },
    [setSurvey]
  );

  const onAddTranslationHandler = (languageToAdd) =>
    onAddTranslation(languageToAdd, setSurvey);

  const onUpdateTranslationHandler = ({
    languageCode,
    elementType,
    elementId,
    updatedTranslation,
    choiceId,
    questionChoiceId,
  }) =>
    onUpdateTranslation(
      {
        languageCode,
        elementType,
        elementId,
        updatedTranslation,
        choiceId,
        questionChoiceId,
      },
      setSurvey
    );

  const onRemoveTranslationHandler = (languageToDelete) =>
    onRemoveTranslation(languageToDelete, setSurvey);

  const removeUnsavedChoices = useCallback(
    (itemId) => {
      const { items } = survey;
      const item = items?.[itemId];

      if (item) {
        if (item.type === SurveyElement.QUESTION) {
          const { choices } = item.question;

          items[itemId].question.choices = choices.filter(
            (choice) => !choice?.newChoice
          );
        }

        setSurvey((prevState) => ({
          ...prevState,
          items,
        }));
      }
    },
    [survey, setSurvey]
  );

  const onCreateSurveyFromTemplateHandler = useCallback(
    ({
      surveyName,
      versionName,
      organizationId,
      organizationName,
      organizationDisplaySiteName,
    }) => {
      const { languageCompletion } = survey;
      const { surveyId, versionId } = modal;

      dispatch(
        createOrganizationSurveyFromTemplate({
          surveyName,
          versionName,
          organizationId,
          organizationName,
          organizationDisplaySiteName,
          surveyId,
          versionId,
          languageCompletion,
        })
      );
    },
    [dispatch, createOrganizationSurveyFromTemplate, modal, survey]
  );

  const onCreateQuestionGroupHandler = useCallback(
    (questionGroup) => {
      setSurvey((prevState) => {
        const { sections, items, availableLanguages, isCondensed } = prevState;

        const { itemIndex, sectionId } = modal;

        const section = sections[sectionId];

        const item = getItem({
          itemType: SurveyElement.QUESTION_GROUP,
          availableLanguages,
          condensed: isCondensed,
        });

        section.itemIds.splice(itemIndex, 0, item.id);

        return {
          ...prevState,
          sections,
          items: {
            ...items,
            [item.id]: {
              ...item,
              questionGroup,
            },
          },
        };
      });

      onCloseModalHandler();

      dispatch(clearQuestionChoiceState());
    },
    [modal, getItem, dispatch, clearQuestionChoiceState]
  );

  const onUpdateQuestionGroupHandler = useCallback(
    (questionGroup) => {
      setSurvey((prevState) => {
        const { itemId } = modal;

        const { sections, items } = prevState;

        return {
          ...prevState,
          sections,
          items: { ...items, [itemId]: { ...items[itemId], questionGroup } },
        };
      });

      onCloseModalHandler();
    },
    [setSurvey, modal, onCloseModalHandler]
  );

  const onCheckChoicesActionsHandler = useCallback(() => {
    const { itemId, choices } = modal;
    if (itemId && choices) {
      const hasActions = survey?.actions?.every((action) => {
        let hasNoActions = true;
        choices.forEach((choice) => {
          hasNoActions =
            hasNoActions && isChoiceInActions(action, itemId, choice.id);
        });
        return hasNoActions;
      });
      return !hasActions;
    }
    return false;
  }, [modal, survey?.actions, isChoiceInActions]);

  const onCreateActionHandler = useCallback(
    (action) => {
      setSurvey((prevState) => {
        const newActions = [...prevState.actions];
        newActions.push(action);
        return { ...prevState, actions: newActions };
      });
    },
    [setSurvey]
  );

  return (
    <>
      {modalType === ModalType.COPY_SURVEY && (
        <CopySurveyModal
          survey={survey}
          onClose={onCloseModalHandler}
          surveyId={survey.id}
        />
      )}
      {modalType === ModalType.DEPLOYMENTS && !deploymentId && (
        <DeploymentsModal
          onClose={onCloseModalHandler}
          deployments={survey.deployments}
          organization={survey.organizationName}
          version={survey.versionSelect}
          surveyId={params.surveyId}
        />
      )}
      {modalType === ModalType.SURVEY_TRANSLATIONS && (
        <Translations
          defaultTranslation={defaultTranslation}
          additionalTranslations={translations}
          onAddTranslation={onAddTranslationHandler}
          onRemoveTranslation={onRemoveTranslationHandler}
          onUpdateTranslation={onUpdateTranslationHandler}
          onClose={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CREATE_SURVEY_FROM_TEMPLATE && (
        <SurveyFromTemplateModal
          surveyName={survey.name}
          versionName={survey.versionName}
          creatingSurvey={creatingFromTemplate}
          onCreateSurveyHandler={onCreateSurveyFromTemplateHandler}
          onCancelHandler={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CREATE_SURVEY_DEPLOYMENT && (
        <CreateDeploymentModal
          organizationId={survey.organizationId}
          organizationName={survey.organizationName}
          onCreateDeployment={onCreateDeploymentHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CHANGE_DEPLOYMENT_DATES && (
        <ChangeDeploymentDatesModal
          organizationId={survey.organizationId}
          organizationName={survey.organizationName}
          onChangeDeploymentDates={onUpdateDeploymentStartAndEndHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.SURVEY_QUESTION_BUILDER && (
        <QuestionBuilder
          surveyType={survey.type}
          surveyLanguage={survey.defaultLanguage}
          surveyLanguages={survey.availableLanguages}
          isUpdating={!!modal.previousQuestion}
          previousQuestion={modal.previousQuestion}
          organizationName={survey.organizationName}
          onAddQuestion={onAddQuestionHandler}
          onUpdateQuestion={onUpdateQuestionHandler}
          onCancel={(itemId) => {
            onCloseModalHandler();
            removeUnsavedChoices(itemId);
          }}
          deploymentId={deploymentId}
          canDeleteChoice={canDeleteChoice}
        />
      )}
      {modalType === ModalType.SURVEY_QUESTION_GROUP_BUILDER && (
        <QuestionGroupBuilder
          surveyType={survey.type}
          surveyLanguage={survey.defaultLanguage}
          selectedLanguage={survey.selectedLanguage}
          availableLanguages={survey.availableLanguages}
          organizationName={survey.organizationName}
          onCreate={onCreateQuestionGroupHandler}
          onUpdate={onUpdateQuestionGroupHandler}
          onCancel={onCloseModalHandler}
          onCheckChoicesActions={onCheckChoicesActionsHandler}
          previousLabel={modal.label}
          previousDataSet={modal.dataSet}
          previousQuestions={modal.questions}
          previousChoices={modal.choices}
          previousColumnWidths={modal.columnWidths}
          deploymentId={deploymentId}
          canDeleteQuestion={canDeleteQuestion}
        />
      )}
      {modalType === ModalType.ACTIONS && (
        <ActionBuilderModal
          onClose={onCloseModalHandler}
          onCreateAction={onCreateActionHandler}
          onEditAction={onEditActionHandler}
          onDeleteAction={onDeleteActionHandler}
          questions={questionItems}
          questionGroups={questionGroupItems}
          textBoxes={textBoxItems}
          sections={survey.sections}
          pages={survey.pages}
          pagesOrder={survey.pagesOrder}
          actionsList={survey.actions}
          searchItem={modal.searchItem}
        />
      )}
      {modalType === ModalType.DELETE_SURVEY_SECTION && (
        <Modal
          isActive
          isLoading={false}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the section. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onConfirmationDeleteSectionModalHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.DELETE_SURVEY_PAGE && (
        <Modal
          isActive
          isLoading={false}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the page. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onConfirmationDeletePageModalHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CANNOT_DELETE_SURVEY_ITEM && (
        <Modal
          isActive
          isLoading={false}
          showConfirmButton={false}
          title="Cannot delete this item"
          body="This item is related to an action. Please change the action before deleting this item in order to prevent inconsistencies."
          cancelButtonMessage="Close"
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CANNOT_DELETE_SURVEY_SECTION && (
        <Modal
          isActive
          isLoading={false}
          showConfirmButton={false}
          title="Cannot delete this section"
          body="This section is related to an action. Please change the action before deleting this section in order to prevent inconsistencies."
          cancelButtonMessage="Close"
          onCancel={onCloseModalHandler}
        />
      )}
      {modalType === ModalType.CANNOT_DELETE_SURVEY_PAGE && (
        <Modal
          isActive
          isLoading={false}
          showConfirmButton={false}
          title="Cannot delete this page"
          body="This page is related to an action. Please change the action before deleting this page in order to prevent inconsistencies."
          cancelButtonMessage="Close"
          onCancel={onCloseModalHandler}
        />
      )}
    </>
  );
};

export default Modals;
