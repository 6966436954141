import { isString } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';

const getDefaultTranslationContent = ({
  surveyDefaultLanguage,
  surveyItems,
  surveyPages,
  surveyPagesOrder,
  surveySections,
  surveyVersionTitle,
}) => {
  const defaultTranslations = [surveyVersionTitle[surveyDefaultLanguage]];
  
  if (surveyPagesOrder?.length) {
    surveyPagesOrder.forEach((pageId) => {
      const { title: pageTitle, sectionIds } = { ...surveyPages[pageId] };

      defaultTranslations.push(pageTitle[surveyDefaultLanguage]);

      sectionIds.forEach((sectionId) => {
        const { title: sectionTitle, itemIds } = {
          ...surveySections[sectionId],
        };

        defaultTranslations.push(sectionTitle[surveyDefaultLanguage]);

        itemIds.forEach((itemId) => {
          const {
            type: itemType,
            content: itemContent,
            richText: itemRichText,
            question,
            questionGroup,
          } = surveyItems[itemId];

          if (itemType === SurveyElement.TEXT_BOX) {
            defaultTranslations.push(
              itemRichText
                ? itemRichText?.[surveyDefaultLanguage]
                : itemContent?.[surveyDefaultLanguage]
            );
          }

          if (itemType === SurveyElement.QUESTION) {
            const { content, richText, choices: questionChoices } = question;

            defaultTranslations.push(
              richText?.[surveyDefaultLanguage] ||
                content?.[surveyDefaultLanguage]
            );

            questionChoices.forEach(({ option }) => {
              defaultTranslations.push(option[surveyDefaultLanguage]);
            });
          }

          if (itemType === SurveyElement.QUESTION_GROUP) {
            const { questions, choices, dataSet } = questionGroup;

            questions.forEach(({ content }) => {
              defaultTranslations.push(content[surveyDefaultLanguage]);
            });

            choices.forEach(({ questionChoices, option, dataSetId }) => {
              if (dataSetId) {
                let datasetTitle = null;

                if (Array.isArray(dataSet)) {
                  datasetTitle = dataSet.find(
                    ({ id }) => id === dataSetId
                  ).title;
                }

                if (datasetTitle) {
                  if (isString(datasetTitle)) {
                    defaultTranslations.push(datasetTitle);
                  } else {
                    defaultTranslations.push(
                      datasetTitle[surveyDefaultLanguage] || ''
                    );
                  }
                }
              }

              if (questionChoices) {
                return questionChoices.forEach(({ option: choiceOption }) => {
                  defaultTranslations.push(choiceOption[surveyDefaultLanguage]);
                });
              }

              // We need this line to not break old implementation
              return defaultTranslations.push(option[surveyDefaultLanguage]);
            });
          }
        });
      });
    });
  }

  return defaultTranslations;
};

export { getDefaultTranslationContent };
