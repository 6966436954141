import React, { useCallback, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Collapse from 'components/Collapse';
import {
  addItemSelectOptions as selectOptions,
  addItemSelectStyles,
} from 'utils/surveyBuilder';
import { ModalType } from 'utils/enums';
import { isString } from 'utils';

import classes from './QuestionGroupItem.module.scss';
import QuestionTag from '../QuestionTag';

const QuestionGroupItem = ({
  survey,
  choices,
  questions,
  label,
  visible,
  condensed,
  itemId,
  pageIndex,
  sectionIndex,
  selectedLanguage,
  itemIndex,
  sectionId,
  onCondense,
  onSelectItemOption,
  onDelete,
  onPropertyChange,
  onCheckHasAction,
  onClickSeeActions,
  deploymentId,
  onOpenModal,
}) => {
  const [questionsWithActions, setQuestionsWithActions] = useState([]);
  const [groupWithActions, setGroupWithActions] = useState(false);

  useEffect(() => {
    const newQuestions = [];
    questions.forEach((question) => {
      if (onCheckHasAction(question.id)) {
        newQuestions.push({ id: question.id, value: true });
      } else {
        newQuestions.push({ id: question.id, value: false });
      }
    });
    setQuestionsWithActions(newQuestions);
  }, [questions, setQuestionsWithActions, onCheckHasAction]);

  useEffect(() => {
    if (onCheckHasAction(itemId)) {
      setGroupWithActions(true);
    }
  }, [onCheckHasAction, setGroupWithActions, itemId]);

  const onUpdateHandler = useCallback(() => {
    const { questionGroup } = survey.items[itemId];

    onOpenModal(ModalType.SURVEY_QUESTION_GROUP_BUILDER, {
      itemId,
      ...questionGroup,
    });
  }, [onOpenModal, survey.items, itemId]);

  const onVisibilityChangeHandler = useCallback(
    () => onPropertyChange({ visible: !visible }, itemId),
    [onPropertyChange, itemId, visible]
  );

  const onDeleteItemHandler = useCallback(
    () => onDelete(itemIndex, sectionId),
    [onDelete, itemIndex, sectionId]
  );

  const hasAction = useCallback(() => {
    const questionsActions = questionsWithActions.find(
      (question) => question.value
    );
    return groupWithActions || questionsActions;
  }, [questionsWithActions, groupWithActions]);

  const onClickSeeActionsHandler = useCallback(() => {
    if (groupWithActions) {
      onClickSeeActions({ itemId });
    } else {
      const question = questionsWithActions.find(
        (questionAction) => questionAction.value
      );
      onClickSeeActions({ itemId: question.id });
    }
  }, [itemId, onClickSeeActions, groupWithActions, questionsWithActions]);

  const addItemSelectOptions = useMemo(
    () =>
      deploymentId
        ? selectOptions.filter((option) => option.label === 'Text Box')
        : selectOptions,
    [deploymentId]
  );

  const pageIndexToRender = Number.isInteger(pageIndex)
    ? `${pageIndex + 1}.`
    : '';

  return (
    <div
      className={classNames(classes.container, {
        [classes['container--hidden']]: !visible,
      })}
    >
      <div className="columns is-vcentered is-mobile is-multiline">
        <div className="column is-full-mobile is-full-tablet is-half-desktop">
          <div className={classes['page-count']}>
            {`${pageIndexToRender}${sectionIndex + 1}.${itemIndex + 1}`}
          </div>
          <div className={classNames('subtitle', classes.label)}>{label}</div>
        </div>
        <div
          className="column is-full-mobile is-full-tablet is-half-desktop"
          style={{ alignSelf: 'flex-start' }}
        >
          <div
            className={classNames(
              'field is-grouped is-grouped-multiline',
              classes['action-bar']
            )}
          >
            <div
              className={classNames('control', classes['action-bar-button'])}
              style={{ minWidth: '150px' }}
            >
              <Select
                placeholder="Add Item"
                styles={addItemSelectStyles}
                value={null}
                isSearchable={false}
                options={addItemSelectOptions}
                onChange={({ value }) =>
                  onSelectItemOption(value, itemIndex + 1)
                }
              />
            </div>
            <Button
              className={classNames('control', classes['action-bar-button'])}
              icon={classNames('mdi', {
                'mdi-eye': visible,
                'mdi-eye-off': !visible,
              })}
              onClick={onVisibilityChangeHandler}
            />
            {hasAction() && (
              <Button
                className={classNames('control', classes['action-bar-button'])}
                onClick={onClickSeeActionsHandler}
              >
                See actions
              </Button>
            )}
            <Button
              className={classNames('control', classes['action-bar-button'])}
              onClick={onUpdateHandler}
            >
              Edit
            </Button>
            <Button
              className={classNames('control', classes['action-bar-button'])}
              onClick={onDeleteItemHandler}
              disabled={deploymentId}
            >
              Delete
            </Button>
            <Collapse
              className={classes['action-bar-button']}
              collapsed={condensed}
              onCollapse={() => onCondense(itemId, !condensed)}
            />
          </div>
        </div>
      </div>
      <div className="content">
        {!condensed && (
          <>
            <h6>Choices</h6>
            <ul>
              {choices.map((choiceProp) => {
                const {
                  title,
                  questionChoices,
                  notApplicable,
                  option: choiceContent,
                  visible: choiceVisible,
                  id,
                } = choiceProp;

                const choiceTitle = isString(title)
                  ? title
                  : title?.[selectedLanguage];
                return (
                  <React.Fragment key={id}>
                    <p className={classes['choices-title']}>{choiceTitle}</p>
                    {id ? (
                      <li
                        key={id}
                        className={classNames({
                          [classes['opacity-50']]: !choiceVisible,
                        })}
                      >
                        {!choiceVisible && (
                          <span
                            className={classNames(
                              'tag is-info question-choice__tag',
                              classes['question-group-tag']
                            )}
                          >
                            Hidden
                          </span>
                        )}
                        {notApplicable && (
                          <span
                            className={classNames(
                              'tag is-info question-choice__tag',
                              classes['question-group-tag']
                            )}
                          >
                            N/A
                          </span>
                        )}
                        {choiceContent}
                      </li>
                    ) : (
                      questionChoices.map(
                        ({
                          notApplicable: questionChoiceNotApplicable,
                          option: questionChoiceContent,
                          visible: questionChoiceVisible,
                          id: questionChoiceId,
                        }) => (
                          <li
                            key={questionChoiceId}
                            className={classNames({
                              [classes['opacity-50']]: !questionChoiceVisible,
                            })}
                          >
                            {!questionChoiceVisible && (
                              <span
                                className={classNames(
                                  'tag is-info question-choice__tag',
                                  classes['question-group-tag']
                                )}
                              >
                                Hidden
                              </span>
                            )}
                            {questionChoiceNotApplicable && (
                              <span
                                className={classNames(
                                  'tag is-info question-choice__tag',
                                  classes['question-group-tag']
                                )}
                              >
                                N/A
                              </span>
                            )}
                            {questionChoiceContent}
                          </li>
                        )
                      )
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </>
        )}
        <h6>Questions</h6>
        <ul>
          {questions.map(
            ({
              content: questionContent,
              richText,
              tags,
              required,
              visible: questionVisible,
              id,
            }) => (
              <li
                key={id}
                className={classNames({
                  [classes['opacity-50']]: !questionVisible,
                })}
              >
                <>
                  {parse(richText || questionContent)}
                  {required && (
                    <span
                      className={classNames(
                        'tag is-required',
                        classes['question-group-tag']
                      )}
                    >
                      Required
                    </span>
                  )}
                  {!questionVisible && (
                    <span
                      className={classNames(
                        'tag is-info question-choice__tag',
                        classes['question-group-tag']
                      )}
                    >
                      Hidden
                    </span>
                  )}
                  {tags.map((tag, tagIndex) => {
                    const tagKey = `tag ${tagIndex}`;
                    return (
                      <QuestionTag key={tagKey} tag={tag} inSurveyBuilder />
                    );
                  })}
                </>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

QuestionGroupItem.propTypes = {
  survey: PropTypes.shape({}).isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  condensed: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  onCondense: PropTypes.func.isRequired,
  onSelectItemOption: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  onCheckHasAction: PropTypes.func.isRequired,
  onClickSeeActions: PropTypes.func.isRequired,
  deploymentId: PropTypes.string,
  onOpenModal: PropTypes.func.isRequired,
};

export default QuestionGroupItem;
