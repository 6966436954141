export const TagType = Object.freeze({
  QUESTION: 'Question',
  DATA_SET: 'Data Set',
  CONSTRUCT: 'Construct',
  DEMOGRAPHIC: 'Demographic',
});

export const ModalType = Object.freeze({
  ACTIONS: 'actions',
  COPY_SURVEY: 'copy-survey',
  CANNOT_DELETE_SURVEY_ITEM: 'cannot-delete-survey-item',
  CANNOT_DELETE_SURVEY_SECTION: 'cannot-delete-survey-section',
  CANNOT_DELETE_SURVEY_PAGE: 'cannot-delete-survey-page',
  CHANGE_DEPLOYMENT_DATES: 'change-deployments-dates',
  CONFIRMATION: 'confirmation',
  CREATE_SURVEY_FROM_TEMPLATE: 'create-survey-from-template',
  CREATE_SURVEY_DEPLOYMENT: 'create-survey-deployment',
  DEPLOYMENTS: 'deployments',
  DELETE_SURVEY_ACTION: 'delete-survey-action',
  DELETE_SURVEY_PAGE: 'delete-survey-page',
  DELETE_SURVEY_SECTION: 'delete-survey-section',
  DELETE_SURVEY_DEPLOYMENT: 'delete-survey-deployment',
  ERROR: 'error',
  QUESTIONS_DATA_SETS_INFORMATION: 'questions-data-sets-information',
  SURVEY_QUESTION_BUILDER: 'survey-question-builder',
  SURVEY_QUESTION_GROUP_BUILDER: 'survey-question-group-builder',
  SURVEY_TRANSLATIONS: 'survey-translations',
});

export const ActionBuilderPages = {
  ACTIONS_LIST: 'actions-list',
  CREATE_ACTION: 'create-action',
};

export const LanguageIsoCode = {
  AF: 'af',
  AR: 'ar',
  BN: 'bn',
  ZH_CHT: 'zh-CHT',
  ZH_CHS: 'zh-CHS',
  CS: 'cs',
  DA: 'da',
  NL: 'nl',
  EN: 'en',
  FI: 'fi',
  FR: 'fr',
  DE: 'de',
  ES: 'es',
  EL: 'el',
  HI: 'hi',
  HU: 'hu',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  FA: 'fa',
  PA: 'pa',
  PL: 'pl',
  PT: 'pt',
  RO: 'ro',
  RU: 'ru',
  SR: 'sr',
  SK: 'sk',
  SV: 'sv',
  TL: 'tl',
  TR: 'tr',
  UK: 'uk',
  UR: 'ur',
  VI: 'vi',
  NO: 'no',
};

export const LanguageName = {
  [LanguageIsoCode.AF]: 'Afrikaans',
  [LanguageIsoCode.AR]: 'Arabic',
  [LanguageIsoCode.BN]: 'Bengali',
  [LanguageIsoCode.ZH_CHT]: 'Traditional Chinese',
  [LanguageIsoCode.ZH_CHS]: 'Simplified Chinese',
  [LanguageIsoCode.CS]: 'Czech',
  [LanguageIsoCode.DA]: 'Danish',
  [LanguageIsoCode.NL]: 'Dutch',
  [LanguageIsoCode.EN]: 'English',
  [LanguageIsoCode.FI]: 'Finnish',
  [LanguageIsoCode.FR]: 'French',
  [LanguageIsoCode.DE]: 'German',
  [LanguageIsoCode.EL]: 'Greek',
  [LanguageIsoCode.ES]: 'Spanish',
  [LanguageIsoCode.HI]: 'Hindi',
  [LanguageIsoCode.HU]: 'Hungarian',
  [LanguageIsoCode.IT]: 'Italian',
  [LanguageIsoCode.JA]: 'Japanese',
  [LanguageIsoCode.KO]: 'Korean',
  [LanguageIsoCode.NO]: 'Norwegian',
  [LanguageIsoCode.FA]: 'Persian',
  [LanguageIsoCode.PA]: 'Punjabi',
  [LanguageIsoCode.PL]: 'Polish',
  [LanguageIsoCode.PT]: 'Portuguese',
  [LanguageIsoCode.RO]: 'Romanian',
  [LanguageIsoCode.RU]: 'Russian',
  [LanguageIsoCode.SR]: 'Serbian',
  [LanguageIsoCode.SK]: 'Slovak',
  [LanguageIsoCode.SV]: 'Swedish',
  [LanguageIsoCode.TL]: 'Tagalog',
  [LanguageIsoCode.TR]: 'Turkish',
  [LanguageIsoCode.UK]: 'Ukrainian',
  [LanguageIsoCode.UR]: 'Urdu',
  [LanguageIsoCode.VI]: 'Vietnamese',
};

export const LanguageTranslation = {
  [LanguageIsoCode.AF]: 'Afrikaans',
  [LanguageIsoCode.AR]: 'عربى',
  [LanguageIsoCode.BN]: 'বাংলা',
  [LanguageIsoCode.ZH_CHT]: '中文简体',
  [LanguageIsoCode.ZH_CHS]: '中文繁體',
  [LanguageIsoCode.CS]: 'Český',
  [LanguageIsoCode.DA]: 'Dansk',
  [LanguageIsoCode.NL]: 'Nederlands',
  [LanguageIsoCode.EN]: 'English',
  [LanguageIsoCode.FI]: 'Suomalainen',
  [LanguageIsoCode.FR]: 'Français',
  [LanguageIsoCode.DE]: 'Deutsch',
  [LanguageIsoCode.EL]: 'Ελληνικά',
  [LanguageIsoCode.ES]: 'Español',
  [LanguageIsoCode.HI]: 'हिन्दी',
  [LanguageIsoCode.HU]: 'Magyar',
  [LanguageIsoCode.IT]: 'Italiano',
  [LanguageIsoCode.JA]: '日本',
  [LanguageIsoCode.KO]: '한국어',
  [LanguageIsoCode.NO]: 'Norsk',
  [LanguageIsoCode.FA]: 'فارسی',
  [LanguageIsoCode.PA]: 'ਪੰਜਾਬੀ',
  [LanguageIsoCode.PL]: 'Polskie',
  [LanguageIsoCode.PT]: 'Português',
  [LanguageIsoCode.RO]: 'Română',
  [LanguageIsoCode.RU]: 'русский',
  [LanguageIsoCode.SR]: 'Српски',
  [LanguageIsoCode.SK]: 'Slovák',
  [LanguageIsoCode.SV]: 'Švédsky',
  [LanguageIsoCode.TL]: 'Tagalog',
  [LanguageIsoCode.TR]: 'Türkçe',
  [LanguageIsoCode.UK]: 'Українська',
  [LanguageIsoCode.UR]: 'اردو',
  [LanguageIsoCode.VI]: 'Tiếng Việt',
};

export const OrderTypes = {
  RANDOM: 'random',
  ASC: 'asc',
  DESC: 'desc',
};

export const ConditionOperators = {
  HAS_A_RESPONSE: 'has a response',
  HAS_NO_RESPONSE: 'has no response',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
  CONTAINS_ONE_OF: 'contains one of',
  DOES_NOT_CONTAIN_ANY_OF: 'does not contain any of',
  CONTAINS_ALL_OF: 'contains all of',
};

export const ConditionOperatorsForSingleResponse = {
  HAS_A_RESPONSE: 'has a response',
  HAS_NO_RESPONSE: 'has no response',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
};

export const RegularOperators = {
  AND: 'AND',
  OR: 'OR',
};

export const AvailableActions = {
  HIDE_QUESTION: 'hide question',
  SHOW_QUESTION: 'show question',
  HIDE_CHOICES: 'hide choices',
  SHOW_CHOICES: 'show choices',
  HIDE_QUESTION_GROUP: 'hide question group',
  SHOW_QUESTION_GROUP: 'show question group',
  HIDE_PAGE: 'hide page',
  SHOW_PAGE: 'show page',
  HIDE_SECTION: 'hide section',
  SHOW_SECTION: 'show section',
  HIDE_TEXT_BOX: 'hide text box',
  SHOW_TEXT_BOX: 'show text box',
  RANDOMIZE_QUESTIONS: 'randomize questions',
  RANDOMIZE_CHOICES: 'randomize choices',
  RANDOMIZE_SECTIONS: 'randomize sections',
  RANDOMIZE_PAGES: 'randomize pages',
};

export const ActionsSelectValues = {
  SUBJECT: 'subject',
  CRITERIA: 'criteria',
  VALUES: 'values',
  ACTION: 'action',
  CHOICES: 'choices',
  RANDOMIZE: 'randomize',
};

export const ActionBuilderConditionTypes = {
  CONDITION: 'condition',
  OPERATOR: 'operator',
};

export const Collections = {
  USERS: 'users',
  GROUPS: 'groups',
  DEMOGRAPHICS: 'demographics',
  ERROR_LOGS: 'errorLogs',
  SURVEYS: 'surveys',
  QUESTIONS: 'questions',
  QUESTION_TAG: 'questionTag',
  QUESTION_CHOICE: 'questionChoice',
  TAGS: 'tags',
  RESPONSES: 'responses',
  RESPONSES_IN_PROGRESS: 'responsesInProgress',
  VERSIONS: 'versions',
  DEPLOYMENTS: 'deployments',
  DATA_SETS: 'dataSets',
  ORGANIZATIONS: 'organizations',
  SECTIONS_SAVES: 'sectionsSaves',
};

export const CollectionGroup = {
  SURVEY_VERSION: 'versions',
  SURVEY_DEPLOYMENT: 'deployments',
};

export const ReactSelectActions = {
  SELECT: 'select-option',
  REMOVE: 'remove-value',
  POP: 'pop-value',
  CLEAR: 'clear',
};

export const ServiceOperations = {
  // create
  CREATE_SURVEY: 'create-survey',
  CREATE_SURVEY_VERSION: 'create-survey-version',
  CREATE_SURVEY_DEPLOYMENT: 'create-survey-deployment',
  CREATE_SURVEY_FROM_TEMPLATE: 'create-survey-from-template',
  CREATE_SURVEY_RESPONSE: 'create-survey-response',
  CREATE_SURVEY_RESPONSE_SAVE: 'create-survey-response-save',
  COPY_SURVEY: 'copy-survey',

  // update
  UPDATE_SURVEY: 'update-survey',
  UPDATE_DEPLOYMENT: 'update-deployment',
  UPDATE_DEPLOYMENT_START_AND_END_DATES: 'update-deployment-start-and-end-dates',

  // delete
  DELETE_SURVEY_DEPLOYMENT: 'delete-survey-deployment',

  // fetch
  FETCH_SURVEY_DEPLOYMENT: 'fetch-survey-deployment',
  FETCH_SURVEY: 'fetch-survey',
  FETCH_DEPLOYMENTS: 'fetch-deployments',
  FETCH_RESPONSE_IN_PROGRESS: 'fetch-response-in-progress',
};

export const ServiceSubOperations = {
  [ServiceOperations.CREATE_SURVEY]: {
    CREATE_SURVEY: 'survey-creation',
    VERIFY_DUPLICATE_SURVEY: 'verify-duplicate-survey',
    VERSION_AND_QUESTIONS_CREATION: 'version-and-questions-creation',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
  },
  [ServiceOperations.CREATE_SURVEY_VERSION]: {
    CREATE_SURVEY_VERSION: 'survey-version-creation',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
  },
  [ServiceOperations.CREATE_SURVEY_RESPONSE_SAVE]: {
    SAVE_RESPONSE: 'save-response',
  },
  [ServiceOperations.COPY_SURVEY]: {
    COPY_SURVEY: 'copy-survey',
  },
  [ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]: {
    VERIFY_DUPLICATE_SURVEY: 'verify-duplicate-survey',
    GET_ORIGINAL_SURVEY: 'get-original-survey',
    QUESTIONS_CREATION: 'questions-creation',
    QUESTION_CHOICES_CREATION: 'question-choices-creation',
    CREATE_SURVEY: 'survey-creation',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
  },
  [ServiceOperations.CREATE_SURVEY_DEPLOYMENT]: {
    CREATE_SURVEY_DEPLOYMENT: 'survey-deployment-creation',
    DEMOGRAPHICS_CREATION: 'demographics-creation',
    QUESTION_CHOICES_CREATION: 'question-choices-creation',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
    ALGOLIA_INDEX_UPDATE_GROUPS: 'algolia-index-update-groups',
  },
  [ServiceOperations.CREATE_SURVEY_RESPONSE]: {
    CREATE_SURVEY_RESPONSE: 'survey-response-creation',
  },
  [ServiceOperations.UPDATE_SURVEY]: {
    UPDATE_SURVEY: 'survey-update',
    UPDATE_QUESTIONS: 'update-questions',
    QUESTION_CHOICES_CREATION: 'question-choices-creation',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
  },
  [ServiceOperations.DELETE_SURVEY_DEPLOYMENT]: {
    DELETE_SURVEY_DEPLOYMENT: 'survey-deployment-deletion',
    UPDATE_SURVEY_VERSION: 'update-survey-version',
    ALGOLIA_INDEX_UPDATE: 'algolia-index-update',
  },
  [ServiceOperations.FETCH_SURVEY_DEPLOYMENT]: {
    FETCH_SURVEY_DEPLOYMENT: 'survey-deployment-fetch',
  },
  [ServiceOperations.FETCH_DEPLOYMENTS]: {
    FETCH_DEPLOYMENTS: 'deployments-fetch',
  },
  [ServiceOperations.FETCH_RESPONSE_IN_PROGRESS]: {
    FETCH_RESPONSE_IN_PROGRESS: 'response-in-progress-fetch',
  },
  [ServiceOperations.UPDATE_DEPLOYMENT]: {
    UPDATE_DEPLOYMENT: 'deployment-update',
    QUESTIONS_DELETION: 'questions-deletion',
    DEMOGRAPHICS_CREATION: 'demographics-creation',
    QUESTIONS_CREATION: 'questions-creation',
    QUESTION_CHOICES_CREATION: 'question-choices-creation',
    ALGOLIA_INDEX_UPDATE_GROUPS: 'algolia-index-update-groups',
  },
  [ServiceOperations.UPDATE_DEPLOYMENT_START_AND_END_DATES]: {
    UPDATE_DEPLOYMENT_START_AND_END_DATES: 'deployment-start-and-end-dates-update',
  },
};
