import { toastr } from 'react-redux-toastr';
import { saveErrorLog } from 'services/utils';

import {
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL,
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT,
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS,
} from 'state/actionCreators/surveys';
import {
  Collections,
  ServiceOperations,
  ServiceSubOperations,
} from 'utils/enums';
import { collection } from 'utils/firebase';

export const fetchResponseInProgress = ({ responseCode }) => {
  return async (dispatch) => {
    dispatch(SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT());

    try {
      const responseInProgress = await collection(
        Collections.RESPONSES_IN_PROGRESS
      )
        .doc(responseCode)
        .get();

      if (responseInProgress.exists) {
        toastr.success('', 'Response loaded successfully');

        return dispatch(
          SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS({
            responseInProgress: responseInProgress.data(),
          })
        );
      }

      const errorMessage = 'Invalid Response code.';

      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL({
          error: errorMessage,
        })
      );
    } catch (error) {
      console.error('Fetch Response In Progress', error);

      await saveErrorLog(error, {
        operation: ServiceOperations.FETCH_RESPONSE_IN_PROGRESS,
        subOperation:
          ServiceSubOperations[ServiceOperations.FETCH_RESPONSE_IN_PROGRESS]
            .FETCH_RESPONSE_IN_PROGRESS,
        responseCode: responseCode ?? null,
      });

      const errorMessage = 'Unknown error. Please Contact your administrator.';

      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};
