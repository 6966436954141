import { SurveyElement } from 'utils/surveyBuilder/enums';

const onRemoveTranslation = (languageToDelete, setSurvey) => {
  setSurvey((prevState) => {
    const {
      pagesOrder,
      pages,
      sections,
      items,
      availableLanguages,
      versionTitle,
    } = prevState;

    delete versionTitle[languageToDelete];

    pagesOrder.forEach((pageId) => {
      const { title: pageTitle, sectionIds } = pages[pageId];

      delete pageTitle[languageToDelete];

      sectionIds.forEach((sectionId) => {
        const { title: sectionTitle, itemIds } = sections[sectionId];

        delete sectionTitle[languageToDelete];

        itemIds.forEach((itemId) => {
          const {
            type: itemType,
            content: itemContent,
            question,
            questionGroup,
          } = items[itemId];

          if (itemType === SurveyElement.TEXT_BOX) {
            delete itemContent[languageToDelete];
          }

          if (itemType === SurveyElement.QUESTION) {
            const { content, choices, dataSet } = question;

            delete content[languageToDelete];

            const choicesAreFromDataSet = !!dataSet.id;

            if (!choicesAreFromDataSet) {
              choices.forEach((_, index) => {
                delete choices[index].option[languageToDelete];
              });
            }
          }

          if (itemType === SurveyElement.QUESTION_GROUP) {
            const { questions } = questionGroup;

            questions.forEach(({ content }) => {
              // eslint-disable-next-line no-param-reassign
              delete content[languageToDelete];
            });
          }
        });
      });
    });

    return {
      ...prevState,
      availableLanguages: availableLanguages.filter(
        (availableLanguage) => availableLanguage !== languageToDelete
      ),
    };
  });
};

export { onRemoveTranslation };
