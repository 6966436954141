import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Organizations from 'pages/Organizations';
import Organization from 'pages/Organization';
import Industry from 'pages/Industry';
import Industries from 'pages/Industries';
import Waves from 'pages/Waves';
import Wave from 'pages/Wave';
import Groups from 'pages/Groups';
import Group from 'pages/Group';
import Tags from 'pages/Tags';
import Tag from 'pages/Tag';
import DataSet from 'pages/DataSet';
import SavedSections from 'pages/SavedSections';
import DataSets from 'pages/DataSets';
import Surveys from 'pages/Surveys';
import SurveyBuilder from 'pages/SurveyBuilder';
import LiveSurvey from 'pages/LiveSurvey';
import Tasks from 'pages/Tasks';
import SavedSectionEdit from 'pages/SavedSectionEdit';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={paths.LOGIN} component={Login} />
        <PublicRoute
          exact
          path={paths.RESET_PASSWORD}
          component={ResetPassword}
        />
        <PrivateRoute exact path={paths.ADD_USER} component={User} />
        <PrivateRoute exact path={paths.MODIFY_USER} component={User} />
        <PrivateRoute exact path={paths.USERS} component={Users} />
        <PrivateRoute
          exact
          path={paths.ADD_ORGANIZATION}
          component={Organization}
        />
        <PrivateRoute
          exact
          path={paths.MODIFY_ORGANIZATION}
          component={Organization}
        />
        <PrivateRoute
          exact
          path={paths.ORGANIZATIONS}
          component={Organizations}
        />
        <PrivateRoute exact path={paths.WAVES} component={Waves} />
        <PrivateRoute exact path={paths.ADD_WAVE} component={Wave} />
        <PrivateRoute exact path={paths.MODIFY_WAVE} component={Wave} />
        <PrivateRoute exact path={paths.INDUSTRIES} component={Industries} />
        <PrivateRoute exact path={paths.ADD_INDUSTRY} component={Industry} />
        <PrivateRoute exact path={paths.MODIFY_INDUSTRY} component={Industry} />
        <PrivateRoute exact path={paths.ADD_TAG} component={Tag} />
        <PrivateRoute exact path={paths.MODIFY_TAG} component={Tag} />
        <PrivateRoute exact path={paths.TAGS} component={Tags} />
        <PrivateRoute exact path={paths.TASKS} component={Tasks} />
        <PrivateRoute exact path={paths.GROUPS} component={Groups} />
        <PrivateRoute exact path={paths.ADD_GROUP} component={Group} />
        <PrivateRoute exact path={paths.MODIFY_GROUP} component={Group} />
        <PrivateRoute exact path={paths.ADD_DATA_SET} component={DataSet} />
        <PrivateRoute exact path={paths.MODIFY_DATA_SET} component={DataSet} />
        <PrivateRoute exact path={paths.DATA_SETS} component={DataSets} />
        <PrivateRoute exact path={paths.PROFILE} component={Profile} />
        <PrivateRoute exact path={paths.ADD_SURVEY} component={SurveyBuilder} />
        <PrivateRoute
          exact
          path={paths.SAVED_SECTIONS}
          component={SavedSections}
        />
        <PrivateRoute
          exact
          path={paths.SAVED_SECTIONS_EDIT}
          component={SavedSectionEdit}
        />
        <PrivateRoute
          exact
          path={paths.SAVED_SECTIONS}
          component={SavedSections}
        />
        <PrivateRoute
          exact
          path={paths.MODIFY_SURVEY}
          component={SurveyBuilder}
        />
        <PrivateRoute
          exact
          path={paths.MODIFY_DEPLOYMENT}
          component={SurveyBuilder}
        />
        <PrivateRoute exact path={paths.SURVEYS_BY_TYPE} component={Surveys} />
        <PrivateRoute exact path={paths.ROOT} component={Home} />
        <PublicRoute exact path={paths.PREVIEW} component={LiveSurvey} />
        <PublicRoute
          exact
          path={paths.VIEW_LIVE_SURVEY}
          component={LiveSurvey}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
