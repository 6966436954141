/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, memo, Fragment } from 'react';
import classNames from 'classnames';
import { useTable, usePagination, useExpanded } from 'react-table';
import PropTypes from 'prop-types';
import {
  connectHits,
  Stats,
  connectStateResults,
} from 'react-instantsearch-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import Pagination from '../Pagination';
import ResultsPerPage from '../ResultsPerPage';
import classes from './Table.module.scss';
import './TableMobile.css';

const Table = ({
  columns,
  hits,
  resultsPerPage,
  defaultPerPageOption,
  isSearchStalled,
  renderRowSubComponent,
  skipPageResetRef,
  resetColumnPreferences,
  className,
}) => {
  useEffect(() => {
    if (skipPageResetRef) {
      skipPageResetRef.current = false;
    }
  }, [skipPageResetRef]);

  useEffect(() => {
    if (isSearchStalled && resetColumnPreferences) {
      resetColumnPreferences();
    }
  }, [isSearchStalled, resetColumnPreferences]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    visibleColumns,
  } = useTable(
    {
      columns,
      data: hits,
      autoResetExpanded: !skipPageResetRef.current,
    },
    useExpanded,
    usePagination
  );

  return (
    <div className="b-table">
      <div className="table-wrapper">
        <table
          className={classNames(
            'table is-striped has-mobile-cards is-hoverable',
            className
          )}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th key={column.id}>
                      <div className="'th-wrap'">{column.render('Header')}</div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={classNames(
                            { 'is-actions-cell': cell.column.id === 'actions' },
                            {
                              'has-no-head-mobile is-image-cell':
                                cell.column.id === 'logoUrl',
                            }
                          )}
                          data-label={cell.column.Header}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <div className={classNames('level', classes.level)}>
          <div className="level-left">
            <div className="control">
              <ResultsPerPage
                defaultRefinement={defaultPerPageOption}
                items={resultsPerPage.map((value) => ({
                  value,
                  label: `${value} per page`,
                }))}
                setPageSize={setPageSize}
              />
            </div>
            <div className={classNames('control', classes.stats)}>
              {isSearchStalled ? <ClipLoader /> : <Stats />}
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Cell: PropTypes.func,
      sortType: PropTypes.string,
      id: PropTypes.string,
      disableSortBy: PropTypes.bool,
    })
  ).isRequired,
  hits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSearchStalled: PropTypes.bool.isRequired,
  resultsPerPage: PropTypes.arrayOf(PropTypes.number),
  defaultPerPageOption: PropTypes.number,
  skipPageResetRef: PropTypes.shape({ current: PropTypes.bool }),
  resetColumnPreferences: PropTypes.func,
};

Table.defaultProps = {
  resultsPerPage: [5, 10, 15, 25, 50],
  defaultPerPageOption: 5,
  skipPageResetRef: { current: false },
};

export default connectHits(connectStateResults(memo(Table)));
