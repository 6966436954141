import { ActionBuilderConditionTypes, AvailableActions } from 'utils/enums';
import { SurveyElement } from '../surveyBuilder/enums';

export const getQuestionOptions = (pages, pagesOrder, sections, questions) => {
  const newSubjects = [];
  let sectionIndex = 1;
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];
    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];
      const options = [];
      section.itemIds.forEach((itemId) => {
        const item = questions[itemId];
        if (item?.type === SurveyElement.QUESTION) {
          const title = item.question.content.en;
          options.push({
            label: title,
            value: { id: item.question.id, choices: item.question.choices },
          });
        }
      });
      newSubjects.push({
        label: section.title.en || `${SurveyElement.SECTION} ${sectionIndex}`,
        options,
      });
      sectionIndex += 1;
    });
  });
  return newSubjects;
};

export const getQuestionGroupOptions = (
  pages,
  pagesOrder,
  sections,
  questionGroups
) => {
  const newSubjects = [];
  let sectionIndex = 1;
  let groupIndex = 1;
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];
    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];
      const options = [];
      section.itemIds.forEach((itemId) => {
        const item = questionGroups[itemId];
        if (item?.type === SurveyElement.QUESTION_GROUP) {
          const title = item.questionGroup.label.en || `Group ${groupIndex}`;
          options.push({
            label: title,
            value: {
              id: item.id,
              questions: item.questionGroup.questions,
              choices: item.questionGroup.choices,
            },
          });
          groupIndex += 1;
        }
      });
      newSubjects.push({
        label: section.title.en || `${SurveyElement.SECTION} ${sectionIndex}`,
        options,
      });
      sectionIndex += 1;
    });
  });
  return newSubjects;
};

export const getAllQuestionsOptions = (
  pages,
  pagesOrder,
  sections,
  questions,
  questionGroups,
  allQuestions
) => {
  const allQuestionsOptions = [];
  const questionsAndGroups = [];

  pagesOrder.forEach((pageId, pageIndex) => {
    const page = pages[pageId];

    page.sectionIds.forEach((sectionId, sectionIndex) => {
      const section = sections[sectionId];
      const allOptions = [];
      const questionsAndGroupsOptions = [];
      const currentPageIndex = pageIndex + 1;
      const currentSectionIndex = sectionIndex + 1;

      section.itemIds.forEach((itemId, itemIndex) => {
        const questionsAndGroupsOptionsItems = [];
        const itemQuestion = questions[itemId];
        const itemGroup = questionGroups[itemId];
        const allItemsOptions = [];

        if (itemQuestion) {
          const key = `question${itemIndex}`;
          const { title } = allQuestions[itemQuestion.question.id];

          allItemsOptions.push({
            label: `${currentPageIndex}.${currentSectionIndex}.${
              itemIndex + 1
            }: ${title}`,
            value: {
              id: itemQuestion.question.id,
              choices: itemQuestion.question.choices,
              type: itemQuestion.question.type,
              toString: () => key,
            },
          });

          questionsAndGroupsOptionsItems.push({
            label: `${currentPageIndex}.${currentSectionIndex}.${
              itemIndex + 1
            }: ${title}`,
            value: {
              id: itemQuestion.question.id,
              choices: itemQuestion.question.choices,
              type: itemQuestion.question.type,
            },
          });
        } else if (itemGroup) {
          const { questionGroup, id } = itemGroup;

          questionGroup.questions.forEach((question, groupIndex) => {
            const key = `questionGroup${itemIndex}${groupIndex}`;
            const { title } = allQuestions[question.id];

            allItemsOptions.push({
              label: `${currentPageIndex}.${currentSectionIndex}.${
                itemIndex + 1
              }: ${title}`,
              value: {
                id: question.id,
                choices: questionGroup.choices,
                type: question.type,
                group: itemGroup.id,
                toString: () => key,
              },
            });
          });

          questionsAndGroupsOptionsItems.push({
            label: `${currentPageIndex}.${currentSectionIndex}: ${questionGroup.label.en}`,
            value: {
              id,
              choices: questionGroup.choices,
              questions: questionGroup.questions,
            },
          });
        }

        const isQuestionOrGroup = itemQuestion || itemGroup;

        if (isQuestionOrGroup) {
          // this label is for the non selectable labels, the sub options are the selectable
          const label = `${currentPageIndex}.${currentSectionIndex}.${
            itemIndex + 1
          }: ${
            itemQuestion?.question?.content?.en ||
            itemGroup?.questionGroup?.label?.en ||
            ''
          }`;
          
          allOptions.push({
            label,
            options: allItemsOptions,
          });

          questionsAndGroupsOptions.push({
            label,
            options: allItemsOptions,
          });
        }
      });

      const sectionLabel = section.title.en
        ? `${currentPageIndex}.${currentSectionIndex}: ${section.title.en}`
        : `${currentPageIndex}.${currentSectionIndex}: ${SurveyElement.SECTION}`;

      allQuestionsOptions.push({
        label: sectionLabel,
        options: allOptions,
      });

      questionsAndGroups.push({
        label: sectionLabel,
        options: questionsAndGroupsOptions,
      });
    });
  });

  return { allOptions: allQuestionsOptions, questionsAndGroups };
};

export const getTextBoxOptions = (pages, pagesOrder, sections, textBoxes) => {
  const newSubjects = [];
  let sectionIndex = 1;
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];
    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];
      const options = [];
      section.itemIds.forEach((itemId) => {
        const item = textBoxes[itemId];
        if (item?.type === SurveyElement.TEXT_BOX) {
          options.push({
            label: `${item.content.en}`,
            value: { id: item.id, content: item.content.en },
          });
        }
      });
      newSubjects.push({
        label: section.title.en || `${SurveyElement.SECTION} ${sectionIndex}`,
        options,
      });
      sectionIndex += 1;
    });
  });
  return newSubjects;
};

export const getPagesOptions = (pages, pagesOrder) => {
  const newSubjects = [];
  pagesOrder.forEach((pageId, index) => {
    const page = pages[pageId];
    newSubjects.push({
      label: page.title.en
        ? `${page.title.en}`
        : `${SurveyElement.PAGE} ${index + 1}`,
      value: { id: page.id },
    });
  });
  return newSubjects;
};

export const getSectionsOptions = (pages, pagesOrder, sections) => {
  const newSubjects = [];
  let sectionIndex = 1;
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];
    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];
      newSubjects.push({
        label: section.title.en
          ? `${section.title.en}`
          : `${SurveyElement.SECTION} ${sectionIndex}`,
        value: { id: section.id },
      });
      sectionIndex += 1;
    });
  });
  return newSubjects;
};

export const getActionsForSelectedQuestion = (actions, selectedQuestion) => {
  const selectedActions = actions.filter((actionItem) => {
    let hasQuestion = false;
    actionItem.actions.forEach((action) => {
      hasQuestion =
        hasQuestion ||
        action.subject.id === selectedQuestion.value.id ||
        action.group?.id === selectedQuestion.value.id;
    });
    actionItem.conditions.forEach((condition) => {
      hasQuestion =
        hasQuestion ||
        (condition.type === ActionBuilderConditionTypes.CONDITION &&
          (condition.subject.id === selectedQuestion.value.id ||
            condition.group === selectedQuestion.value.id));
    });
    return hasQuestion;
  });
  return selectedActions;
};

export const getTransformedActions = (
  actions,
  allQuestions,
  questionGroups,
  textBoxes,
  sections,
  pages
) => {
  const newActions = [];

  actions.forEach((actionItem) => {
    let groupIndex = 1;
    const transformedActions = [];
    actionItem.actions.forEach((action) => {
      if (
        action.action === AvailableActions.SHOW_QUESTION ||
        action.action === AvailableActions.HIDE_QUESTION
      ) {
        const question = allQuestions[action.subject];

        if (question) {
          transformedActions.push({
            ...action,
            subject: { id: action.subject, title: question.title },
          });
        }
      }
      if (
        action.action === AvailableActions.SHOW_QUESTION_GROUP ||
        action.action === AvailableActions.HIDE_QUESTION_GROUP
      ) {
        const questionGroup = questionGroups[action.subject];

        if (questionGroup) {
          transformedActions.push({
            ...action,
            subject: {
              id: action.subject,
              title:
                questionGroup.questionGroup.label.en || `Group ${groupIndex}`,
            },
          });
        }
        groupIndex += 1;
      }
      if (
        action.action === AvailableActions.SHOW_TEXT_BOX ||
        action.action === AvailableActions.HIDE_TEXT_BOX
      ) {
        const textBox = textBoxes[action.subject];

        if (textBox) {
          transformedActions.push({
            ...action,
            subject: { id: action.subject, title: textBox.content.en },
          });
        }
      }
      if (
        action.action === AvailableActions.SHOW_PAGE ||
        action.action === AvailableActions.HIDE_PAGE
      ) {
        const page = pages[action.subject];
        const index = Object.keys(pages).indexOf(action.subject);

        if (page) {
          transformedActions.push({
            ...action,
            subject: {
              id: action.subject,
              title: page.title.en || `${SurveyElement.PAGE} ${index + 1}`,
            },
          });
        }
      }
      if (
        action.action === AvailableActions.SHOW_SECTION ||
        action.action === AvailableActions.HIDE_SECTION
      ) {
        const section = sections[action.subject];
        const index = Object.keys(sections).indexOf(action.subject);

        if (section) {
          transformedActions.push({
            ...action,
            subject: {
              id: action.subject,
              title:
                section.title.en || `${SurveyElement.SECTION} ${index + 1}`,
            },
          });
        }
      }
      if (
        action.action === AvailableActions.SHOW_CHOICES ||
        action.action === AvailableActions.HIDE_CHOICES ||
        action.action === AvailableActions.RANDOMIZE_CHOICES
      ) {
        const question = allQuestions[action.subject];
        const transformedChoices = [];

        let title = '';
        let matching = false;

        if (question?.groupId) {
          matching = true;
          title = question.groupTitle;

          question.choices.forEach((choice) => {
            if (choice.questionChoices) {
              choice.questionChoices.forEach((questionChoice) => {
                if (action.choices.includes(questionChoice.id)) {
                  transformedChoices.push({
                    id: questionChoice.id,
                    title: questionChoice.option.en,
                  });
                }
              });

              return;
            }

            if (action.choices.includes(choice.id)) {
              transformedChoices.push({
                id: choice.id,
                title: choice.option.en,
              });
            }
          });
        } else if (question) {
          matching = true;
          title = question.title;
          allQuestions[action.subject].choices.forEach((choice) => {
            if (action.choices.includes(choice.id)) {
              transformedChoices.push({
                id: choice.id,
                title: choice.option.en,
              });
            }
          });
        }

        if (matching) {
          transformedActions.push({
            ...action,
            subject: {
              id: action.subject,
              title,
            },
            choices: transformedChoices,
          });
        }
      }
      if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
        const randomizeQuestions = [];
        let matching = false;
        action.subject.forEach((questionId) => {
          const question = allQuestions[questionId];

          if (question) {
            matching = true;
            randomizeQuestions.push({
              id: questionId,
              title: question.title,
            });
          }
        });

        if (matching) {
          transformedActions.push({
            ...action,
            subject: randomizeQuestions,
          });
        }
      }
      if (action.action === AvailableActions.RANDOMIZE_SECTIONS) {
        const randomizeQuestions = [];
        let matching = false;

        action.subject.forEach((sectionId) => {
          const section = sections[sectionId];
          const index = Object.keys(sections).indexOf(sectionId);

          if (section) {
            matching = true;
            randomizeQuestions.push({
              id: sectionId,
              title:
                section.title.en || `${SurveyElement.SECTION} ${index + 1}`,
            });
          }
        });

        if (matching) {
          transformedActions.push({
            ...action,
            subject: randomizeQuestions,
          });
        }
      }
      if (action.action === AvailableActions.RANDOMIZE_PAGES) {
        const randomizeQuestions = [];
        let matching = false;

        action.subject.forEach((pageId) => {
          const page = pages[pageId];
          const index = Object.keys(pages).indexOf(pageId);
          if (page) {
            matching = true;
            randomizeQuestions.push({
              id: pageId,
              title: page.title.en || `${SurveyElement.PAGE} ${index + 1}`,
            });
          }
        });

        if (matching) {
          transformedActions.push({
            ...action,
            subject: randomizeQuestions,
          });
        }
      }
    });

    const transformedConditions = [];

    actionItem.conditions.forEach((condition) => {
      if (condition.type === ActionBuilderConditionTypes.CONDITION) {
        const question = allQuestions[condition.subject];
        const transformedValues = [];

        if (question) {
          allQuestions[condition.subject].choices.forEach((choice) => {
            if (choice.questionChoices) {
              choice.questionChoices.forEach((questionChoice) => {
                if (
                  condition.values.includes(
                    `${questionChoice.dataSetId}.${questionChoice.id}`
                  )
                ) {
                  transformedValues.push({
                    id: questionChoice.id,
                    title: questionChoice.option.en,
                  });
                }
              });

              return;
            }

            if (condition.values.includes(choice.id)) {
              transformedValues.push({
                id: choice.id,
                title: choice.option.en,
              });
            }
          });

          transformedConditions.push({
            ...condition,
            subject: {
              id: condition.subject,
              title: question.title,
            },
            values: transformedValues,
          });
        }
      } else {
        transformedConditions.push({
          ...condition,
        });
      }
    });

    newActions.push({
      ...actionItem,
      actions: transformedActions,
      conditions: transformedConditions,
    });
  });
  return newActions;
};

export const mapActionConditionValuesToIds = (condition) => {
  const values = condition.values.map((value) => {
    if (value.value.dataSetId) {
      return `${value.value.dataSetId}.${value.value.id}`;
    }

    return value.value.id;
  });

  return values;
};

export const getAllQuestions = (
  pages,
  pagesOrder,
  sections,
  questions,
  questionGroups
) => {
  const questionTitles = {};
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];

    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];

      section.itemIds.forEach((itemId) => {
        const questionItem = questions[itemId];
        const groupItem = questionGroups[itemId];

        if (groupItem) {
          groupItem.questionGroup.questions.forEach((question) => {
            questionTitles[question.id] = {
              id: question.id,
              title: `${question.content.en}`,
              groupTitle: `${groupItem.questionGroup.label.en}`,
              groupId: groupItem.id,
              itemId: groupItem.id,
              choices: groupItem.questionGroup.choices,
              type: question.type,
            };
          });
        } else if (questionItem?.type === SurveyElement.QUESTION) {
          questionTitles[questionItem.question.id] = {
            title: `${questionItem.question.content.en}`,
            itemId: questionItem.id,
            id: questionItem.question.id,
            choices: questionItem.question.choices,
            type: questionItem.question.type,
          };
        }
      });
    });
  });

  return questionTitles;
};

export const getQuestionsWithActionsOptions = (
  actions,
  pages,
  pagesOrder,
  sections,
  allQuestions,
  questionGroups
) => {
  const questionsWithActions = {};
  actions.forEach((actionItem) => {
    actionItem.actions.forEach((action) => {
      const questionItem = allQuestions[action.subject.id];
      const groupItem = questionGroups[action.subject.id];
      if (questionItem) {
        questionsWithActions[questionItem.itemId] = {
          id: action.subject.id,
          title: action.subject.title,
        };
      } else if (groupItem) {
        questionsWithActions[groupItem.id] = {
          id: action.subject.id,
          title: action.subject.title,
        };
      }
    });
    actionItem.conditions.forEach((condition) => {
      if (condition.type === ActionBuilderConditionTypes.CONDITION) {
        const item = allQuestions[condition.subject.id];
        questionsWithActions[item.itemId] = {
          id: condition.subject.id,
          title: condition.subject.title,
        };
      }
    });
  });

  const newSubjects = [];
  let sectionIndex = 1;
  pagesOrder.forEach((pageId) => {
    const page = pages[pageId];
    page.sectionIds.forEach((sectionId) => {
      const section = sections[sectionId];
      const options = [];
      section.itemIds.forEach((itemId) => {
        const item = questionsWithActions[itemId];
        if (item) {
          options.push({
            label: `${item.title}`,
            value: item,
          });
        }
      });
      newSubjects.push({
        label: section.title.en || `${SurveyElement.SECTION} ${sectionIndex}`,
        options,
      });
      sectionIndex += 1;
    });
  });
  return newSubjects;
};
