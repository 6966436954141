import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import ErrorBoundary from 'components/ErrorBoundary';

const PublicRoute = ({ path, component: Component }) => (
  <Route
    path={path}
    render={() => (
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    )}
  />
);

PublicRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PublicRoute;
