import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { QuestionType } from 'utils/surveyBuilder/enums';
import {
  ConditionOperators,
  ConditionOperatorsForSingleResponse,
  ActionsSelectValues,
} from 'utils/enums';
import Select from 'components/Select';
import classes from './Condition.module.scss';

const allOperators = Object.keys(ConditionOperators).map((operator) => {
  return {
    label: ConditionOperators[operator],
    value: operator,
  };
});

const singleResponseOperators = Object.keys(
  ConditionOperatorsForSingleResponse
).map((operator) => {
  return {
    label: ConditionOperatorsForSingleResponse[operator],
    value: operator,
  };
});

const Condition = ({
  condition: { subject, criteria, values },
  index,
  onDeleteCondition,
  onChangeCondition,
  canDelete,
  subjects,
}) => {
  const [valueOptions, setValueOptions] = useState([]);

  useEffect(() => {
    if (subject) {
      const valuesOptions = subject.value.choices;
      const options = valuesOptions.flatMap((option, i) => {
        if (option.questionChoices) {
          return option.questionChoices.map((questionChoice) => {
            return {
              label: questionChoice.option.en,
              value: {
                id: questionChoice.id,
                dataSetId: questionChoice.dataSetId,
                title: questionChoice.option.en,
                toString: () => i,
              },
            };
          });
        }
        return {
          label: option.option.en,
          value: { id: option.id, title: option.option.en, toString: () => i },
        };
      });
      setValueOptions(options);
    }
  }, [subject]);

  const isMultipleResponseQuestion = useMemo(() => {
    return subject?.value?.type === QuestionType.MULTIPLE_RESPONSE;
  }, [subject]);

  const isMultipleSelect = useCallback(() => {
    return (
      criteria.label === ConditionOperators.CONTAINS_ONE_OF ||
      criteria.label === ConditionOperators.CONTAINS_ALL_OF ||
      criteria.label === ConditionOperators.DOES_NOT_CONTAIN_ANY_OF
    );
  }, [criteria]);

  const showValue = useMemo(
    () =>
      criteria &&
      criteria.label !== ConditionOperators.HAS_A_RESPONSE &&
      criteria.label !== ConditionOperators.HAS_NO_RESPONSE,
    [criteria]
  );

  const onChangeHandler = useCallback(
    (attribute, e) => {
      if (Array.isArray(e) || attribute !== ActionsSelectValues.VALUES) {
        onChangeCondition(index, attribute, e);
      } else {
        onChangeCondition(index, attribute, [e]);
      }
    },
    [index, onChangeCondition]
  );

  const getValueOfSubject = useCallback(() => {
    let selectedOption = null;

    subjects.forEach((subjectSection) => {
      subjectSection.options.forEach((itemsOptions) => {
        itemsOptions.options.forEach((option) => {
          const isSelected = option.value?.id === subject?.value.id;

          if (isSelected) {
            selectedOption = option;
          }
        });
      });
    });
    return selectedOption;
  }, [subject, subjects]);

  const getValueOfCriteria = useCallback(() => {
    let selectedOption = null;
    Object.values(allOperators).forEach((operator) => {
      if (operator.label === criteria?.label) {
        selectedOption = operator;
      }
    });
    return selectedOption;
  }, [criteria]);

  const getValueOfValues = useCallback(() => {
    const selectedOption = [];
    Object.values(valueOptions).forEach((valueOption) => {
      values.forEach((value) => {
        if (valueOption.value.id === value?.value.id) {
          selectedOption.push(valueOption);
        }
      });
    });
    return selectedOption;
  }, [valueOptions, values]);

  return (
    <div
      className={classNames('field is-horizontal', classes['field-horizontal'])}
    >
      <div className={classes['field-body-select']}>
        <div className={classNames('field', classes.field)}>
          <div className="control">
            <Select
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              placeholder="Select subject"
              value={getValueOfSubject()}
              options={subjects}
              onChange={(e) => onChangeHandler('subject', e)}
              maxMenuHeight={250}
            />
          </div>
        </div>
        {subject && (
          <div className={classNames('field', classes.field)}>
            <div className="control">
              <Select
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Select criteria"
                value={getValueOfCriteria()}
                options={
                  isMultipleResponseQuestion
                    ? allOperators
                    : singleResponseOperators
                }
                onChange={(e) => onChangeHandler('criteria', e)}
                maxMenuHeight={150}
              />
            </div>
          </div>
        )}
        {showValue && (
          <div className={classNames('field', classes.field)}>
            <div className="control">
              <Select
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                isMulti={isMultipleSelect()}
                placeholder="Select value"
                value={getValueOfValues()}
                options={valueOptions}
                onChange={(e) => onChangeHandler('values', e)}
                maxMenuHeight={150}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classNames('field-body', classes['delete-field'])}>
        <button
          id="delete-condition"
          type="button"
          className={classNames(
            'button',
            'is-small',
            'is-danger',
            classes['delete-button']
          )}
          onClick={() => onDeleteCondition(index)}
          disabled={!canDelete}
        >
          <span className="icon is-small">
            <i className="mdi mdi-trash-can" />
          </span>
        </button>
      </div>
    </div>
  );
};

Condition.propTypes = {
  condition: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string,
      subject: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.shape({
            id: PropTypes.string,
            question: PropTypes.shape({
              title: PropTypes.shape({
                en: PropTypes.string,
              }),
              choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
            }),
          }),
        }),
      }),
      criteria: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      values: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          }),
        })
      ),
    }),
    PropTypes.shape({
      type: PropTypes.string,
      operator: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  ]),
  index: PropTypes.number.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default Condition;
