/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isString } from 'utils';
import { LanguageIsoCode } from 'utils/enums';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import { generateLiveSurveyUIBreadcrumb } from 'utils/sentry/breadcrumbs/generateLiveSurveyBreadcrumb';
import useCustomMediaQuery from 'hooks/useCustomMediaQuery';

import classes from './QuestionGroup.module.scss';

const tabletSize = 768;

const QuestionGroup = ({
  label,
  questions,
  surveyLanguage,
  choices,
  columnWidths,
  currentResponse,
  onChangeInput,
  error,
  isChoiceInRequiredQuestion,
}) => {
  const isTablet = useCustomMediaQuery(tabletSize);

  const mappedChoices = useMemo(() => {
    const finalChoices = [];

    choices.forEach((choice) => {
      if (choice.questionChoices) {
        const questionChoices = [];

        choice.questionChoices.forEach((questionChoice) => {
          if (questionChoice.visible) {
            questionChoices.push({
              ...questionChoice,
              option: questionChoice.option[surveyLanguage.code],
            });
          }
        });

        return finalChoices.push({
          ...choice,
          questionChoices,
        });
      }

      if (choice.visible) {
        return finalChoices.push({
          ...choice,
          option: choice.option[surveyLanguage.code],
        });
      }

      return null;
    });

    return finalChoices;
  }, [choices, surveyLanguage]);

  const isDatasetAnswered = (questionId, dataSetId) =>
    Object.values(currentResponse[questionId].choices[dataSetId]).some(
      (choice) => choice
    );

  const questionsColumnSpan =
    columnWidths?.[0] &&
    Number.isInteger(Number(columnWidths[0])) &&
    Number(columnWidths[0]) <= 10 &&
    Number(columnWidths[0]) > 0
      ? columnWidths[0]
      : 1;

  return isTablet ? (
    <>
      <div className="subtitle is-5">{label}</div>
      <table className={classes.table}>
        <thead className={classes['table-header']}>
          {mappedChoices.length > 1 && (
            <tr className={classes['table-header-row']}>
              <th colSpan={questionsColumnSpan} />
              {mappedChoices.map(({ dataSetId, questionChoices, title }) => {
                if (questionChoices) {
                  const renderTitle = isString(title)
                    ? title
                    : title?.[surveyLanguage.code] ||
                      title?.[LanguageIsoCode.EN];

                  return (
                    <React.Fragment key={dataSetId}>
                      <th
                        colSpan={questionChoices.length}
                        className={classNames(
                          classes['header-title'],
                          classes['choice-name'],
                          classes['row-border-left'],
                          classes['row-border-bottom']
                        )}
                      >
                        {renderTitle || ''}
                      </th>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </tr>
          )}
          <tr>
            <td
              className={classes['first-column']}
              colSpan={questionsColumnSpan}
            />
            {mappedChoices.map(({ id, option, questionChoices }) => {
              if (questionChoices) {
                return questionChoices.map(
                  (
                    { id: questionChoiceId, option: questionChoiceOption },
                    questionChoiceIndex
                  ) => (
                    <td
                      key={questionChoiceId}
                      className={classNames(classes['choice-name'], {
                        [classes['row-border-left']]:
                          questionChoiceIndex === 0 && mappedChoices.length > 1,
                      })}
                    >
                      {questionChoiceOption}
                    </td>
                  )
                );
              }

              return (
                <td key={id} className={classes['choice-name']}>
                  {option}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {questions.map(
            ({ id: questionId, content, rawContent, required }, index) => {
              return (
                <tr
                  key={questionId}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? 'rgba(0,0,0,0.10)' : 'rgba(0,0,0,0.02)',
                  }}
                >
                  <td
                    className={classes.question}
                    colSpan={questionsColumnSpan}
                  >
                    <span className={classes['question-content']}>
                      {content}
                    </span>
                    {required && (
                      <span className={classes['required-asterisk']}>*</span>
                    )}
                  </td>
                  {mappedChoices.map(
                    ({ id: choiceId, questionChoices, option, dataSetId }) => {
                      if (questionChoices) {
                        return questionChoices.map(
                          (
                            {
                              id: questionChoiceId,
                              option: questionChoiceOption,
                            },
                            questionChoiceIndex
                          ) => (
                            <td
                              key={questionChoiceId}
                              className={classNames(classes['choice-input'], {
                                [classes['row-border-left']]:
                                  questionChoiceIndex === 0 &&
                                  mappedChoices.length > 1,
                              })}
                            >
                              <label className="b-radio radio">
                                <input
                                  type="radio"
                                  name={`${questionId}-${dataSetId}`}
                                  data-questionid={questionId}
                                  data-datasetname={dataSetId}
                                  value={questionChoiceId}
                                  checked={
                                    currentResponse[questionId].choices[
                                      dataSetId
                                    ][questionChoiceId]
                                  }
                                  onChange={onChangeInput}
                                  data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                                    SurveyElement.QUESTION_GROUP,
                                    {
                                      question: rawContent,
                                      response: questionChoiceOption,
                                    }
                                  )}
                                />
                                <span
                                  className={classNames('check', {
                                    [classes['checkbox-error']]:
                                      isChoiceInRequiredQuestion(questionId) &&
                                      !isDatasetAnswered(questionId, dataSetId),
                                  })}
                                />
                              </label>
                            </td>
                          )
                        );
                      }

                      return (
                        <td key={choiceId} className={classes['choice-input']}>
                          <label className="b-radio radio">
                            <input
                              type="radio"
                              name={questionId}
                              value={choiceId}
                              checked={
                                currentResponse[questionId].choices[choiceId]
                              }
                              onChange={onChangeInput}
                              data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                                SurveyElement.QUESTION_GROUP,
                                {
                                  question: rawContent,
                                  response: option,
                                }
                              )}
                            />
                            <span
                              className={classNames('check', {
                                [classes['checkbox-error']]:
                                  isChoiceInRequiredQuestion(questionId),
                              })}
                            />
                          </label>
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      <div>
        {error && (
          <span className={classes['required-checkbox']}>
            Please answer the required questions of the group.
          </span>
        )}
      </div>
    </>
  ) : (
    questions.map(({ id: questionId, content, rawContent, required }) => {
      return (
        <div key={questionId}>
          <span
            className={classNames(
              classes['question-content'],
              classes['mobile-question-content']
            )}
          >
            {content}
          </span>
          {required && <span className={classes['required-asterisk']}>*</span>}
          <div className={classes['choice-container']}>
            {mappedChoices.map(
              ({ id: choiceId, option, dataSetId, questionChoices, title }) => {
                if (questionChoices) {
                  const renderTitle = isString(title)
                    ? title
                    : title?.[surveyLanguage.code] || title?.en;

                  return (
                    <div className={classes['mobile-choices-container']}>
                      <p>{renderTitle || ''}</p>
                      {questionChoices.map(
                        ({
                          id: questionChoiceId,
                          option: questionChoiceOption,
                        }) => (
                          <label
                            className={classNames(
                              'b-radio radio',
                              classes['choice-label']
                            )}
                            key={`${questionChoiceId}-${questionChoiceOption}`}
                          >
                            <input
                              type="radio"
                              name={`${questionId}-${dataSetId}`}
                              data-questionid={questionId}
                              data-datasetname={dataSetId}
                              value={questionChoiceId}
                              checked={
                                currentResponse[questionId].choices[dataSetId][
                                  questionChoiceId
                                ]
                              }
                              onChange={onChangeInput}
                              data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                                SurveyElement.QUESTION_GROUP,
                                {
                                  question: rawContent,
                                  response: questionChoiceOption,
                                }
                              )}
                            />
                            <span
                              className={classNames('check', {
                                [classes['checkbox-error']]:
                                  isChoiceInRequiredQuestion(questionId) &&
                                  !isDatasetAnswered(questionId, dataSetId),
                              })}
                            />
                            <span className="control-label">
                              {questionChoiceOption}
                            </span>
                          </label>
                        )
                      )}
                    </div>
                  );
                }

                return (
                  <label
                    className={classNames(
                      'b-radio radio',
                      classes['choice-label']
                    )}
                    key={`${choiceId}-${option}`}
                  >
                    <input
                      type="radio"
                      name={questionId}
                      value={choiceId}
                      checked={currentResponse[questionId].choices[choiceId]}
                      onChange={onChangeInput}
                      data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                        SurveyElement.QUESTION_GROUP,
                        {
                          question: rawContent,
                          response: option,
                        }
                      )}
                    />
                    <span
                      className={classNames('check', {
                        [classes['checkbox-error']]:
                          isChoiceInRequiredQuestion(questionId),
                      })}
                    />
                    <span className="control-label">{option}</span>
                  </label>
                );
              }
            )}
          </div>
        </div>
      );
    })
  );
};

QuestionGroup.propTypes = {
  label: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentResponse: PropTypes.object.isRequired,
  isChoiceInRequiredQuestion: PropTypes.func.isRequired,
};

export default QuestionGroup;
