import 'polifills.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import * as Sentry from '@sentry/react';

import { configureStore } from 'state/store';
import LanguageWrapper from 'components/LanguageWrapper';
import Router from 'pages/Router';
import paths from 'pages/Router/paths';
import {
  selectIsPreview,
  selectOrganizationName,
  selectPageIndex,
  selectPagesWithActions,
  selectQuestionGroupsDemographics,
  selectQuestionResponses,
  selectQuestionsDemographics,
  selectResponse,
} from 'state/selectors/liveSurvey';
import { createSurveyResponseSave } from 'state/actions/surveys';
import { processShownResponses } from 'utils/LiveSurvey';
import { mergeDemographics } from 'utils';

import * as serviceWorker from './serviceWorker';
import './assets/css/main.css';
import './index.scss';

const { store, persistor } = configureStore({});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment:
    process.env.NODE_ENV === 'development'
      ? 'local'
      : process.env.REACT_APP_SENTRY_PROJECT_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
  beforeBreadcrumb(breadcrumb, hint) {
    let finalBreadcrumb = breadcrumb;
    try {
      if (breadcrumb.category.startsWith('ui')) {
        const customMessage = hint.event.target.getAttribute(
          'data-sentry-custom-breadcrumb'
        );

        if (customMessage) {
          finalBreadcrumb.message = customMessage;
        }
      }
    } catch (e) {
      finalBreadcrumb = breadcrumb;
    }

    return finalBreadcrumb;
  },
  async beforeSend(event) {
    if (
      event.exception &&
      window.location.pathname.includes(paths.LIVE_SURVEY)
    ) {
      const state = store.getState();
      const pagesWithActions = selectPagesWithActions(state);
      const questionResponses = selectQuestionResponses(state);
      const organizationName = selectOrganizationName(state);
      const pageIndex = selectPageIndex(state);
      const response = selectResponse(state);
      const isPreview = selectIsPreview(state);
      const questionDemographics = selectQuestionsDemographics({
        isPreview,
      })(state);
      const questionGroupDemographics = selectQuestionGroupsDemographics({
        isPreview,
      })(state);

      try {
        const shownResponses = processShownResponses({
          pagesWithActions,
          questionResponses,
        });

        const demographics = mergeDemographics(
          questionDemographics,
          questionGroupDemographics
        );

        const responseCode = await store.dispatch(
          createSurveyResponseSave({
            ...response,
            pagesWithActions,
            questionResponses,
            shownResponses,
            demographics,
            organizationName,
            pageIndex,
          })
        );

        const newEvent = {
          ...event,
          user: {
            ...event.user,
            responseCode,
          },
        };

        return newEvent;
      } catch (error) {
        console.error('Error sending event to Sentry', error);
      }
    }

    return event;
  },
});

const app = (
  <Provider store={store}>
    <LanguageWrapper>
      <PersistGate persistor={persistor}>
        <ReduxToastr
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          getState={(state) => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <Router />
      </PersistGate>
    </LanguageWrapper>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
