const processShownResponses = ({ pagesWithActions, questionResponses }) => {
  const shownResponses = {};

  Object.values(pagesWithActions).forEach((page) => {
    if (page.visible) {
      page.sections.forEach((section) => {
        if (section.visible) {
          section.items.forEach((item) => {
            if (item.visible && item.question) {
              const questionId = item.question.id;
              const visibleChoices = {};
              const questionResponse = { ...questionResponses[questionId] };

              item.question.choices.forEach((choice) => {
                if (choice.visible) {
                  visibleChoices[choice.id] =
                    questionResponse.choices[choice.id] ?? false;
                }
              });

              questionResponse.choices = visibleChoices;
              shownResponses[questionId] = questionResponse;
            } else if (item.visible && item.questionGroup) {
              item.questionGroup.questions.forEach((question) => {
                const questionId = question.id;
                const visibleChoices = {};
                const questionResponse = {
                  ...questionResponses[questionId],
                };

                item.questionGroup.choices.forEach((choice) => {
                  if (choice.questionChoices) {
                    choice.questionChoices.forEach((questionChoice) => {
                      if (questionChoice.visible) {
                        if (!visibleChoices[questionChoice.dataSetId]) {
                          visibleChoices[questionChoice.dataSetId] = {};
                        }

                        visibleChoices[questionChoice.dataSetId][
                          questionChoice.id
                        ] =
                          questionResponse.choices[questionChoice.dataSetId][
                            questionChoice.id
                          ] ?? false;
                      }
                    });

                    return;
                  }

                  if (choice.visible) {
                    visibleChoices[choice.id] =
                      questionResponse.choices[choice.id] ?? false;
                  }
                });

                questionResponse.choices = visibleChoices;
                shownResponses[questionId] = questionResponse;
              });
            } else if (!item.visible && item.question) {
              const questionId = item.question.id;
              const questionResponse = { ...questionResponses[questionId] };

              Object.keys(questionResponse.choices).forEach((choice) => {
                questionResponse.choices[choice] = false;
              });

              shownResponses[questionId] = questionResponse;
            } else if (!item.visible && item.questionGroup) {
              item.questionGroup.questions.forEach((question) => {
                const questionId = question.id;
                const questionResponse = {
                  ...questionResponses[questionId],
                };

                item.questionGroup.choices.forEach((choice) => {
                  if (choice.questionChoices) {
                    choice.questionChoices.forEach((questionChoice) => {
                      if (!questionResponse[questionChoice.dataSetId]) {
                        questionResponse[questionChoice.dataSetId] = {};
                      }

                      questionResponse.choices[questionChoice.dataSetId][
                        questionChoice.id
                      ] = false;
                    });

                    return;
                  }

                  questionResponse.choices[choice] = false;
                });

                shownResponses[questionId] = questionResponse;
              });
            }
          });
        } else {
          section.items.forEach((item) => {
            if (item.question) {
              const questionId = item.question.id;
              const questionResponse = { ...questionResponses[questionId] };

              Object.keys(questionResponse.choices).forEach((choice) => {
                questionResponse.choices[choice] = false;
              });

              shownResponses[questionId] = questionResponse;
            } else if (item.questionGroup) {
              item.questionGroup.questions.forEach((question) => {
                const questionId = question.id;
                const questionResponse = {
                  ...questionResponses[questionId],
                };

                item.questionGroup.choices.forEach((choice) => {
                  if (choice.questionChoices) {
                    choice.questionChoices.forEach((questionChoice) => {
                      if (!questionResponse[questionChoice.dataSetId]) {
                        questionResponse[questionChoice.dataSetId] = {};
                      }

                      questionResponse.choices[questionChoice.dataSetId][
                        questionChoice.id
                      ] = false;
                    });

                    return;
                  }

                  questionResponse.choices[choice] = false;
                });

                shownResponses[questionId] = questionResponse;
              });
            }
          });
        }
      });
    } else {
      page.sections.forEach((section) => {
        section.items.forEach((item) => {
          if (item.question) {
            const questionId = item.question.id;
            const questionResponse = { ...questionResponses[questionId] };

            Object.keys(questionResponse.choices).forEach((choice) => {
              questionResponse.choices[choice] = false;
            });
            shownResponses[questionId] = questionResponse;
          } else if (item.questionGroup) {
            item.questionGroup.questions.forEach((question) => {
              const questionId = question.id;
              const questionResponse = { ...questionResponses[questionId] };

              item.questionGroup.choices.forEach((choice) => {
                if (choice.questionChoices) {
                  choice.questionChoices.forEach((questionChoice) => {
                    if (!questionResponse[questionChoice.dataSetId]) {
                      questionResponse[questionChoice.dataSetId] = {};
                    }

                    questionResponse.choices[questionChoice.dataSetId][
                      questionChoice.id
                    ] = false;
                  });

                  return;
                }

                questionResponse.choices[choice] = false;
              });

              shownResponses[questionId] = questionResponse;
            });
          }
        });
      });
    }
  });

  return shownResponses;
};

export { processShownResponses };
