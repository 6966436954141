import { v4 as uuid } from 'uuid';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL,
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT,
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS,
} from 'state/actionCreators/surveys';
import {
  AvailableActions,
  ServiceOperations,
  ServiceSubOperations,
} from 'utils/enums';
import {
  questionChoicesCollection,
  questionsCollection,
  questionTagCollection,
  surveysCollection,
  surveyVersionsCollection,
} from 'utils/firebase/surveys';
import { checkSurveyExistance } from 'services/surveys/utils';
import { SurveyElement, SurveyType } from 'utils/surveyBuilder/enums';
import { firebaseError } from 'utils';
import { newActionQuestionGroupAssign } from 'utils/actions';
import { indexSurveys } from 'utils/algolia';
import { saveErrorLog } from 'services/utils';

export const createOrganizationSurveyFromTemplate = ({
  organizationId,
  organizationName,
  surveyId,
  surveyName,
  versionId,
  versionName,
  organizationDisplaySiteName,
  languageCompletion = {},
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT());

    const { locale } = getState().preferences;

    const newSurveyId = uuid();
    const newVersionId = uuid();

    const { firstName, lastName } = getState().auth.userData;
    const createdBy = `${firstName} ${lastName}`;

    try {
      const surveyIsDuplicated = await checkSurveyExistance(
        newSurveyId,
        surveyName.trim(),
        SurveyType.ORGANIZATION
      );

      if (surveyIsDuplicated) {
        const errorMessage = `Survey with name "${surveyName}" already exists.`;
        toastr.error('', errorMessage);
        return dispatch(
          SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
            error: errorMessage,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]
            .VERIFY_DUPLICATE_SURVEY,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

    let version;

    try {
      const versionDoc = await surveyVersionsCollection(surveyId)
        .doc(versionId)
        .get();

      const {
        items,
        pages,
        pagesOrder,
        sections,
        status,
        title,
        availableLanguages,
        defaultLanguage,
        isCondensed,
        actions,
      } = versionDoc.data();

      version = {
        name: versionName.trim() || 'Version 1',
        items,
        pages,
        pagesOrder,
        sections,
        status,
        title,
        createdAt,
        createdBy,
        availableLanguages,
        defaultLanguage,
        isCondensed,
        actions,
        languageCompletion,
      };
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_VERSION,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_VERSION]
            .GET_ORIGINAL_SURVEY,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    const survey = {
      name: surveyName.trim(),
      type: 'organization',
      versionCount: 1,
      versions: [
        {
          id: newVersionId,
          name: version.name,
          status: version.status,
          createdAt,
        },
      ],
      deploymentCount: 0,
      deployments: [],
      organizationName,
      organizationId,
      organizationDisplaySiteName,
      createdBy,
      createdAt,
    };

    const questions = [];
    const questionTag = [];
    const questionChoices = [];
    const newActions = [...version.actions];

    Object.values(version.items).forEach((item) => {
      if (item.type === SurveyElement.QUESTION) {
        const { id, question } = item;
        const {
          id: oldQuestionId,
          content,
          dataSet,
          tags,
          choices,
          required,
          choicesOrder,
          regionalBreakdown,
          sliderProps,
        } = question;

        const questionId = uuid();

        version.items[id].question.id = questionId;

        const questionContentParsed = {};

        Object.entries(content).forEach(([language, questionContent]) => {
          questionContentParsed[language] = questionContent.trim();
        });

        questions.push({
          id: questionId,
          question: {
            content: questionContentParsed,
            type: question.type,
            dataSetId: dataSet.id ?? null,
            surveyId: newSurveyId,
            sliderProps: sliderProps ?? null,
            surveyVersionId: newVersionId,
            surveyDeploymentId: null,
            required,
            choicesOrder,
            template: {
              questionId: oldQuestionId,
              surveyId,
              versionId,
            },
            regionalBreakdown,
            organizationId,
          },
        });

        tags.forEach(({ id: tagId, weight }) => {
          questionTag.push({ tagId, questionId, weight });
        });

        // TODO: replace this for newActionsQuestionAssign function
        for (let index = 0; index < newActions.length; index += 1) {
          const { actions: oldActions, conditions: oldConditions } =
            newActions[index];

          for (
            let indexAction = 0;
            indexAction < oldActions.length;
            indexAction += 1
          ) {
            const action = oldActions[indexAction];

            if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
              action.subject.forEach((subjectId, indexSubject) => {
                if (subjectId === oldQuestionId) {
                  newActions[index].actions[indexAction].subject[indexSubject] =
                    questionId;
                }
              });
            }

            if (action.subject === oldQuestionId) {
              newActions[index].actions[indexAction].subject = questionId;
            }
          }

          for (
            let indexCondition = 0;
            indexCondition < oldConditions.length;
            indexCondition += 1
          ) {
            const condition = oldConditions[indexCondition];

            if (condition.subject === oldQuestionId) {
              newActions[index].conditions[indexCondition].subject = questionId;
            }
          }
        }

        // newActionsQuestionAssign(newActions, oldQuestionId, questionId);

        if (!dataSet.id) {
          choices.forEach(
            (
              {
                id: oldChoiceId,
                option,
                paramOne,
                paramTwo,
                openEnded,
                notApplicable,
                visible,
                sortOrder,
              },
              index
            ) => {
              const choiceId = uuid();

              newActions.forEach(
                (
                  { actions: changedActions, conditions: changedConditions },
                  allActionsIndex
                ) => {
                  changedActions.forEach((action, actionIndex) => {
                    if (
                      action.action === AvailableActions.HIDE_CHOICES ||
                      action.action === AvailableActions.SHOW_CHOICES ||
                      action.action === AvailableActions.RANDOMIZE_CHOICES
                    ) {
                      if (action.subject === questionId) {
                        action.choices.forEach((choice, choiceIndex) => {
                          if (choice === oldChoiceId) {
                            newActions[allActionsIndex].actions[
                              actionIndex
                            ].choices[choiceIndex] = choiceId;
                          }
                        });
                      }
                    }
                  });
                  changedConditions.forEach((condition, conditionIndex) => {
                    if (condition.subject === questionId) {
                      condition.values.forEach((value, valueIndex) => {
                        if (value === oldChoiceId) {
                          newActions[allActionsIndex].conditions[
                            conditionIndex
                          ].values[valueIndex] = choiceId;
                        }
                      });
                    }
                  });
                }
              );

              version.items[id].question.choices[index].id = choiceId;

              const choiceOptionParsed = {};

              Object.entries(option).forEach(([language, choiceOption]) => {
                choiceOptionParsed[language] = choiceOption.trim();
              });

              questionChoices.push({
                id: choiceId,
                choice: {
                  questionId,
                  option: choiceOptionParsed,
                  paramOne: paramOne?.trim() || null,
                  paramTwo: paramTwo?.trim() || null,
                  openEnded,
                  notApplicable,
                  dataSetId: null,
                  sortOrder,
                  visible,
                },
              });
            }
          );
        }
      }

      if (item.type === SurveyElement.QUESTION_GROUP) {
        const {
          id: questionGroupId,
          questionGroup: {
            dataSet: { id: dataSetId },
            questions: questionsInGroup,
          },
        } = item;

        questionsInGroup.forEach(
          (
            {
              id: oldQuestionId,
              content,
              tags,
              type: questionInGroupType,
              required,
              choicesOrder,
              template = null,
            },
            questionIndex
          ) => {
            const questionInGroupId = uuid();
            version.items[questionGroupId].questionGroup.questions[
              questionIndex
            ].id = questionInGroupId;

            const questionContentParsed = {};

            Object.entries(content).forEach(([language, questionContent]) => {
              questionContentParsed[language] = questionContent.trim();
            });

            questions.push({
              id: questionInGroupId,
              question: {
                id: questionInGroupId,
                content: questionContentParsed,
                type: questionInGroupType,
                dataSetId: dataSetId ?? null,
                surveyId: newSurveyId,
                surveyVersionId: newVersionId,
                surveyDeploymentId: null,
                required,
                choicesOrder,
                template,
                regionalBreakdown: false,
                organizationId,
              },
            });

            tags.forEach(({ id: tagId, weight }) => {
              questionTag.push({
                tagId,
                questionId: questionInGroupId,
                weight,
              });
            });

            newActionQuestionGroupAssign(
              newActions,
              oldQuestionId,
              questionGroupId
            );

            // old code
            // newActions.forEach(
            //   ({ actions: oldActions, conditions: oldConditions }, index) => {
            //     oldActions.forEach((action, indexAction) => {
            //       if (
            //         action.action === AvailableActions.RANDOMIZE_QUESTIONS
            //       ) {
            //         action.subject.forEach((subjectId, indexSubject) => {
            //           if (subjectId === oldQuestionId) {
            //             newActions[index].actions[indexAction].subject[
            //               indexSubject
            //             ] = questionInGroupId;
            //           }
            //         });
            //       }
            //       if (action.subject === oldQuestionId) {
            //         newActions[index].actions[indexAction].subject =
            //           questionInGroupId;
            //       }
            //     });
            //     oldConditions.forEach((condition, indexCondition) => {
            //       if (condition.subject === oldQuestionId) {
            //         newActions[index].conditions[indexCondition].subject =
            //           questionInGroupId;
            //       }
            //     });
            //   }
            // );
          }
        );
      }
    });

    version.actions = newActions;

    const surveyRef = surveysCollection.doc(newSurveyId);
    const versionRef = surveyVersionsCollection(newSurveyId).doc(newVersionId);

    try {
      const batch = firebase.firestore().batch();

      batch.set(surveyRef, survey);
      batch.set(versionRef, version);

      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]
            .CREATE_SURVEY,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const batch = firebase.firestore().batch();

      questions.forEach(({ id, question }) =>
        batch.set(questionsCollection.doc(id), question)
      );

      await batch.commit();
    } catch (error) {
      const batch = firebase.firestore().batch();

      batch.delete(surveyRef);
      batch.delete(versionRef);

      batch.commit();

      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]
            .QUESTIONS_CREATION,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const createQuestionTagTasks = [];

      questionTag.forEach((data) =>
        createQuestionTagTasks.push(questionTagCollection.add(data))
      );

      const createQuestionChoiceTasks = [];

      questionChoices.forEach(({ id, choice }) =>
        createQuestionChoiceTasks.push(
          questionChoicesCollection.doc(id).set(choice)
        )
      );

      await Promise.all([
        ...createQuestionChoiceTasks,
        ...createQuestionTagTasks,
      ]);
    } catch (error) {
      const batch = firebase.firestore().batch();

      batch.delete(surveyRef);
      batch.delete(versionRef);

      questions.forEach(({ id }) => batch.delete(questionsCollection.doc(id)));

      batch.commit();

      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]
            .QUESTION_CHOICES_CREATION,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      await indexSurveys
        .saveObject({
          objectID: newSurveyId,
          ...survey,
          createdAt: +new Date(),
        })
        .wait();
    } catch (error) {
      const batch = firebase.firestore().batch();

      batch.delete(surveyRef);
      batch.delete(versionRef);

      questions.forEach(({ id }) => batch.delete(questionsCollection.doc(id)));

      batch.commit();

      const errorMessage = error.message;
      toastr.error('', errorMessage);

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY_FROM_TEMPLATE]
            .ALGOLIA_INDEX_UPDATE,
        createdBy: createdBy ?? null,
      });

      return dispatch(
        SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(
      SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS({
        surveyId: newSurveyId,
        versionId: newVersionId,
      })
    );
  };
};
