/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { toastr } from 'react-redux-toastr';

import {
  SURVEYS_CREATE_SURVEY_FAIL,
  SURVEYS_CREATE_SURVEY_INIT,
  SURVEYS_CREATE_SURVEY_SUCCESS,
} from 'state/actionCreators/surveys';
import {
  verifyIfSurveyIsDuplicated,
  setSurveyVersion,
  setQuestions,
  createQuestionChoices,
  createQuestionTags,
} from 'services/surveys/utils';
import { SurveyElement, SurveyVersionStatus } from 'utils/surveyBuilder/enums';
import { indexSurveys } from 'utils/algolia';
import {
  createSurveyObject,
  getBasicSurveyInfo,
  createSurveyVersionObject,
  trimMultipleLanguageContent,
  processQuestionItem,
  processQuestionGroupItem,
} from 'utils/actions';
import { saveErrorLog } from 'services/utils';
import { ServiceOperations, ServiceSubOperations } from 'utils/enums';

const rollbackOperations = async (operations) => {
  for (const operation of operations) {
    await operation();
  }
};

export const createSurvey = ({
  pages,
  items,
  actions,
  sections,
  pagesOrder,
  versionName,
  isCondensed,
  versionTitle,
  organizationId,
  defaultLanguage,
  availableLanguages,
  disableDeselect = false,
  languageCompletion = {},

  organizationDisplaySiteName,
  organizationName,
  type,
  name,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_SURVEY_INIT());

    const operationsRollbacks = [];

    const { locale, createdAt, surveyVersionId, surveyId, fullName } =
      getBasicSurveyInfo({
        getState,
      });

    const versionTitleParsed = trimMultipleLanguageContent(versionTitle);

    const surveyVersion = createSurveyVersionObject({
      name: versionName?.trim() || 'Version 1',
      title: versionTitleParsed,
      status: SurveyVersionStatus.ACTIVE,
      pages,
      pagesOrder,
      sections,
      items,
      createdAt,
      createdBy: fullName,
      availableLanguages,
      defaultLanguage,
      isCondensed,
      languageCompletion,
      disableDeselect,
      actions,
    });

    const survey = createSurveyObject({
      name: name.trim(),
      type,
      versionCount: 1,
      versions: [
        {
          id: surveyVersionId,
          name: surveyVersion.name,
          status: surveyVersion.status,
          createdAt,
        },
      ],
      deploymentCount: 0,
      deployments: [],
      organizationDisplaySiteName,
      organizationName,
      organizationId,
      createdBy: fullName,
      createdAt,
      languageCompletion,
    });

    try {
      await verifyIfSurveyIsDuplicated(
        surveyId,
        survey.name,
        survey.type,
        locale
      );
    } catch (error) {
      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY]
            .VERIFY_DUPLICATE_SURVEY,
        surveyId: surveyId ?? null,
        organizationId: organizationId ?? null,
        createdBy: fullName ?? null,
      });

      toastr.error('', error.message);

      return dispatch(
        SURVEYS_CREATE_SURVEY_FAIL({
          error,
        })
      );
    }

    const questions = [];
    const questionTag = [];
    const questionChoices = [];

    Object.values(items).forEach((item) => {
      if (item.type === SurveyElement.QUESTION) {
        processQuestionItem({
          item,
          surveyId,
          surveyType: type,
          surveyVersionId,
          organizationId,
          questions,
          questionTag,
          questionChoices,
        });
      }

      if (item.type === SurveyElement.QUESTION_GROUP) {
        processQuestionGroupItem({
          item,
          surveyId,
          surveyVersionId,
          organizationId,
          questions,
          questionTag,
          surveyType: type,
        });
      }
    });

    try {
      const batchRollback = await setSurveyVersion({
        surveyId,
        surveyVersionId,
        survey,
        surveyVersion,
        locale,
      });

      operationsRollbacks.push(batchRollback);

      if (questions.length) {
        const questionsRollback = await setQuestions({ questions, locale });

        operationsRollbacks.push(questionsRollback);
      }

      if (questionTag.length) {
        const createQuestionTagsRollback = await createQuestionTags({
          questionTags: questionTag,
          locale,
        });

        operationsRollbacks.push(createQuestionTagsRollback);
      }

      if (questionChoices.length) {
        const createQuestionChoicesRollback = await createQuestionChoices({
          questionChoices,
          locale,
        });

        operationsRollbacks.push(createQuestionChoicesRollback);
      }
    } catch (error) {
      if (operationsRollbacks.length) {
        await rollbackOperations(operationsRollbacks);
      }

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY]
            .VERSION_AND_QUESTIONS_CREATION,
        surveyId: surveyId ?? null,
        organizationId: organizationId ?? null,
        createdBy: fullName ?? null,
      });

      toastr.error('', error);

      return dispatch(
        SURVEYS_CREATE_SURVEY_FAIL({
          error,
        })
      );
    }

    try {
      await indexSurveys
        .saveObject({ objectID: surveyId, ...survey, createdAt: +new Date() })
        .wait();
    } catch (error) {
      await rollbackOperations(operationsRollbacks);

      const errorMessage = error.message;

      await saveErrorLog(error, {
        operation: ServiceOperations.CREATE_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.CREATE_SURVEY]
            .ALGOLIA_INDEX_UPDATE,
        surveyId: surveyId ?? null,
        organizationId: organizationId ?? null,
        createdBy: fullName ?? null,
      });

      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_CREATE_SURVEY_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(SURVEYS_CREATE_SURVEY_SUCCESS());
  };
};
