import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_FETCH_DEPLOYMENT_FAIL,
  SURVEYS_FETCH_DEPLOYMENT_INIT,
  SURVEYS_FETCH_DEPLOYMENT_SUCCESS,
} from 'state/actionCreators/surveys';
import { saveErrorLog } from 'services/utils';
import { ServiceOperations, ServiceSubOperations } from 'utils/enums';

export const fetchDeployment = (url) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_FETCH_DEPLOYMENT_INIT());

    const { firstName, lastName } = getState()?.auth?.userData ?? {};

    const fullName = `${firstName} ${lastName}`;

    try {
      const deployments = await firebase
        .firestore()
        .collectionGroup('deployments')
        .where('url', '==', url)
        .get();

      if (deployments.empty) {
        return dispatch(
          SURVEYS_FETCH_DEPLOYMENT_FAIL({ error: 'Invalid deployment url.' })
        );
      }

      const deploymentDoc = deployments.docs[0];

      const { startDate, endDate, lastTimeProcessed } = deploymentDoc.data();

      const deployment = {
        id: deploymentDoc.id,
        ...deploymentDoc.data(),
        startDate: startDate.toDate(),
        endDate: endDate?.toDate() || null,
        lastTimeProcessed: lastTimeProcessed?.toDate() || null,
      };

      return dispatch(SURVEYS_FETCH_DEPLOYMENT_SUCCESS({ deployment }));
    } catch (error) {
      console.log('error', error);
      const errorMessage = 'Unkown error. Please contact your adminsitrator';

      await saveErrorLog(error, {
        operation: ServiceOperations.FETCH_SURVEY_DEPLOYMENT,
        subOperation:
          ServiceSubOperations[ServiceOperations.FETCH_SURVEY_DEPLOYMENT]
            .FETCH_SURVEY_DEPLOYMENT,
        createdBy: fullName ?? null,
        deploymentId: url ?? null,
      });

      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_FETCH_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};
