import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';

const saveErrorLog = async (error, extraProps) => {
  try {
    await collection(Collections.ERROR_LOGS).add({
      errorCode: error.code,
      errorName: error.name,
      errorMessage: error.message,
      createdAt: new Date(),
      ...(!!error.toString && { error: error.toString() }),
      ...extraProps,
    });
  } catch (e) {
    console.error('Error saving error log:', e);
  }
};

export { saveErrorLog };
