import React from 'react';
import * as Sentry from '@sentry/react';

import NewModal from 'components/NewModal';

const FallbackComponent = ({ resetError }) => (
  <NewModal isOpen toggle={resetError}>
    <div className="flex flex-col items-center justify-center gap-8">
      <h1>Oops, there was an error!</h1>
      <p className="text-center text-lg text-secondary">
        If the error persists, please contact&nbsp;
        <strong>support@presagegroup.com.</strong>
      </p>
    </div>
  </NewModal>
);

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
