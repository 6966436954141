import { createReducer } from 'redux-act';

import {
  SET_ORGANIZATION_NAME,
  SET_PAGES_WITH_ACTIONS,
  SET_QUESTION_RESPONSES,
  SET_PAGE_INDEX,
  SET_RESPONSE,
  SET_IS_PREVIEW,
  CLEAR_LIVE_SURVEY_STATE,
} from 'state/actionCreators/liveSurvey';

export const initialState = {
  pagesWithActions: [],
  questionResponses: {},
  pageIndex: 0,
  organizationName: '',
  response: {},
  isPreview: false,
};

export const liveSurveyReducer = createReducer(
  {
    [SET_PAGES_WITH_ACTIONS]: (state, { newState: pagesWithActions }) => ({
      ...state,
      pagesWithActions,
    }),
    [SET_QUESTION_RESPONSES]: (state, { newState: questionResponses }) => ({
      ...state,
      questionResponses,
    }),
    [SET_PAGE_INDEX]: (state, { newState: pageIndex }) => ({
      ...state,
      pageIndex,
    }),
    [SET_ORGANIZATION_NAME]: (state, { newState: organizationName }) => ({
      ...state,
      organizationName,
    }),
    [SET_RESPONSE]: (state, { newState: response }) => ({
      ...state,
      response,
    }),
    [SET_IS_PREVIEW]: (state, { newState: isPreview }) => ({
      ...state,
      isPreview,
    }),
    [CLEAR_LIVE_SURVEY_STATE]: (state) => ({
      ...state,
      ...initialState,
    }),
  },
  initialState
);
