/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React, { createRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';

import classes from './Select.module.scss';

const NestedOption = ({
  props,
  label,
  nestedOptions,
  hoveredOption,
  setHoveredOption,
  currentIndex,
}) => {
  const { cx, getStyles, innerProps, selectOption, isDisabled, selectProps } =
    props;

  const { value, isMulti } = selectProps;

  const nestedLabelClassName = cx('nested-optgroup-label');

  const nestedOptionClassName = cx({
    option: true,
    'option--is-disabled': isDisabled,
  });

  return (
    <div className="nested-optgroup">
      <div
        style={getStyles('groupHeading', props)}
        className={nestedLabelClassName}
      >
        {label}
      </div>
      {nestedOptions.map((nestedOption, optionIndex) => {
        if (nestedOption.options) {
          return (
            <NestedOption
              props={props}
              label={nestedOption.label}
              options={nestedOption.options}
              currentIndex={currentIndex + 1}
            />
          );
        }

        const newRef = createRef();

        const isSameHoveredId =
          !!hoveredOption && hoveredOption.value?.id === nestedOption?.value.id;

        const isFocused = isSameHoveredId;

        const isSelected = value?.value?.id === nestedOption?.value?.id;

        const showOption =
          !isMulti ||
          !value.find((option) => option?.value.id === nestedOption?.value?.id);

        const nestedInnerProps = {
          ...innerProps,
          innerRef: newRef,
          onClick: (e) => {
            e.stopPropagation();

            selectOption(nestedOption);
          },
          onMouseOver: () => {
            setHoveredOption(nestedOption);
          },
          onMouseLeave: () => {
            setHoveredOption(null);
          },
        };

        return (
          showOption && (
            <div
              className="nested-optgroup-option"
              key={`${currentIndex}.${optionIndex}`}
            >
              <components.Option
                {...props}
                innerProps={nestedInnerProps}
                isFocused={isFocused}
                isSelected={isSelected}
                className={nestedOptionClassName}
              >
                {nestedOption.label}
              </components.Option>
            </div>
          )
        );
      })}
    </div>
  );
};

const CustomOption = (props) => {
  const { children, data, className } = props;
  const nestedOptions = data.options;
  const [hoveredOption, setHoveredOption] = useState(null);

  if (nestedOptions) {
    const { label } = data;

    return (
      <NestedOption
        props={props}
        label={label}
        nestedOptions={nestedOptions}
        hoveredOption={hoveredOption}
        setHoveredOption={setHoveredOption}
        currentIndex={0}
      />
    );
  }

  const { customProps } = data;

  const { className: customPropsClassname } = customProps || {};

  return (
    <components.Option
      {...props}
      className={classNames(classes.option, className)}
    >
      <span
        {...customProps}
        className={classNames(
          classes['option-inner-wrapper'],
          customPropsClassname
        )}
      >
        {children}
      </span>
    </components.Option>
  );
};

const Select = (props) => (
  <ReactSelect components={{ Option: CustomOption }} {...props} />
);

export default Select;
