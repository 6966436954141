import React from 'react';

import Section from '../Section';
import Items from '../Items';

const Sections = ({
  surveySections,
  surveyLanguage,
  disableDeselect,
  validateQuestions,
  questionResponses,
  setQuestionResponses,
  onChangeInputHandler,
  getNotAnsweredQuestionsInCurrentPage,
}) => {
  const onClearAnswerHandler = (questionId) => {
    setQuestionResponses((prevState) => {
      const question = prevState[questionId];
      const choices = {};

      Object.keys(question.choices).forEach((choiceId) => {
        choices[choiceId] = false;
      });

      return {
        ...prevState,
        [questionId]: {
          ...question,
          choices,
        },
      };
    });
  };

  const isQuestionNotAnswered = (questionId) => {
    if (validateQuestions) {
      return getNotAnsweredQuestionsInCurrentPage().includes(questionId);
    }
    return false;
  };

  const isQuestionGroupNotAnswered = (questionGroup) => {
    if (validateQuestions) {
      const notAnswered = getNotAnsweredQuestionsInCurrentPage();
      const includes = questionGroup.questions.some((question) =>
        notAnswered.includes(question.id)
      );
      return includes;
    }
    return false;
  };

  const isChoiceInRequiredQuestion = (questionId) => {
    if (validateQuestions) {
      const notAnswered = getNotAnsweredQuestionsInCurrentPage();
      return notAnswered.includes(questionId);
    }
    return false;
  };

  return surveySections.map(
    (section) =>
      section.visible && (
        <Section
          id="survey-section"
          key={section.id}
          title={section?.title[surveyLanguage.code]}
        >
          <Items
            items={section?.items}
            surveyLanguage={surveyLanguage}
            disableDeselect={disableDeselect}
            questionResponses={questionResponses}
            onClearAnswerHandler={onClearAnswerHandler}
            onChangeInputHandler={onChangeInputHandler}
            isQuestionNotAnswered={isQuestionNotAnswered}
            isQuestionGroupNotAnswered={isQuestionGroupNotAnswered}
            isChoiceInRequiredQuestion={isChoiceInRequiredQuestion}
          />
          <hr />
        </Section>
      )
  );
};

export default Sections;
