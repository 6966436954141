import { LanguageName } from 'utils/enums';

const getDefaultTranslation = ({
  surveyDefaultLanguage,
  defaultTranslationContent,
}) => ({
  code: surveyDefaultLanguage,
  name: LanguageName[surveyDefaultLanguage],
  content: defaultTranslationContent,
});

export { getDefaultTranslation };
