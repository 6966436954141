import { indexSectionsSaves } from 'utils/algolia';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const setSectionSaveService = async ({ availableLanguages, section, items, sectionIdentifier, sectionDescription }) => {
  try {
    await collection(Collections.SECTIONS_SAVES).doc(sectionIdentifier).set({
      id: sectionIdentifier,
      description: sectionDescription,
      section,
      items,
      availableLanguages,
    });
  } catch (error) {
    console.error(error);

    throw error;
  }

  try {
    await indexSectionsSaves.saveObject({
      objectID: sectionIdentifier,
      sectionIdentifier,
      section,
      description: sectionDescription,
    });
  } catch (error) {
    console.error(error);
    
    await collection(Collections.SECTIONS_SAVES).doc(sectionIdentifier).delete();
  }
};
