/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from 'react-spinners/ClipLoader';

import {
  clearSavedSectionsState,
  fetchSectionSave,
  updateSectionSave,
} from 'state/actions/SavedSections';
import {
  selectSectionSaveCreation,
  selectSectionSaveFetch,
} from 'state/selectors/sectionsSaves';
import { getTranslation } from 'utils';
import {
  onRemoveTranslation,
  onUpdateTranslation,
  onAddTranslation,
  getDefaultTranslation,
} from 'utils/surveyBuilder/translations';
import { LanguageIsoCode } from 'utils/enums';
import { surveyState } from 'utils/surveyBuilder';
import { getSurveyTranslations } from 'utils/surveyBuilder/translations/getSurveyTranslations';
import { getDefaultTranslationContent } from 'utils/surveyBuilder/translations/getDefaultTranslationContent';
import { SurveyElement, SurveyType } from 'utils/surveyBuilder/enums';
import { onDragEnd } from 'utils/surveyBuilder/dragAndDrop';
import useModal from 'hooks/useModal';
import Button from 'components/Button';
import Section from 'components/SurveyBuilder/Section';
import Modals from 'components/SurveyBuilder/Modals';
import Translations from 'components/SurveyBuilder/Translations';
import useNewModal from 'hooks/useNewModal';

import classes from './SavedSectionEdit.module.scss';

const SavedSectionEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { sectionId: sectionIdentifier } = params;

  const { sectionSave, loading, success } = useSelector(selectSectionSaveFetch);
  const { loading: updatingSection, success: successUpdatingSection } =
    useSelector(selectSectionSaveCreation);

  const [survey, setSurvey] = useState(
    surveyState({
      surveyType: SurveyType.ORGANIZATION,
      defaultLanguage: LanguageIsoCode.EN,
    })
  );

  const [sectionDescription, setSectionDescription] = useState('');
  const [questionItems, setQuestionItems] = useState([]);
  const [questionGroupItems, setQuestionGroupItems] = useState([]);
  const [textBoxItems, setTextBoxItems] = useState([]);

  const surveyPageId = survey.pagesOrder[0];
  const surveySectionId = survey.pages[surveyPageId].sectionIds[0];

  const {
    versionTitle: surveyVersionTitle,
    pagesOrder: surveyPagesOrder,
    defaultLanguage: surveyDefaultLanguage,
    items: surveyItems,
    pages: surveyPages,
    sections: surveySections,
    availableLanguages: surveyAvailableLanguages,
  } = survey;

  useEffect(() => {
    dispatch(
      fetchSectionSave({
        sectionIdentifier,
        showSuccessToast: false,
      })
    );

    return () => {
      dispatch(clearSavedSectionsState());
    };
  }, []);

  useEffect(() => {
    if (successUpdatingSection) {
      dispatch(
        fetchSectionSave({
          sectionIdentifier,
          showSuccessToast: false,
        })
      );
    }
  }, [successUpdatingSection]);

  useEffect(() => {
    if (success) {
      const {
        section: sectionToLoad,
        items: itemsToLoad,
        availableLanguages,
        description,
      } = sectionSave;

      setSectionDescription(description);

      setSurvey((prevState) => {
        const newItems = itemsToLoad.reduce((acc, item) => {
          acc[item.id] = { ...item };
          return acc;
        }, {});

        return {
          ...prevState,
          ...(availableLanguages && { availableLanguages }),
          pages: {
            [prevState.pagesOrder[0]]: {
              ...prevState.pages[prevState.pagesOrder[0]],
              sectionIds: [sectionToLoad.id],
            },
          },
          sections: {
            [sectionToLoad.id]: sectionToLoad,
          },
          items: {
            ...newItems,
          },
        };
      });
    }
  }, [success, sectionSave]);

  const surveyItemsDependency = useMemo(
    () => JSON.stringify(surveyItems),
    [surveyItems]
  );

  useEffect(() => {
    if (surveyItems) {
      const questions = {};
      const questionGroups = {};
      const textBoxes = {};

      Object.values(surveyItems).forEach((item) => {
        if (item.type === SurveyElement.QUESTION) {
          questions[item.id] = item;
        }
        if (item.type === SurveyElement.TEXT_BOX) {
          textBoxes[item.id] = item;
        }
        if (item.type === SurveyElement.QUESTION_GROUP) {
          questionGroups[item.id] = item;
        }
      });

      setQuestionItems(questions);
      setTextBoxItems(textBoxes);
      setQuestionGroupItems(questionGroups);
    }
  }, [surveyItems, surveyItemsDependency]);

  const onDragEndHandler = useCallback(
    ({ source, destination, type, draggableId }) =>
      onDragEnd({ source, destination, type, draggableId }, setSurvey),
    [setSurvey]
  );

  const onChangeSectionDescriptionHandler = ({ target: { value } }) => {
    setSectionDescription(value);
  };

  const getSectionTranslation = () => {
    const { selectedLanguage } = survey;
    const section = { ...survey.sections[surveySectionId] };

    section.title = getTranslation(section.title, selectedLanguage);

    const items = (section.itemIds ?? []).map((itemId) => {
      const item = survey.items[itemId];
      const { type: itemType } = item;

      if (itemType === SurveyElement.TEXT_BOX) {
        return {
          ...item,
          content: getTranslation(item.content, selectedLanguage),
        };
      }

      if (itemType === SurveyElement.QUESTION) {
        const { question } = item;

        return {
          ...item,
          question: {
            ...question,
            content: getTranslation(question.content, selectedLanguage),
            richText:
              getTranslation(question.richText, selectedLanguage) ||
              `<p>${getTranslation(question.content, selectedLanguage)}</p>`,
            choices: question.choices.map((choice) => ({
              ...choice,
              option: getTranslation(choice.option, selectedLanguage),
            })),
          },
        };
      }

      if (itemType === SurveyElement.QUESTION_GROUP) {
        const { questionGroup } = item;

        return {
          ...item,
          questionGroup: {
            ...questionGroup,
            questions: questionGroup.questions.map((question) => ({
              ...question,
              content: getTranslation(question.content, selectedLanguage),
              richText:
                getTranslation(question?.richText, selectedLanguage) ||
                `<p>${getTranslation(question.content, selectedLanguage)}</p>`,
            })),
            choices: questionGroup.choices?.map((choice) => {
              if (choice.questionChoices) {
                return {
                  ...choice,
                  questionChoices: choice.questionChoices.map(
                    (questionChoice) => ({
                      ...questionChoice,
                      option: getTranslation(
                        questionChoice.option,
                        selectedLanguage
                      ),
                    })
                  ),
                };
              }

              return {
                ...choice,
                option: getTranslation(choice.option, selectedLanguage),
              };
            }),
          },
        };
      }

      return { ...item };
    });

    return { ...section, items };
  };

  const surveySection = getSectionTranslation();

  const [
    complexSurveyItems,
    complexSurveyPages,
    complexSurveyPagesOrder,
    complexSurveySections,
  ] = [
    JSON.stringify(surveyItems),
    JSON.stringify(surveyPages),
    JSON.stringify(surveyPagesOrder),
    JSON.stringify(surveySections),
  ];

  const defaultTranslationContent = useMemo(
    () =>
      getDefaultTranslationContent({
        surveyDefaultLanguage,
        surveyItems,
        surveyPages,
        surveyPagesOrder,
        surveySections,
        surveyVersionTitle,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      surveyDefaultLanguage,
      complexSurveyItems,
      complexSurveyPages,
      complexSurveyPagesOrder,
      complexSurveySections,
      surveyVersionTitle,
    ]
  );

  const defaultTranslation = useMemo(
    () =>
      getDefaultTranslation({
        surveyDefaultLanguage,
        defaultTranslationContent,
      }),
    [surveyDefaultLanguage, defaultTranslationContent]
  );

  const translations = useMemo(
    () =>
      getSurveyTranslations({
        surveyAvailableLanguages,
        surveyDefaultLanguage,
        defaultTranslation,
        surveyItems,
        surveyPages,
        surveyPagesOrder,
        surveySections,
        surveyVersionTitle,
      }),
    [
      surveyAvailableLanguages,
      surveyDefaultLanguage,
      defaultTranslation,
      surveyItems,
      surveyPages,
      surveyPagesOrder,
      surveySections,
      surveyVersionTitle,
    ]
  );

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onUpdateSectionHandler = () => {
    const { id: currentSectionIdentifier, description } = sectionSave;
    const section = survey.sections[surveySectionId];

    const items = section.itemIds.map((itemId) => {
      const item = { ...survey.items[itemId], condensed: false };

      return item;
    });

    if (currentSectionIdentifier) {
      dispatch(
        updateSectionSave({
          availableLanguages: surveyAvailableLanguages,
          section,
          items,
          sectionIdentifier: currentSectionIdentifier,
          sectionDescription: sectionDescription || description,
        })
      );
    }
  };

  const { isOpen, toggle } = useNewModal();

  const onAddTranslationHandler = (languageToAdd) =>
    onAddTranslation(languageToAdd, setSurvey);

  const onUpdateTranslationHandler = ({
    languageCode,
    elementType,
    elementId,
    updatedTranslation,
    choiceId,
    questionChoiceId,
  }) =>
    onUpdateTranslation(
      {
        languageCode,
        elementType,
        elementId,
        updatedTranslation,
        choiceId,
        questionChoiceId,
      },
      setSurvey
    );

  const onRemoveTranslationHandler = (languageToDelete) =>
    onRemoveTranslation(languageToDelete, setSurvey);

  return (
    <>
      {isOpen && (
        <Translations
          defaultTranslation={defaultTranslation}
          additionalTranslations={translations}
          onClose={toggle}
          onAddTranslation={onAddTranslationHandler}
          onRemoveTranslation={onRemoveTranslationHandler}
          onUpdateTranslation={onUpdateTranslationHandler}
        />
      )}
      <Modals
        survey={survey}
        setSurvey={setSurvey}
        modal={modal}
        params={params}
        deploymentId={params.deploymentId}
        onCloseModalHandler={onCloseModalHandler}
        modalType={modal.type}
        defaultTranslation={defaultTranslation}
        translations={translations}
        questionItems={questionItems}
        questionGroupItems={questionGroupItems}
        textBoxItems={textBoxItems}
      />
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Edit Section Save</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="control">
                  {!loading && (
                    <button
                      id="data-items-translations"
                      className={classNames(
                        'button',
                        classes['translations-button']
                      )}
                      type="button"
                      onClick={toggle}
                    >
                      <span className="icon">
                        <i className="mdi mdi-earth" />
                      </span>
                      <span>Translations</span>
                    </button>
                  )}
                  {!loading && (
                    <Button
                      className={classNames('button is-success', {
                        'is-loading': updatingSection,
                      })}
                      onClick={onUpdateSectionHandler}
                    >
                      Update Section
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!loading && (
        <section
          className={classNames(
            'section columns is-mobile is-multiline',
            classes['section-info']
          )}
        >
          <div className="column is-full-mobile is-one-third-tablet">
            <div className="field">
              <label htmlFor="description" className="label">
                Description
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={sectionDescription}
                  placeholder="Description"
                  required
                  id="description"
                  onChange={onChangeSectionDescriptionHandler}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="section is-main-section">
        {loading ? (
          <Spinner />
        ) : (
          <DragDropContext onDragEnd={onDragEndHandler}>
            <Section
              isEditingSavedSection
              survey={survey}
              setSurvey={setSurvey}
              pageId={surveyPageId}
              sectionId={surveySectionId}
              sectionIndex={0}
              title={surveySection.title}
              visible={surveySection.visible}
              collapsed={false}
              randomize={surveySection.randomize}
              onAddSection={null}
              items={surveySection.items}
              onOpenModal={onOpenModalHandler}
              canDeleteSection={false}
            />
          </DragDropContext>
        )}
      </section>
    </>
  );
};

export default SavedSectionEdit;
