import { AvailableActions } from "utils/enums";

const applyAction = (
  actionToApply,
  pagesWithActions,
  setPagesWithActions,
) => {
  const { action, subject, choices, randomize } = actionToApply;

  if (action === AvailableActions.HIDE_QUESTION) {
    const pages = [...pagesWithActions];

    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            item.question?.id === subject ||
            (!item.questionGroup && item.id === subject)
          ) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = false;
          } else if (item.questionGroup) {
            item.questionGroup.questions.forEach((question, questionIndex) => {
              if (question.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.questions[questionIndex].visible = false;
              }
            });
          }
        });
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_QUESTION) {
    const pages = [...pagesWithActions];

    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            item.question?.id === subject ||
            (!item.questionGroup && item.id === subject)
          ) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = true;
          } else if (item.questionGroup) {
            item.questionGroup.questions.forEach((question, questionIndex) => {
              if (question.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.questions[questionIndex].visible = true;
              }
            });
          }
        });
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.HIDE_TEXT_BOX) {
    const pages = [...pagesWithActions];

    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (item.id === subject) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = false;
          }
        });
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_TEXT_BOX) {
    const pages = [...pagesWithActions];

    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (item.id === subject) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = true;
          }
        });
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.HIDE_SECTION) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        if (section.id === subject)
          pages[indexOfPage].sections[sectionIndex].visible = false;
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_SECTION) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        if (section.id === subject)
          pages[indexOfPage].sections[sectionIndex].visible = true;
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.HIDE_PAGE) {
    const pages = [...pagesWithActions];
    pages.forEach((page, index) => {
      if (page.id === subject) pages[index].visible = false;
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_PAGE) {
    const pages = [...pagesWithActions];
    pages.forEach((page, index) => {
      if (page.id === subject) pages[index].visible = true;
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.HIDE_CHOICES) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            item.question?.id === subject ||
            (!item.questionGroup && item.id === subject)
          ) {
            item.question.choices.forEach((choice, choiceIndex) => {
              if (choices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].question.choices[choiceIndex].visible = false;
              }
            });
          } else if (item.questionGroup && item.id === subject) {
            item.questionGroup.choices.forEach((choice, choiceIndex) => {
              if (choices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.choices[choiceIndex].visible = false;
              }
            });
          }
        });
      });
    });

    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_CHOICES) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            item.question?.id === subject ||
            (!item.questionGroup && item.id === subject)
          ) {
            item.question.choices.forEach((choice, choiceIndex) => {
              if (choices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].question.choices[choiceIndex].visible = true;
              }
            });
          } else if (item.questionGroup && item.id === subject) {
            item.questionGroup.choices.forEach((choice, choiceIndex) => {
              if (choices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.choices[choiceIndex].visible = true;
              }
            });
          }
        });
      });
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.RANDOMIZE_QUESTIONS) {
    const pages = [...pagesWithActions];
    const subjects = [...subject];
    const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
    const questions = sortedSubjects.splice(0, randomize);
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            questions.includes(item.question?.id) ||
            (!item.questionGroup && questions.includes(item.id))
          ) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = true;
          } else if (item.questionGroup) {
            item.questionGroup.questions.forEach((question, questionIndex) => {
              if (questions.includes(question.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.questions[questionIndex].visible = true;
              }
            });
          }
        });
      });
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.RANDOMIZE_SECTIONS) {
    const pages = [...pagesWithActions];
    const subjects = [...subject];
    const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
    const sections = sortedSubjects.splice(0, randomize);
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        if (sections.includes(section.id)) {
          pages[indexOfPage].sections[sectionIndex].visible = true;
        }
      });
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.RANDOMIZE_PAGES) {
    const pages = [...pagesWithActions];
    const subjects = [...subject];
    const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
    const sections = sortedSubjects.splice(0, randomize);
    pages.forEach((page, indexOfPage) => {
      if (sections.includes(page.id)) {
        pages[indexOfPage].visible = true;
      }
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.RANDOMIZE_CHOICES) {
    const pages = [...pagesWithActions];
    const allChoices = [...choices];
    const sortedChoices = allChoices.sort(() => Math.random() - 0.5);
    const showChoices = sortedChoices.splice(0, randomize);
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (
            item.question?.id === subject ||
            (!item.questionGroup && item.id === subject)
          ) {
            item.question.choices.forEach((choice, indexChoice) => {
              if (showChoices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].question.choices[indexChoice].visible = true;
              }
            });
          } else if (item.questionGroup && item.id === subject) {
            item.questionGroup.choices.forEach((choice, indexChoice) => {
              if (showChoices.includes(choice.id)) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].questionGroup.choices[indexChoice].visible = true;
              }
            });
          }
        });
      });
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.SHOW_QUESTION_GROUP) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (item.id === subject) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = true;
          }
        });
      });
    });
    setPagesWithActions(pages);
  }
  if (action === AvailableActions.HIDE_QUESTION_GROUP) {
    const pages = [...pagesWithActions];
    pages.forEach((page, indexOfPage) => {
      page.sections.forEach((section, sectionIndex) => {
        section.items.forEach((item, itemIndex) => {
          if (item.id === subject) {
            pages[indexOfPage].sections[sectionIndex].items[
              itemIndex
            ].visible = false;
          }
        });
      });
    });
    setPagesWithActions(pages);
  }
};

export default applyAction;