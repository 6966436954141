export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  ORGANIZATIONS: '/organizations',
  ADD_ORGANIZATION: '/organizations/new',
  MODIFY_ORGANIZATION: '/organizations/:siteName',
  WAVES: '/waves',
  ADD_WAVE: '/waves/new',
  MODIFY_WAVE: '/waves/:id',
  GROUPS: '/groups',
  ADD_GROUP: '/groups/new',
  MODIFY_GROUP: '/groups/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  TAGS: '/tags/:type',
  ADD_TAG: '/tags/:type/new',
  MODIFY_TAG: '/tags/:type/:id',
  TAGS_QUESTION: '/tags/question',
  TAGS_DATA_SET: '/tags/data-set',
  TAGS_CONSTRUCT: '/tags/construct',
  TAGS_DEMOGRAPHIC: '/tags/demographic',
  ADD_QUESTION_TAG: '/tags/question/new',
  ADD_DATA_SET_TAG: '/tags/data-set/new',
  ADD_CONSTRUCT_TAG: '/tags/construct/new',
  ADD_DEMOGRAPHIC_TAG: '/tags/demographic/new',
  DATA_SETS: '/data-set',
  ADD_DATA_SET: '/data-set/new',
  MODIFY_DATA_SET: '/data-set/:id',
  SAVED_SECTIONS: '/saved-sections',
  SAVED_SECTIONS_EDIT: '/saved-sections/:sectionId',
  SURVEYS: '/surveys',
  SURVEYS_BY_TYPE: '/surveys/:type',
  ADD_SURVEY: '/surveys/:type/new',
  MODIFY_SURVEY: '/surveys/:surveyId/versions/:versionId',
  PREVIEW: '/surveys/:surveyId/versions/:versionId/preview',
  MODIFY_DEPLOYMENT: '/surveys/:surveyId/deployments/:deploymentId',
  ADD_ORGANIZATION_SURVEY: '/surveys/organization/new',
  ADD_TEMPLATE_SURVEY: '/surveys/template/new',
  SURVEYS_ORGANIZATION: '/surveys/organization',
  SURVEYS_TEMPLATE: '/surveys/template',
  LIVE_SURVEY: '/live-survey',
  VIEW_LIVE_SURVEY: '/live-survey/:deploymentUrl',
  INDUSTRIES: '/industries',
  ADD_INDUSTRY: '/industries/new',
  MODIFY_INDUSTRY: '/industries/:id',
  TASKS: '/tasks',
};
