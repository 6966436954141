import { useIntl } from 'react-intl';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useChangeHandler = (setState, specialValueChanger = null) => {
  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (specialValueChanger) {
      const valueWithSpecialChanger = specialValueChanger(value);

      return setState((prevState) => ({
        ...prevState,
        [`${name}`]: valueWithSpecialChanger,
      }));
    }

    return setState((prevState) => ({ ...prevState, [`${name}`]: value }));
  };

  return onChangeHandler;
};

export const useSelectChangeHandler = (setState) => {
  const onSelectChangeHandler = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return onSelectChangeHandler;
};

export const useFormatMessage = (
  id,
  values = {},
  defaultMessage = '',
  description = ''
) => {
  const intl = useIntl();
  return intl.formatMessage({ id, defaultMessage, description }, values);
};

export const useFormatDate = (value, options = {}) => {
  const intl = useIntl();
  return intl.formatDate(value, options);
};

export const useDateFormatter = () => {
  const intl = useIntl();
  return (value, options = {}) => intl.formatDate(value, options);
};

export const useFormatTime = (value, options = {}) => {
  const intl = useIntl();
  return intl.formatDate(value, options);
};

export const useAlgoliaSearch = (initialState) => {
  const [search, setSearch] = useState({ ...initialState, refresh: false });

  const refresh = (state = {}) =>
    setSearch((prevState) => ({ ...prevState, ...state, refresh: true }));

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (search.refresh) {
      setSearch((prevState) => ({ ...prevState, refresh: false }));
    }
  }, [search.refresh]);

  return { search, refresh };
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
