const { QuestionType } = require('utils/surveyBuilder/enums');

const areNonRequiredChoiceQuestionsAnswered = (responses) =>
  responses &&
  Object.values(responses)
    .filter(
      (question) =>
        question.visible &&
        (question.type === QuestionType.SINGLE_RESPONSE ||
          question.type === QuestionType.MULTIPLE_RESPONSE ||
          question.type === QuestionType.DROPDOWN)
    )
    .every((requiredQuestion) => {
      const choicesValues = Object.values(requiredQuestion.choices);

      if (
        choicesValues.some((choiceValue) => typeof choiceValue === 'boolean')
      ) {
        return choicesValues.some((choiceValue) => choiceValue);
      }

      return choicesValues.every((choiceValue) =>
        Object.values(choiceValue).some((choice) => choice)
      );
    });

const areNonRequiredTextAreaQuestionsAnswered = (responses) =>
  responses &&
  Object.values(responses)
    .filter(
      (question) =>
        question.visible &&
        question.type === QuestionType.TEXT_AREA &&
        question.openAnswer !== ''
    )
    .every((requiredQuestion) => requiredQuestion.openAnswer !== '');

const useLastAnsweredPageGetter = ({
  props: { visiblePages },
  helpers: {
    areRequiredChoiceQuestionsAnswered,
    areRequiredTextAreaQuestionsAnswered,
  },
}) => {
  const getQuestionsPerPage = () => {
    const requiredQuestions = [];

    const nonRequiredQuestions = [];

    visiblePages.forEach((page, index) => {
      page.sections.forEach((section) => {
        if (section.visible) {
          const nonRequiredQuestionsInPage = [];
          const requiredQuestionsInPage = [];

          section.items.forEach((item) => {
            if (item.type !== QuestionType.TEXT_BOX && item.visible) {
              if (item.question?.required) {
                requiredQuestionsInPage.push(item.question.id);
              }

              if (item.question) {
                nonRequiredQuestionsInPage.push(item.question.id);
              }
            }
          });

          const requiredQuestionsInGroupInPage = [];
          const nonRequiredQuestionsInGroupInPage = [];

          section.items.forEach((item) => {
            if (
              item.type !== QuestionType.TEXT_BOX &&
              item.questionGroup &&
              item.visible
            ) {
              item.questionGroup.questions.forEach((question) => {
                if (question.required && question.visible) {
                  requiredQuestionsInGroupInPage.push(question.id);
                }

                if (question.visible) {
                  nonRequiredQuestionsInGroupInPage.push(question.id);
                }
              });
            }
          });

          nonRequiredQuestions[index] = [
            ...(nonRequiredQuestions[index] ? nonRequiredQuestions[index] : []),
            ...nonRequiredQuestionsInPage,
            ...nonRequiredQuestionsInGroupInPage,
          ];

          requiredQuestions[index] = [
            ...(requiredQuestions[index] ? requiredQuestions[index] : []),
            ...requiredQuestionsInPage,
            ...requiredQuestionsInGroupInPage,
          ];
        }
      });
    });

    return { requiredQuestions, nonRequiredQuestions };
  };

  const getLastAnsweredPageIndex = (responses) => {
    let lastAnsweredPage = 0;
    const { requiredQuestions, nonRequiredQuestions } = getQuestionsPerPage();

    for (let index = 0; index < requiredQuestions.length; index += 1) {
      const currentPageRequiredQuestions = requiredQuestions[index];

      if (currentPageRequiredQuestions?.length) {
        const currentPageRequiredResponses = {};

        currentPageRequiredQuestions.forEach((question) => {
          const questionResponse = {
            [question]: responses[question],
          };

          Object.assign(currentPageRequiredResponses, questionResponse);
        });

        const areCurrentPageRequiredQuestionsAnswered =
          areRequiredChoiceQuestionsAnswered(currentPageRequiredResponses) &&
          areRequiredTextAreaQuestionsAnswered(currentPageRequiredResponses);
        if (areCurrentPageRequiredQuestionsAnswered) {
          lastAnsweredPage = index;
        }
      } else {
        const currentPageNonRequiredQuestions = nonRequiredQuestions[index];

        if (currentPageNonRequiredQuestions?.length) {
          const currentPageNonRequiredResponses = {};

          currentPageNonRequiredQuestions.forEach((question) => {
            const questionResponse = {
              [question]: responses[question],
            };

            Object.assign(currentPageNonRequiredResponses, questionResponse);
          });

          const areCurrentPageNonRequiredQuestionsAnswered =
            areNonRequiredChoiceQuestionsAnswered(
              currentPageNonRequiredResponses
            ) &&
            areNonRequiredTextAreaQuestionsAnswered(
              currentPageNonRequiredResponses
            );
          if (areCurrentPageNonRequiredQuestionsAnswered) {
            lastAnsweredPage = index;
          } else {
            return lastAnsweredPage;
          }
        }
      }
    }

    return lastAnsweredPage;
  };

  return { getLastAnsweredPageIndex };
};

export default useLastAnsweredPageGetter;
