import { SurveyElement } from 'utils/surveyBuilder/enums';
import { selectLiveSurveyDataState } from '../surveys';

const { createSelector } = require('reselect');

const selectLiveSurveyState = (state) => state.liveSurvey;

export const selectPagesWithActions = createSelector(
  selectLiveSurveyState,
  ({ pagesWithActions }) => pagesWithActions
);

export const selectQuestionResponses = createSelector(
  selectLiveSurveyState,
  ({ questionResponses }) => questionResponses
);

export const selectOrganizationName = createSelector(
  selectLiveSurveyState,
  ({ organizationName }) => organizationName
);

export const selectPageIndex = createSelector(
  selectLiveSurveyState,
  ({ pageIndex }) => pageIndex
);

export const selectResponse = createSelector(
  selectLiveSurveyState,
  ({ response }) => response
);

export const selectIsPreview = createSelector(
  selectLiveSurveyState,
  ({ isPreview }) => isPreview
);

export const selectQuestionsDemographics = ({ isPreview }) =>
  createSelector(
    selectLiveSurveyDataState({ isPreview }),
    selectQuestionResponses,
    ({ surveyItems }, questionResponses) => {
      const demographics = [];

      if (questionResponses) {
        surveyItems.forEach(({ type, question }) => {
          if (
            type === SurveyElement.QUESTION &&
            question.demographicsId &&
            Object.values(questionResponses[question.id].choices).some(
              (choice) => choice
            )
          ) {
            const choices = [];

            question.demographicsId.forEach((demographicId) => {
              question.choices.forEach((choice) => {
                if (questionResponses[question.id].choices[choice.id]) {
                  choices.push(choice.option.en);
                }
              });

              const demographic = {
                demographicId,
                name: choices,
              };

              demographics.push(demographic);
            });
          }
        });
      }

      return demographics;
    }
  );

export const selectQuestionGroupsDemographics = ({ isPreview }) =>
  createSelector(
    selectLiveSurveyDataState({ isPreview }),
    selectQuestionResponses,
    ({ surveyItems }, questionResponses) => {
      const questionGroupDemographics = [];

      if (questionResponses) {
        surveyItems.forEach(({ type, questionGroup }) => {
          if (
            type === SurveyElement.QUESTION_GROUP &&
            questionGroup.questions.filter(
              (question) =>
                question.demographicsId &&
                Object.values(questionResponses[question.id].choices).some(
                  (choice) => choice
                )
            )
          ) {
            questionGroup.questions.forEach((question) => {
              const choices = [];

              if (question.demographicsId) {
                question.demographicsId.forEach((demographicId) => {
                  questionGroup.choices.forEach((choice) => {
                    if (questionResponses[question.id].choices[choice.id]) {
                      choices.push(choice.option.en);
                    }
                  });

                  const demographic = {
                    demographicId,
                    name: choices,
                  };

                  questionGroupDemographics.push(demographic);
                });
              }
            });
          }
        });
      }

      return questionGroupDemographics;
    }
  );
