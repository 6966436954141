import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const useDispatchState = ({
  selector,
  action,
  initialState = null,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const state = useSelector(selector);

  useEffect(() => {
    if (initialState && !state && !readOnly) {
      dispatch(action({ newState: initialState }));
    }
  }, []);

  const setState = (newState) => {
    if (!readOnly) {
      if (typeof newState === 'function') {
        dispatch(action({ newState: newState(state) }));
      } else {
        dispatch(action({ newState }));
      }
    }
  };

  return [state, setState];
};

export default useDispatchState;
