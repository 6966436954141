/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';

import firebase from 'firebase.js';
import {
  getBasicSurveyInfo,
  getChoicesFromDataSets,
  getGroupsToCreate,
} from 'utils/actions';
import getDeploymentData from 'utils/surveyBuilder/getDeploymentData';
import { firebaseError } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import {
  Collections,
  ServiceOperations,
  ServiceSubOperations,
} from 'utils/enums';
import {
  checkDeploymentExistance,
  getPreviousDemographics,
} from 'services/surveys/utils';
import { collection } from 'utils/firebase';
import { indexGroups, indexSurveys } from 'utils/algolia';
import processDeploymentQuestionItem from 'utils/actions/surveys/processDeploymentQuestionItem';
import processDeploymentQuestionGroupItem from 'utils/actions/surveys/processDeploymentQuestionGroupItem';
import { saveErrorLog } from 'services/utils';

export const createSurveyDeploymentService = async (
  { surveyData },
  { getState }
) => {
  const { locale, createdAt, firstName, lastName } = getBasicSurveyInfo({
    getState,
  });

  const deploymentId = uuid();

  const {
    name,
    surveyId,
    surveyVersionId,
    actions,
    deploymentCount,
    deployments,
    versionName,
    versionTitle,
    pages,
    pagesOrder,
    sections,
    items,
    waveIndex,
    waveName,
    waveId,
    waveStatus = null,
    startDate,
    endDate,
    url,
    organizationId,
    organizationName,
    organizationDisplaySiteName,
    availableLanguages,
    defaultLanguage,
    isCondensed,
    languageCompletion = {},
    disableDeselect = false,
  } = surveyData;

  const deploymentStartDate = firebase.firestore.Timestamp.fromDate(startDate);
  const deploymentEndDate = endDate
    ? firebase.firestore.Timestamp.fromDate(endDate)
    : null;
  /* eslint-disable no-undef */
  const itemsClone = structuredClone(items);
  const sectionsClone = structuredClone(sections);
  /* eslint-enable no-undef */

  const { deployment, newDeployment } = getDeploymentData({
    name,
    deploymentId,
    surveyVersionId,
    versionName,
    pages,
    pagesOrder,
    sections: sectionsClone,
    waveId,
    waveName,
    waveIndex,
    waveStatus,
    organizationDisplaySiteName,
    availableLanguages,
    defaultLanguage,
    isCondensed,
    languageCompletion,
    disableDeselect,
    url,
    surveyId,
    organizationId,
    organizationName,
    startDate: deploymentStartDate,
    endDate: deploymentEndDate,
    versionTitle,
    items: itemsClone,
    createdAt,
    firstName,
    lastName,
  });

  const createdBy = `${firstName} ${lastName}`;

  const previousSurvey = {
    deploymentCount,
    deployments,
  };

  const updatedSurvey = {
    deploymentCount: deploymentCount + 1,
    deployments: deployments.concat([newDeployment]),
  };

  const newDeploymentAlgolia = {
    ...newDeployment,
    startDate: newDeployment.startDate.toMillis(),
    endDate: newDeployment.endDate?.toMillis() || null,
    createdAt: newDeployment.createdAt.toMillis(),
    lastTimeProcessed: newDeployment.lastTimeProcessed?.toMillis() || null,
  };

  const updatedDeployments = [];
  deployments.forEach((deploy) => {
    updatedDeployments.push({
      ...deploy,
      startDate: deploy.startDate.toMillis(),
      endDate: deploy.endDate?.toMillis() || null,
      createdAt: deploy.createdAt.toMillis(),
      lastTimeProcessed: deploy.lastTimeProcessed?.toMillis() || null,
    });
  });

  const updatedSurveyAlgolia = {
    deploymentCount: updatedSurvey.deploymentCount,
    deployments: updatedDeployments.concat([newDeploymentAlgolia]),
  };

  let deploymentIsDuplicated = false;

  try {
    deploymentIsDuplicated = await checkDeploymentExistance(url);
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);

    throw new Error(errorMessage);
  }

  if (deploymentIsDuplicated) {
    const errorMessage = 'Deployment with the same url already exists.';

    throw new Error(errorMessage);
  }

  const questions = [];
  const questionTag = [];
  const questionChoices = [];
  const demographics = [];
  const dataSetIds = [];
  const allQuestionsTags = [];
  // eslint-disable-next-line no-undef, prefer-const
  let newActions = structuredClone(actions);
  let dataSetChoices = [];

  Object.values(itemsClone).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { question } = item;

      question.tags.forEach((tag) => {
        const tagAlreadyIncluded = allQuestionsTags.some(
          (tagInArray) => tagInArray.id === tag.id
        );
        if (!tagAlreadyIncluded) {
          allQuestionsTags.push(tag);
        }
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      const { questionGroup } = item;

      questionGroup.questions.forEach((question) => {
        question.tags.forEach((tag) => {
          const tagAlreadyIncluded = allQuestionsTags.some(
            (tagInArray) => tagInArray.id === tag.id
          );

          if (!tagAlreadyIncluded) {
            allQuestionsTags.push(tag);
          }
        });
      });
    }
  });

  const previousDemographics = await getPreviousDemographics(
    allQuestionsTags,
    waveId
  );

  Object.values(itemsClone).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { id, question } = item;

      processDeploymentQuestionItem({
        id,
        items: itemsClone,
        waveId,
        surveyId,
        deploymentId,
        organizationId,
        sections: sectionsClone,
        question,
        questions,
        newActions,
        dataSetIds,
        questionTag,
        demographics,
        questionChoices,
        previousDemographics,
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      const {
        id: questionGroupId,
        questionGroup: {
          dataSet,
          questions: questionsInGroup,
          choices: questionGroupChoices,
        },
      } = item;

      let dataSetId = dataSet.id;

      if (Array.isArray(dataSet)) {
        dataSetId = [];

        dataSet.forEach((dataSetItem) => {
          if (dataSetItem.id) {
            dataSetId.push(dataSetItem.id);
          }
        });
      }

      processDeploymentQuestionGroupItem({
        waveId,
        surveyId,
        dataSetId,
        deploymentId,
        organizationId,
        questionGroupId,
        questionsInGroup,
        items: itemsClone,
        questions,
        newActions,
        questionTag,
        demographics,
        questionGroupChoices,
        previousDemographics,
      });
    }
  });

  if (dataSetIds.length > 0) {
    dataSetChoices = await getChoicesFromDataSets(dataSetIds);
  }

  deployment.actions = newActions;

  const surveyRef = collection(Collections.SURVEYS).doc(surveyId);
  const deploymentRef = surveyRef
    .collection(Collections.DEPLOYMENTS)
    .doc(deploymentId);

  try {
    const batch = firebase.firestore().batch();

    batch.update(surveyRef, updatedSurvey);
    batch.set(deploymentRef, deployment);

    await batch.commit();
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_DEPLOYMENT,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_DEPLOYMENT]
          .VERIFY_DUPLICATE_SURVEY,
      createdBy: createdBy ?? null,
      surveyId: surveyId ?? null,
    });

    throw new Error(errorMessage);
  }

  try {
    const batch = firebase.firestore().batch();

    demographics.forEach(({ id, demographic }) =>
      batch.set(collection(Collections.DEMOGRAPHICS).doc(id), demographic)
    );

    await batch.commit();
  } catch (error) {
    const batch = firebase.firestore().batch();

    batch.update(surveyRef, previousSurvey);
    batch.delete(deploymentRef);

    demographics.forEach(({ id }) =>
      batch.delete(collection(Collections.DEMOGRAPHICS).doc(id))
    );

    await batch.commit();

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_DEPLOYMENT,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_DEPLOYMENT]
          .DEMOGRAPHICS_CREATION,
      createdBy: createdBy ?? null,
      surveyId: surveyId ?? null,
    });

    const errorMessage = firebaseError(error.code, locale);

    throw errorMessage;
  }

  try {
    const createQuestionTagTasks = [];
    const createQuestionChoiceTasks = [];

    questionTag.forEach((data) =>
      createQuestionTagTasks.push(
        collection(Collections.QUESTION_TAG).add(data)
      )
    );

    questionChoices.forEach(({ id, choice }) =>
      createQuestionChoiceTasks.push(
        collection(Collections.QUESTION_CHOICE).doc(id).set(choice)
      )
    );

    await Promise.all([
      ...createQuestionChoiceTasks,
      ...createQuestionTagTasks,
    ]);
  } catch (error) {
    const batch = firebase.firestore().batch();
    batch.update(surveyRef, previousSurvey);
    batch.delete(deploymentRef);

    questions.forEach(({ id }) =>
      batch.delete(collection(Collections.QUESTIONS).doc(id))
    );
    demographics.forEach(({ id }) =>
      batch.delete(collection(Collections.DEMOGRAPHICS).doc(id))
    );

    await batch.commit();

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_DEPLOYMENT,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_DEPLOYMENT]
          .QUESTION_CHOICES_CREATION,
      createdBy: createdBy ?? null,
      surveyId: surveyId ?? null,
    });

    const errorMessage = firebaseError(error.code, locale);

    throw errorMessage;
  }

  try {
    const regionalBreakdownQuestions = questions.filter(
      ({ question }) => question.regionalBreakdown
    );

    const regionalBreakdownChoices = [];

    if (questionChoices.length > 0) {
      const choicesFromRegionalBreakdownQuestions = questionChoices.filter(
        (questionChoice) =>
          regionalBreakdownQuestions.some(
            ({ id }) => id === questionChoice.choice.questionId
          )
      );

      regionalBreakdownChoices.push(...choicesFromRegionalBreakdownQuestions);
    }

    if (dataSetChoices.length > 0) {
      const dataSetChoicesFromRegionalBreakdownQuestions =
        dataSetChoices.filter((dataSetChoice) =>
          regionalBreakdownQuestions.some(
            ({ question }) =>
              question.dataSetId === dataSetChoice.choice.dataSetId
          )
        );

      regionalBreakdownChoices.push(
        ...dataSetChoicesFromRegionalBreakdownQuestions
      );
    }

    const groups = await getGroupsToCreate(
      regionalBreakdownChoices,
      waveId,
      { firstName, lastName },
      organizationName
    );

    const batch = firebase.firestore().batch();

    const groupsToCreateOrUpdateInAlgolia = [];

    groups.forEach(({ id, group }) => {
      batch.set(collection(Collections.GROUPS).doc(id), {
        ...group,
        createdAt:
          group.createdAt || firebase.firestore.FieldValue.serverTimestamp(),
      });

      groupsToCreateOrUpdateInAlgolia.push({
        objectID: id,
        ...group,
        createdAt: group.createdAt
          ? +new Date(group.createdAt.toDate())
          : +new Date(),
      });
    });

    await Promise.all([
      indexGroups.partialUpdateObjects(groupsToCreateOrUpdateInAlgolia, {
        createIfNotExists: true,
      }),
      batch.commit(),
    ]);
  } catch (error) {
    const batch = firebase.firestore().batch();

    batch.update(surveyRef, previousSurvey);
    batch.delete(deployment);

    questions.forEach(({ id }) =>
      batch.delete(collection(Collections.QUESTIONS).doc(id))
    );
    demographics.forEach(({ id }) =>
      batch.delete(collection(Collections.DEMOGRAPHICS).doc(id))
    );

    await batch.commit();

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_DEPLOYMENT,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_DEPLOYMENT]
          .ALGOLIA_INDEX_UPDATE_GROUPS,
      createdBy: createdBy ?? null,
      surveyId: surveyId ?? null,
    });

    const errorMessage = error.message;

    throw errorMessage;
  }

  try {
    await indexSurveys
      .partialUpdateObject({ objectID: surveyId, ...updatedSurveyAlgolia })
      .wait();
  } catch (error) {
    const batch = firebase.firestore().batch();

    batch.update(surveyRef, previousSurvey);
    batch.delete(deployment);

    questions.forEach(({ id }) =>
      batch.delete(collection(Collections.QUESTIONS).doc(id))
    );
    demographics.forEach(({ id }) =>
      batch.delete(collection(Collections.DEMOGRAPHICS).doc(id))
    );

    await batch.commit();

    await saveErrorLog(error, {
      operation: ServiceOperations.CREATE_SURVEY_DEPLOYMENT,
      subOperation:
        ServiceSubOperations[ServiceOperations.CREATE_SURVEY_DEPLOYMENT]
          .ALGOLIA_INDEX_UPDATE,
      createdBy: createdBy ?? null,
      surveyId: surveyId ?? null,
    });

    const errorMessage = error.message;

    throw errorMessage;
  }
};
