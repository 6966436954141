/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import { AvailableActions, ActionsSelectValues } from 'utils/enums';
import Select from 'components/Select';

import classes from './Action.module.scss';

const operatorsOptions = Object.keys(AvailableActions).map((action) => {
  return {
    label: AvailableActions[action],
    value: AvailableActions[action],
  };
});

const Action = ({
  action: { action, subject, choices = null, randomize = null },
  index,
  onDeleteAction,
  onChangeAction,
  canDelete,
  questionOptions,
  questionGroupOptions,
  allQuestionsOptions,
  questionsAndGroupsOptions,
  textBoxOptions,
  pageOptions,
  sectionOptions,
}) => {
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [choicesOptions, setChoicesOptions] = useState([]);
  const [randomizeOptions, setRandomizeOptions] = useState([]);

  const isQuestionAction = useMemo(
    () =>
      action?.label === AvailableActions.SHOW_QUESTION ||
      action?.label === AvailableActions.HIDE_QUESTION,
    [action]
  );

  const isQuestionGroupAction = useMemo(
    () =>
      action?.label === AvailableActions.SHOW_QUESTION_GROUP ||
      action?.label === AvailableActions.HIDE_QUESTION_GROUP,
    [action]
  );

  const isTextBoxAction = useMemo(
    () =>
      action?.label === AvailableActions.SHOW_TEXT_BOX ||
      action?.label === AvailableActions.HIDE_TEXT_BOX,
    [action]
  );

  const isSectionAction = useMemo(
    () =>
      action?.label === AvailableActions.SHOW_SECTION ||
      action?.label === AvailableActions.HIDE_SECTION,
    [action]
  );

  const isPageAction = useMemo(
    () =>
      action?.label === AvailableActions.SHOW_PAGE ||
      action?.label === AvailableActions.HIDE_PAGE,
    [action]
  );

  const isChoicesAction = useMemo(
    () =>
      action?.label === AvailableActions.HIDE_CHOICES ||
      action?.label === AvailableActions.SHOW_CHOICES,
    [action]
  );

  const isRandomizeQuestionAction = useMemo(
    () => action?.label === AvailableActions.RANDOMIZE_QUESTIONS,
    [action]
  );

  const isRandomizeChoicesAction = useMemo(
    () => action?.label === AvailableActions.RANDOMIZE_CHOICES,
    [action]
  );

  const isRandomizeSectionsAction = useMemo(
    () => action?.label === AvailableActions.RANDOMIZE_SECTIONS,
    [action]
  );

  const isRandomizePagesAction = useMemo(
    () => action?.label === AvailableActions.RANDOMIZE_PAGES,
    [action]
  );

  const isRandomizeAction = useMemo(
    () =>
      isRandomizeQuestionAction ||
      isRandomizeChoicesAction ||
      isRandomizeSectionsAction ||
      isRandomizePagesAction,
    [
      isRandomizeQuestionAction,
      isRandomizeChoicesAction,
      isRandomizeSectionsAction,
      isRandomizePagesAction,
    ]
  );

  useEffect(() => {
    if (action) {
      if (isQuestionAction || isRandomizeQuestionAction) {
        setSubjectOptions(allQuestionsOptions);
      } else if (isChoicesAction || isRandomizeChoicesAction) {
        setSubjectOptions(questionsAndGroupsOptions);
      } else if (isQuestionGroupAction) {
        setSubjectOptions(questionGroupOptions);
      } else if (isTextBoxAction) {
        setSubjectOptions(textBoxOptions);
      } else if (isSectionAction || isRandomizeSectionsAction) {
        setSubjectOptions(sectionOptions);
      } else if (isPageAction || isRandomizePagesAction) {
        setSubjectOptions(pageOptions);
      }
    }
  }, [
    action,
    isQuestionAction,
    isQuestionGroupAction,
    isTextBoxAction,
    isSectionAction,
    isPageAction,
    isChoicesAction,
    isRandomizeQuestionAction,
    isRandomizeChoicesAction,
    isRandomizeSectionsAction,
    isRandomizePagesAction,
    questionOptions,
    allQuestionsOptions,
    questionGroupOptions,
    questionsAndGroupsOptions,
    textBoxOptions,
    sectionOptions,
    pageOptions,
  ]);

  useEffect(() => {
    if (subject && (isChoicesAction || isRandomizeChoicesAction)) {
      const valuesOptions = subject.value.choices;
      const options = valuesOptions.flatMap((option, i) => {
        if (option.questionChoices) {
          return option.questionChoices.map((questionChoice) => ({
            label: questionChoice.option.en,
            value: {
              id: questionChoice.id,
              dataSetId: questionChoice.dataSetId,
              title: questionChoice.option.en,
              toString: () => i,
            },
          }));
        }

        return {
          label: option.option.en,
          value: { id: option.id, title: option.option.en, toString: () => i },
        };
      });

      setChoicesOptions(options);
    }
  }, [subject, isChoicesAction, isRandomizeChoicesAction]);

  useEffect(() => {
    const randomizePossibleNumbers = [];
    if (subject && isRandomizeAction) {
      if (isRandomizeChoicesAction && choices) {
        for (let position = 0; position < choices.length; position += 1) {
          randomizePossibleNumbers.push({
            label: `${position + 1}`,
            value: `${position + 1}`,
          });
        }
      } else if (!isRandomizeChoicesAction) {
        for (let position = 0; position < subject.length; position += 1) {
          randomizePossibleNumbers.push({
            label: `${position + 1}`,
            value: `${position + 1}`,
          });
        }
      }
    }
    setRandomizeOptions(randomizePossibleNumbers);
  }, [subject, choices, isRandomizeAction, isRandomizeChoicesAction]);

  const onChangeActionHandler = useCallback(
    (newAction) => {
      onChangeAction(index, ActionsSelectValues.ACTION, newAction);
    },
    [index, onChangeAction]
  );

  const onChangeSubjectHandler = useCallback(
    (newSubject) => {
      onChangeAction(index, ActionsSelectValues.SUBJECT, newSubject);
    },
    [index, onChangeAction]
  );

  const onChangeChoicesHandler = useCallback(
    (newChoice) => {
      onChangeAction(index, ActionsSelectValues.CHOICES, newChoice);
    },
    [index, onChangeAction]
  );

  const onChangeRandomize = useCallback(
    (newRandomize) => {
      onChangeAction(index, ActionsSelectValues.RANDOMIZE, newRandomize);
    },
    [index, onChangeAction]
  );

  const getValueOfAction = useCallback(() => {
    let selectedOption = null;
    Object.values(operatorsOptions).forEach((operator) => {
      if (operator.label === action?.label) {
        selectedOption = operator;
      }
    });
    return selectedOption;
  }, [action]);

  const getValueOfSubject = useCallback(() => {
    let selectedOption = null;

    subjectOptions.forEach((subjectOption) => {
      if (subjectOption.options) {
        subjectOption.options.forEach((itemsOption) => {
          if (itemsOption.options) {
            itemsOption.options.forEach((option) => {
              const isSelected = option.value?.id === subject?.value.id;

              if (isSelected) {
                selectedOption = option;
              }
            });
          } else if (itemsOption.value.id === subject?.value.id) {
            selectedOption = itemsOption;
          }
        });
      } else if (subjectOption.value.id === subject?.value.id) {
        selectedOption = subjectOption;
      }
    });
    return selectedOption;
  }, [subject, subjectOptions]);

  const getValueOfChoices = useCallback(() => {
    const selectedOption = [];

    if (choices && choices.length > 0) {
      Object.values(choicesOptions).forEach((choiceOption) => {
        choices.forEach((choice) => {
          if (choiceOption.value.id === choice?.value.id) {
            selectedOption.push(choiceOption);
          }
        });
      });
    }

    return selectedOption;
  }, [choicesOptions, choices]);

  const getValueOfRandomize = useCallback(() => {
    const selectedOption = [];
    if (subject && subject.length > 0) {
      subjectOptions.forEach((subjectOption) => {
        if (subjectOption.options) {
          subjectOption.options.forEach((itemsOption) => {
            itemsOption.options.forEach((option) => {
              subject.forEach((question) => {
                const isSelected = option.value?.id === question?.value?.id;

                if (isSelected) {
                  selectedOption.push(option);
                }
              });
            });
          });
        } else {
          subject.forEach((item) => {
            if (subjectOption.value.id === item?.value.id) {
              selectedOption.push(subjectOption);
            }
          });
        }
      });
    }
    return selectedOption;
  }, [subject, subjectOptions]);

  return (
    <div className={classNames('columns', classes.columns)}>
      <div className={classNames('column', classes.column)}>
        <div className="field">
          <div className="field-body">
            <label className={classNames('label', classes.label)}>
              {index + 1}.
            </label>
            <div className={classNames('control', classes.field)}>
              <ReactSelect
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Select action"
                value={getValueOfAction()}
                options={operatorsOptions}
                onChange={onChangeActionHandler}
                maxMenuHeight={150}
              />
            </div>
          </div>
        </div>
        {action && (!isRandomizeAction || isRandomizeChoicesAction) && (
          <div className="field">
            <div className="field-body">
              <label
                className={classNames('label', classes['label-choices'])}
              />
              <div className={classNames('control', classes.field)}>
                <Select
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder="Select subject"
                  value={getValueOfSubject()}
                  options={subjectOptions}
                  onChange={onChangeSubjectHandler}
                  maxMenuHeight={150}
                />
              </div>
            </div>
          </div>
        )}
        {isRandomizeAction && !isRandomizeChoicesAction && (
          <div className="field">
            <div className="field-body">
              <label
                className={classNames('label', classes['label-choices'])}
              />
              <div className={classNames('control', classes.field)}>
                <Select
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isMulti
                  placeholder="Select subjects"
                  value={getValueOfRandomize()}
                  options={subjectOptions}
                  onChange={onChangeSubjectHandler}
                  maxMenuHeight={150}
                />
              </div>
            </div>
          </div>
        )}
        {(isChoicesAction || isRandomizeChoicesAction) && subject && (
          <div className="field">
            <div className="field-body">
              <label
                className={classNames('label', classes['label-choices'])}
              />
              <div className={classNames('control', classes.field)}>
                <ReactSelect
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isMulti
                  placeholder="Select choices"
                  value={getValueOfChoices()}
                  options={choicesOptions}
                  onChange={onChangeChoicesHandler}
                  maxMenuHeight={150}
                />
              </div>
            </div>
          </div>
        )}
        {isRandomizeAction &&
          ((!isRandomizeChoicesAction && subject) ||
            (isRandomizeChoicesAction && choices)) && (
            <div className="field">
              <div className="field-body">
                <label
                  className={classNames('label', classes['label-choices'])}
                />
                <div className={classNames('control', classes.field)}>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    placeholder="Select amount"
                    value={randomize}
                    options={randomizeOptions}
                    onChange={onChangeRandomize}
                    maxMenuHeight={150}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
      <div className="column is-1">
        <div className={classes['delete-field']}>
          <button
            id="delete-action"
            type="button"
            className={classNames(
              'button',
              'is-small',
              'is-danger',
              classes['delete-button']
            )}
            onClick={() => onDeleteAction(index)}
            disabled={!canDelete}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

Action.propTypes = {
  action: PropTypes.shape({
    action: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    subject: PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          id: PropTypes.string,
          choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        }),
      }),
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    ]),
    choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    randomize: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  index: PropTypes.number.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  questionOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  questionGroupOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  textBoxOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  pageOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  sectionOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default Action;
