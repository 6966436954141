import { doc, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';
import { toastr } from 'react-redux-toastr';
import { saveErrorLog } from 'services/utils';

import {
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import {
  CollectionGroup,
  Collections,
  ServiceOperations,
  ServiceSubOperations,
} from 'utils/enums';

export const updateDeploymentStartAndEnd =
  ({ surveyId, deploymentId, startDate, endDate }) =>
  async (dispatch, getState) => {
    dispatch(SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT());

    const db = getFirestore();

    const { locale } = getState().preferences;

    const deploymentRef = doc(
      db,
      Collections.SURVEYS,
      surveyId,
      CollectionGroup.SURVEY_DEPLOYMENT,
      deploymentId
    );

    try {
      await updateDoc(deploymentRef, {
        startDate: Timestamp.fromDate(startDate),
        endDate: endDate ? Timestamp.fromDate(endDate) : null,
      });
    } catch (error) {
      await saveErrorLog(error, {
        operation: ServiceOperations.UPDATE_DEPLOYMENT_START_AND_END_DATES,
        subOperation:
          ServiceSubOperations[
            ServiceOperations.UPDATE_DEPLOYMENT_START_AND_END_DATES
          ].UPDATE_DEPLOYMENT_START_AND_END_DATES,
        surveyId: surveyId ?? null,
        deploymentId: deploymentId ?? null,
      });

      console.error(error);
      const errorMessage = firebaseError(error.code, locale);

      toastr.error('', errorMessage);

      dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Survey Dates Updated');
    dispatch(
      SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS({
        deploymentId,
        startDate,
        endDate,
      })
    );
  };
