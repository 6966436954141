import { toastr } from 'react-redux-toastr';

import {
  setSectionSaveService
} from 'services/sectionSave';
import {
  SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL,
  SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT,
  SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS,
} from 'state/actionCreators/savedSections';

export const updateSectionSave = ({ availableLanguages, section, items, sectionIdentifier, sectionDescription }) => {
  return async (dispatch) => {
    dispatch(SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT());

    try {
      await setSectionSaveService({ availableLanguages, section, items, sectionIdentifier, sectionDescription });
    } catch (error) {
      toastr.error('Error Updating the Section', error);
      return dispatch(SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL({ error }));
    }

    toastr.success('', 'Section Save Successfully Updated.');

    return dispatch(SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS());
  };
};
