import { SurveyElement } from 'utils/surveyBuilder/enums';

const onDragEnd = (
  { source, destination, type, draggableId },
  setSurvey
) => {
  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  if (type === SurveyElement.PAGE) {
    setSurvey((prevState) => {
      const { pagesOrder } = prevState;

      const [removedPage] = pagesOrder.splice(source.index, 1);
      pagesOrder.splice(destination.index, 0, removedPage);

      return { ...prevState, pagesOrder };
    });
  }

  if (type === SurveyElement.SECTION) {
    setSurvey((prevState) => {
      const { pages } = prevState;

      const sourcePage = pages[source.droppableId];
      const destinationPage = pages[destination.droppableId];

      if (sourcePage === destinationPage) {
        const newSectionIds = Array.from(sourcePage.sectionIds);

        newSectionIds.splice(source.index, 1);
        newSectionIds.splice(destination.index, 0, draggableId);

        const newPage = {
          ...sourcePage,
          sectionIds: newSectionIds,
        };

        return { ...prevState, pages: { ...pages, [newPage.id]: newPage } };
      }

      const startSectionIds = Array.from(sourcePage.sectionIds);
      startSectionIds.splice(source.index, 1);

      const newStartPage = {
        ...sourcePage,
        sectionIds: startSectionIds,
      };

      const finishSectionIds = Array.from(destinationPage.sectionIds);
      finishSectionIds.splice(destination.index, 0, draggableId);

      const newFinishPage = {
        ...destinationPage,
        sectionIds: finishSectionIds,
      };

      return {
        ...prevState,
        pages: {
          ...pages,
          [newStartPage.id]: newStartPage,
          [newFinishPage.id]: newFinishPage,
        },
      };
    });
  }

  if (type === SurveyElement.ITEM) {
    setSurvey((prevState) => {
      const { sections } = prevState;

      const sourceSection = sections[source.droppableId];
      const destinationSection = sections[destination.droppableId];

      if (sourceSection === destinationSection) {
        const newItemIds = Array.from(sourceSection.itemIds);

        newItemIds.splice(source.index, 1);
        newItemIds.splice(destination.index, 0, draggableId);

        const newSection = {
          ...sourceSection,
          itemIds: newItemIds,
        };

        return {
          ...prevState,
          sections: { ...sections, [newSection.id]: newSection },
        };
      }

      const startItemIds = Array.from(sourceSection.itemIds);
      startItemIds.splice(source.index, 1);

      const newStartSection = {
        ...sourceSection,
        itemIds: startItemIds,
      };

      const finishItemIds = Array.from(destinationSection.itemIds);
      finishItemIds.splice(destination.index, 0, draggableId);

      const newFinishSection = {
        ...destinationSection,
        itemIds: finishItemIds,
      };

      return {
        ...prevState,
        sections: {
          ...sections,
          [newStartSection.id]: newStartSection,
          [newFinishSection.id]: newFinishSection,
        },
      };
    });
  }
};

export { onDragEnd };
