import { calculateTranslationCompletion, isString } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import { LanguageName } from 'utils/enums';


const getSurveyTranslations = ({
  surveyAvailableLanguages,
  surveyDefaultLanguage,
  defaultTranslation,
  surveyItems,
  surveyPages,
  surveyPagesOrder,
  surveySections,
  surveyVersionTitle,
}) => {
  return surveyAvailableLanguages
    .filter((languageCode) => languageCode !== surveyDefaultLanguage)
    .map((languageCode, index) => {
      const translation = [
        {
          type: SurveyElement.SURVEY_TITLE,
          value: surveyVersionTitle[languageCode],
        },
      ];
      const dataSetsMissingTranslations = [];

      surveyPagesOrder.forEach((pageId) => {
        const { title: pageTitle, sectionIds } = {
          ...surveyPages[pageId],
        };

        translation.push({
          type: SurveyElement.PAGE,
          id: pageId,
          value: pageTitle[languageCode],
        });

        sectionIds.forEach((sectionId) => {
          const { title: sectionTitle, itemIds } = {
            ...surveySections[sectionId],
          };

          translation.push({
            type: SurveyElement.SECTION,
            id: sectionId,
            value: sectionTitle[languageCode],
          });

          itemIds.forEach((itemId) => {
            const {
              type: itemType,
              content: itemContent,
              richText: itemRichText,
              question,
              questionGroup,
            } = {
              ...surveyItems[itemId],
            };

            if (itemType === SurveyElement.TEXT_BOX) {
              translation.push({
                type: SurveyElement.TEXT_BOX,
                id: itemId,
                value:
                  itemRichText?.[languageCode] || itemContent[languageCode],
              });
            }

            if (itemType === SurveyElement.QUESTION) {
              const {
                choices: questionChoices,
                richText: questionRichText,
                dataSet,
              } = question;

              translation.push({
                type: SurveyElement.QUESTION,
                id: itemId,
                value: questionRichText?.[languageCode] || '',
              });

              const choicesAreFromDataSet = !!dataSet.id;

              const dataSetMissingTranslations =
                choicesAreFromDataSet &&
                questionChoices.some(
                  (questionChoice) => !questionChoice.option[languageCode]
                );

              if (dataSetMissingTranslations) {
                const isAlreadyContained = dataSetsMissingTranslations.some(
                  ({ id: dataSetId }) => dataSetId === dataSet.id
                );

                if (!isAlreadyContained) {
                  dataSetsMissingTranslations.push(dataSet);
                }
              }

              questionChoices.forEach(({ option }, choiceId) => {
                translation.push({
                  type: SurveyElement.QUESTION_CHOICE,
                  id: itemId,
                  choiceId,
                  value: dataSetMissingTranslations ? '' : option[languageCode],
                  missingTranslation: dataSetMissingTranslations,
                  isFromDataSet: choicesAreFromDataSet,
                });
              });
            }

            if (itemType === SurveyElement.QUESTION_GROUP) {
              const { questions, choices, dataSet } = questionGroup;

              questions.forEach(({ content }, questionIndex) => {
                translation.push({
                  type: SurveyElement.QUESTION_FROM_QUESTION_GROUP,
                  id: {
                    questionIndex,
                    itemId,
                  },
                  value: content[languageCode],
                });
              });

              const choicesAreFromDataSet = !!dataSet.id;

              const dataSetMissingTranslations =
                choicesAreFromDataSet &&
                choices.some(
                  (questionChoice) => !questionChoice.option[languageCode]
                );

              if (dataSetMissingTranslations) {
                const isAlreadyContained = dataSetsMissingTranslations.some(
                  ({ id: dataSetId }) => dataSetId === dataSet.id
                );

                if (!isAlreadyContained) {
                  dataSetsMissingTranslations.push(dataSet);
                }
              }

              choices.forEach(
                ({ questionChoices, dataSetId, option }, choiceId) => {
                  if (dataSetId) {
                    let datasetTitle = null;

                    if (Array.isArray(dataSet)) {
                      datasetTitle = dataSet.find(
                        ({ id }) => id === dataSetId
                      ).title;
                    }

                    if (datasetTitle) {
                      if (isString(datasetTitle)) {
                        translation.push({
                          type: SurveyElement.QUESTION_CHOICE_DATASET_TITLE,
                          id: { itemId, choiceId, dataSetId },
                          choiceId,
                          value: '',
                        });
                      } else {
                        translation.push({
                          type: SurveyElement.QUESTION_CHOICE_DATASET_TITLE,
                          id: { itemId, choiceId, dataSetId },
                          choiceId,
                          value: datasetTitle[languageCode] || '',
                        });
                      }
                    }
                  }

                  if (questionChoices) {
                    return questionChoices.forEach(
                      ({ option: questionChoiceOption }, questionChoiceId) => {
                        translation.push({
                          type: SurveyElement.QUESTION_CHOICE,
                          id: itemId,
                          choiceId,
                          questionChoiceId,
                          value:
                            dataSetMissingTranslations ||
                            !questionChoiceOption[languageCode]
                              ? ''
                              : questionChoiceOption[languageCode],
                          missingTranslation: dataSetMissingTranslations,
                          isFromDataSet: choicesAreFromDataSet,
                        });
                      }
                    );
                  }

                  return translation.push({
                    type: SurveyElement.QUESTION_CHOICE,
                    id: itemId,
                    choiceId,
                    value: dataSetMissingTranslations
                      ? ''
                      : option[languageCode],
                    missingTranslation: dataSetMissingTranslations,
                    isFromDataSet: choicesAreFromDataSet,
                  });
                }
              );
            }
          });
        });
      });

      const percentageCompleted = calculateTranslationCompletion(
        defaultTranslation.content,
        translation.map(({ value }) => value)
      );

      return {
        code: languageCode,
        name: LanguageName[languageCode],
        percentageCompleted,
        content: translation,
        index,
        dataSetsMissingTranslations,
      };
    });
};

export { getSurveyTranslations };
