import { SurveyElement } from 'utils/surveyBuilder/enums';

const getValidQuestions = (fetchedSurvey) => {
  const validItems = {};

  const { pagesOrder } = fetchedSurvey;

  if (pagesOrder?.length) {
    pagesOrder.forEach((pageId) => {
      const page = fetchedSurvey.pages[pageId];

      if (page) {
        page.sectionIds.forEach((sectionId) => {
          const section = fetchedSurvey.sections[sectionId];

          if (section) {
            section.itemIds.forEach((itemId) => {
              const item = fetchedSurvey.items[itemId];

              if (item) {
                if (item.type === SurveyElement.QUESTION) {
                  const { id } = item;

                  validItems[id] = true;
                }

                if (item.type === SurveyElement.QUESTION_GROUP) {
                  const {
                    questionGroup: { questions: q },
                  } = item;

                  q.forEach((question) => {
                    validItems[question.id] = true;
                  });
                }
              }
            });
          }
        });
      }
    });
  }

  return validItems;
};

export { getValidQuestions };
