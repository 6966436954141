/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import {
  createSurvey,
  updateDeployment,
  updateSurvey,
} from 'state/actions/surveys';
import { selectSurveyBuilderState } from 'state/selectors/surveys';
import { getTranslation, isString } from 'utils';
import { SurveyElement, SurveyType } from 'utils/surveyBuilder/enums';
import { onDragEnd } from 'utils/surveyBuilder/dragAndDrop';
import { LanguageName, ModalType } from 'utils/enums';
import { getPage, listSurveysPath } from 'utils/surveyBuilder';
import useNewModal from 'hooks/useNewModal';
import Toggle from 'components/Toggle';
import Dropdown from 'components/Navigation/Dropdown';
import Pages from 'components/SurveyBuilder/Pages';

import BacktrackingModal from '../Modals/BacktrackingModal';
import './Form.scss';

const Form = ({ survey, setSurvey, isUpdating, onOpenModalHandler }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { toggle: backtrackingModalToggle, isOpen: backtrackingModalIsOpen } =
    useNewModal();

  const isOrganization = survey.type === SurveyType.ORGANIZATION;
  const isTemplate = survey.type === SurveyType.TEMPLATE;

  const { organizations, loading, fetchingOrgs, updatingDeployment } =
    useSelector(selectSurveyBuilderState, shallowEqual);

  const onDragEndHandler = useCallback(
    ({ source, destination, type, draggableId }) =>
      onDragEnd({ source, destination, type, draggableId }, setSurvey),
    [setSurvey]
  );

  const onAddPageHandler = useCallback(
    (pageIndex) =>
      setSurvey((prevState) => {
        const { pages, pagesOrder, availableLanguages } = prevState;

        const page = getPage({
          availableLanguages,
        });

        pagesOrder.splice(pageIndex, 0, page.id);
        pages[page.id] = page;

        return {
          ...prevState,
          pagesOrder,
          pages,
        };
      }),
    [setSurvey]
  );

  const onChangeSurveyNameHandler = useCallback(
    (name) =>
      setSurvey((prevState) => ({
        ...prevState,
        name,
      })),
    [setSurvey]
  );

  const onChangeSurveyTitleHandler = useCallback(
    (title) =>
      setSurvey((prevState) => ({
        ...prevState,
        versionTitle: {
          ...prevState.versionTitle,
          [prevState.selectedLanguage]: title,
        },
      })),
    [setSurvey]
  );

  const onChangeVersionNameHandler = useCallback(
    (versionName) =>
      setSurvey((prevState) => ({
        ...prevState,
        versionName,
      })),
    [setSurvey]
  );

  const onDeselectToggleHandler = useCallback(
    (checked) => {
      setSurvey((prevState) => ({
        ...prevState,
        disableDeselect: checked,
      }));
    },
    [setSurvey]
  );

  const onOrganizationChangeHandler = useCallback(
    (organization) => {
      if (organization) {
        setSurvey((prevState) => ({
          ...prevState,
          organization,
          ...organization.value,
        }));
      } else {
        setSurvey((prevState) => ({
          ...prevState,
          organization: null,
          organizationDisplaySiteName: null,
          organizationId: null,
          organizationName: null,
        }));
      }
    },
    [setSurvey]
  );

  const onCondenseAllQuestionsHandler = useCallback(
    () =>
      setSurvey((prevState) => {
        const { items, isCondensed } = prevState;

        const condensed = !isCondensed;
        const newItems = {};

        Object.keys(items).forEach((itemId) => {
          newItems[itemId] = {
            ...items[itemId],
            condensed,
          };
        });

        return {
          ...prevState,
          items: newItems,
          isCondensed: condensed,
        };
      }),
    [setSurvey]
  );

  const onChangeQuestionContent = useCallback(
    (itemId, value) => {
      setSurvey((prevState) => ({
        ...prevState,
        items: {
          ...prevState.items,
          [itemId]: {
            ...prevState.items[itemId],
            question: {
              ...prevState.items[itemId].question,
              content: {
                ...prevState.items[itemId].question.content,
                [survey.defaultLanguage]: value,
              },
            },
          },
        },
      }));
    },
    [survey.defaultLanguage, setSurvey]
  );

  const onChangeLanguageHandler = (select) => {
    setSurvey((prevState) => ({
      ...prevState,
      selectedLanguage: select.value,
      languageSelect: select,
    }));
  };

  const removePropertyFromChoices = useCallback(
    (items, property) => {
      Object.values(items).forEach((item) => {
        if (item.type === SurveyElement.QUESTION) {
          const { choices } = item.question;

          choices.forEach((choice) => {
            delete choice[property];
          });
        }
      });

      setSurvey((prevState) => ({
        ...prevState,
        items,
      }));
    },
    [setSurvey]
  );

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();

      removePropertyFromChoices(survey.items, 'newChoice');

      if (isUpdating && !params.deploymentId) {
        dispatch(updateSurvey(survey));
      }

      if (isUpdating && params.deploymentId) {
        const { waveId, totalResponses, unprocessedResponses } =
          survey.deployments.find(({ id }) => id === params.deploymentId);

        const deployment = {
          ...survey,
          deploymentId: params.deploymentId,
          waveId,
          totalResponses,
          unprocessedResponses,
        };

        dispatch(updateDeployment(deployment));
      }

      if (!isUpdating) {
        dispatch(createSurvey(survey));
      }
    },
    [
      dispatch,
      isUpdating,
      survey,
      removePropertyFromChoices,
      params.deploymentId,
    ]
  );

  const pages = survey?.pagesOrder?.map((pageId) => {
    const page = { ...survey.pages[pageId] };

    const { selectedLanguage } = survey;

    page.title = getTranslation(page.title, selectedLanguage);

    const sections = page.sectionIds.map((sectionId) => {
      const section = { ...survey.sections[sectionId] };

      section.title = getTranslation(section.title, selectedLanguage);

      const items = section.itemIds.map((itemId) => {
        const item = survey.items[itemId];
        const { type: itemType } = item;

        if (itemType === SurveyElement.TEXT_BOX) {
          return {
            ...item,
            content: getTranslation(item.content, selectedLanguage),
          };
        }

        if (itemType === SurveyElement.QUESTION) {
          const { question } = item;

          return {
            ...item,
            question: {
              ...question,
              content: getTranslation(question.content, selectedLanguage),
              richText:
                getTranslation(question.richText, selectedLanguage) ||
                `<p>${getTranslation(question.content, selectedLanguage)}</p>`,
              choices: question.choices.map((choice) => ({
                ...choice,
                option: getTranslation(choice.option, selectedLanguage),
              })),
            },
          };
        }

        if (itemType === SurveyElement.QUESTION_GROUP) {
          const { questionGroup } = item;

          return {
            ...item,
            questionGroup: {
              ...questionGroup,
              ...(Array.isArray(questionGroup.dataSet) && {
                dataSet: questionGroup.dataSet.map((set) => {
                  const dataSetTitle = isString(set.title)
                    ? set.title
                    : set.title?.[selectedLanguage] || '';

                  return {
                    ...set,
                    title: dataSetTitle,
                  };
                }),
              }),
              questions: questionGroup.questions.map((question) => ({
                ...question,
                content: getTranslation(question.content, selectedLanguage),
                richText:
                  getTranslation(question?.richText, selectedLanguage) ||
                  `<p>${getTranslation(
                    question.content,
                    selectedLanguage
                  )}</p>`,
              })),
              choices: questionGroup.choices?.map((choice) => {
                if (choice.questionChoices) {
                  const choicesTitle = isString(choice.title)
                    ? choice.title
                    : choice.title?.[selectedLanguage] || '';

                  return {
                    ...choice,
                    title: choicesTitle,
                    questionChoices: choice.questionChoices.map(
                      (questionChoice) => ({
                        ...questionChoice,
                        option: getTranslation(
                          questionChoice.option,
                          selectedLanguage
                        ),
                      })
                    ),
                  };
                }

                return {
                  ...choice,
                  option: getTranslation(choice.option, selectedLanguage),
                };
              }),
            },
          };
        }

        return { ...item };
      });

      return { ...section, items };
    });

    return { ...page, sections };
  });

  const questionsAreNotEmpty = useMemo(
    () =>
      Object?.keys(survey.items).every((key) => {
        const isQuestion = survey?.items[key]?.type === SurveyElement.QUESTION;

        if (!isQuestion) {
          return true;
        }

        return survey.items[key].question?.content.en?.trim() !== '';
      }),
    [survey.items]
  );

  const canSubmit = useMemo(() => {
    const hasCommonData =
      survey.name && survey.versionTitle[survey.defaultLanguage];

    const hasUpdatingData = isUpdating && survey.versionName;

    const hasOrganizationData =
      isOrganization && survey.organizationId && survey.organizationName;

    return (
      hasCommonData &&
      (hasUpdatingData || isTemplate || hasOrganizationData) &&
      questionsAreNotEmpty
    );
  }, [
    survey.name,
    survey.versionTitle,
    survey.versionName,
    survey.organizationId,
    survey.organizationName,
    survey.defaultLanguage,
    questionsAreNotEmpty,
    isUpdating,
    isTemplate,
    isOrganization,
  ]);

  useEffect(() => {
    onOrganizationChangeHandler(survey?.organization || null);
  }, []);

  const disabledCreateOrUpdateButton = useMemo(
    () => !canSubmit || loading,
    [canSubmit, loading]
  );

  return (
    <>
      {backtrackingModalIsOpen && (
        <BacktrackingModal
          isOpen={backtrackingModalIsOpen}
          toggle={backtrackingModalToggle}
          survey={survey}
          setSurvey={setSurvey}
        />
      )}
      <form onSubmit={onSubmitHandler}>
        <div className="columns is-mobile is-multiline">
          <div
            className={classNames('column is-full-mobile is-one-third-tablet', {
              ' is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd':
                isTemplate,
              'is-one-third-desktop': isOrganization,
            })}
          >
            <div className="field">
              <label className="label">Survey Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={survey.name}
                  placeholder="Name"
                  required
                  onChange={(e) => onChangeSurveyNameHandler(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames('column is-full-mobile is-one-third-tablet', {
              'is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd':
                isTemplate,
              'is-one-third-desktop': isOrganization,
            })}
          >
            <div className="field">
              <label className="label">Survey Title</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={getTranslation(
                    survey.versionTitle,
                    survey.selectedLanguage
                  )}
                  placeholder="Title"
                  required
                  onChange={(e) => onChangeSurveyTitleHandler(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames('column is-full-mobile is-one-third-tablet', {
              'is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd':
                isTemplate,
              'is-one-third-desktop': isOrganization,
            })}
          >
            <div className="field">
              <label className="label">Survey Version Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={survey.versionName}
                  placeholder="Version name"
                  disabled={params.deploymentId}
                  onChange={(e) => onChangeVersionNameHandler(e.target.value)}
                />
              </div>
            </div>
          </div>
          {isOrganization && (
            <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd">
              <div className="field">
                <label className="label">Survey Organization</label>
                <div className="control">
                  <Select
                    placeholder="Organization"
                    noOptionsMessage={() => 'No available organizations'}
                    value={survey.organization}
                    options={organizations}
                    isLoading={fetchingOrgs}
                    isDisabled={!survey.canSelectOrganization || isUpdating}
                    onChange={onOrganizationChangeHandler}
                    isClearable={survey.canSelectOrganization || !isUpdating}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className={classNames('column is-full-mobile is-one-third-tablet', {
              'is-one-quarter-desktop is-one-quarter-widescreen': isTemplate,
              'is-one-quarter-widescreen is-one-fifth-fullhd': isOrganization,
            })}
          >
            <div className="field">
              <label className="label">Survey Language</label>
              <div className="control">
                <Select
                  placeholder="Language"
                  value={survey.languageSelect}
                  options={survey.availableLanguages.map((code) => ({
                    label: LanguageName[code],
                    value: code,
                  }))}
                  onChange={onChangeLanguageHandler}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames('column is-full-mobile is-one-third-tablet', {
              'is-one-quarter-desktop is-one-quarter-widescreen': isTemplate,
              'is-one-quarter-widescreen is-one-fifth-fullhd': isOrganization,
            })}
          >
            <div className="field">
              <label className="label">Disable Deselect</label>
              <Toggle
                toggled={survey.disableDeselect}
                onToggle={onDeselectToggleHandler}
                haveMargin={false}
              />
            </div>
          </div>
          <div className="column survey-builder__action-buttons">
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <button
                  type="button"
                  className="button is-light"
                  disabled={loading}
                  onClick={() => history.push(listSurveysPath(survey.type))}
                >
                  Go Back
                </button>
              </div>
              <div className="control">
                <button
                  type="submit"
                  className={classNames('button', 'is-success', {
                    'is-loading': loading || updatingDeployment,
                  })}
                  disabled={disabledCreateOrUpdateButton}
                >
                  {!isUpdating && 'Create Survey'}
                  {isUpdating && params.versionId && 'Update Version'}
                  {isUpdating && params.deploymentId && 'Update Deployment'}
                </button>
              </div>
              <div className="control">
                <Dropdown
                  placeHolder={
                    <span className="icon">
                      <i className="mdi mdi-cog" />
                    </span>
                  }
                >
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onAddPageHandler(0)}
                  >
                    <span className="icon">
                      <i className="mdi mdi-plus-circle-outline" />
                    </span>
                    <span>Add Page</span>
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() =>
                      onOpenModalHandler(ModalType.SURVEY_TRANSLATIONS)
                    }
                  >
                    <span className="icon">
                      <i className="mdi mdi-earth" />
                    </span>
                    <span>Translations</span>
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={onCondenseAllQuestionsHandler}
                  >
                    <span className="icon">
                      <i className="mdi mdi-arrow-collapse-vertical" />
                    </span>
                    {!survey.isCondensed && <span>Condense</span>}
                    {survey.isCondensed && <span>Uncondense</span>}
                  </button>
                  {!params.deploymentId && isUpdating && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => onOpenModalHandler(ModalType.DEPLOYMENTS)}
                    >
                      <span className="icon">
                        <i className="mdi mdi-cloud-upload-outline" />
                      </span>
                      <span>Deployments</span>
                    </button>
                  )}
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => onOpenModalHandler(ModalType.ACTIONS)}
                  >
                    <span className="icon">
                      <i className="mdi mdi-creation" />
                    </span>
                    <span>Actions</span>
                  </button>
                  {!isTemplate && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => onOpenModalHandler(ModalType.COPY_SURVEY)}
                    >
                      <span className="icon">
                        <i className="mdi mdi-content-copy" />
                      </span>
                      <span>Copy Survey</span>
                    </button>
                  )}
                  {pages.length > 1 && (
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={backtrackingModalToggle}
                    >
                      <span className="icon">
                        <i className="mdi mdi-undo-variant" />
                      </span>
                      <span>Backtracking</span>
                    </button>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEndHandler}>
          <Pages
            survey={survey}
            setSurvey={setSurvey}
            pages={pages}
            onAddPage={onAddPageHandler}
            onOpenModal={onOpenModalHandler}
            deploymentId={params.deploymentId}
            onChangeQuestionContent={onChangeQuestionContent}
          />
        </DragDropContext>
      </form>
    </>
  );
};

export default Form;
