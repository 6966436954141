/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { QuestionType, SurveyElement } from 'utils/surveyBuilder/enums';
import { generateLiveSurveyUIBreadcrumb } from 'utils/sentry/breadcrumbs/generateLiveSurveyBreadcrumb';
import { useFormatMessage } from 'hooks';
import { selectErrorStyle } from 'utils';
import Slider from 'components/Slider';
import Select from 'components/Select';

import classes from './Question.module.scss';
import './Question.scss';

const trackStyle = {
  backgroundColor: 'hsl(206.91deg 51.69% 63.66%)',
};
const handleStyle = {
  backgroundColor: 'hsl(206.91deg 77.46% 41.76%)',
};

const Question = ({
  id,
  type,
  content,
  choices,
  rawContent,
  onChangeInput,
  currentResponse,
  required,
  error,
  onClearAnswer,
  disableDeselect,
  singleLine,
  sliderProps = {
    min: 0,
    max: 100,
    step: 10,
    defaultValue: null,
  },
}) => {
  const {
    min: sliderMin,
    max: sliderMax,
    step: sliderStep,
    defaultValue,
    plusToMax,
    ...sliderPropsRest
  } = sliderProps;

  const domain = [sliderMin, sliderMax];

  const [ranges, setRanges] = useState({
    update: [defaultValue ?? sliderMin],
    values: [defaultValue ?? sliderMin],
  });

  const isMultipleResponse = type === QuestionType.MULTIPLE_RESPONSE;
  const isSingleResponse = type === QuestionType.SINGLE_RESPONSE;
  const isTextArea = type === QuestionType.TEXT_AREA;
  const isDropdown = type === QuestionType.DROPDOWN;
  const isRankOrder = type === QuestionType.RANK_ORDER;
  const isSlider = type === QuestionType.SLIDER;
  const visibleChoices = choices.filter((choice) => choice.visible);
  const choiceCount = visibleChoices.length;
  const isAnswered = Object.values(currentResponse.choices || {}).some(
    (answer) => answer
  );

  const sliderFirstValue = ranges.values[0];

  const currentResponseValue = useMemo(
    () =>
      Number.isInteger(currentResponse.sliderProps?.value)
        ? currentResponse.sliderProps.value
        : sliderFirstValue,
    [currentResponse.value, sliderFirstValue]
  );

  const onUpdateSlider = (update) => {
    setRanges((prevState) => ({ ...prevState, update }));
  };

  const onChangeSlider = useCallback(
    (values) => {
      setRanges((prevState) => ({ ...prevState, values }));

      onChangeInput({
        target: {
          value: values,
          name: id,
          type: QuestionType.SLIDER,
          min: sliderMin,
          max: sliderMax,
          step: sliderStep,
          defaultValue,
        },
      });
    },
    [id, onChangeInput, setRanges]
  );

  const onChangeTextArea = useCallback(
    (e) => {
      onChangeInput({
        target: {
          value: e.target.value,
          name: id,
          singleLine,
          type: QuestionType.TEXT_AREA,
        },
      });
    },
    [id, onChangeInput]
  );

  useEffect(() => {
    if (isSlider && currentResponseValue !== sliderFirstValue && sliderMax) {
      onUpdateSlider([currentResponseValue]);
      onChangeSlider([currentResponseValue]);
    }
  }, [id, currentResponseValue, isSlider, sliderMax]);

  const deselectTranslated = useFormatMessage('LiveSurvey.deselect');

  return (
    <div className="field">
      <label
        id="question-content"
        className={classNames('subtitle is-5', classes['question-content'])}
      >
        {content}
        {required && <span className={classes['required-asterisk']}>*</span>}
      </label>
      <div
        className={classNames(
          'field is-grouped is-grouped-multiline',
          classes.field,
          { [classes['no-margin-bottom']]: isSlider }
        )}
      >
        {(isSingleResponse || isMultipleResponse) &&
          visibleChoices.map((choice) => (
            <div
              id="question-choice"
              key={choice.id}
              className={classNames('control', classes['question-choice'])}
            >
              <div className={classes['question-choice__content']}>
                {isMultipleResponse && (
                  <label className="b-checkbox checkbox">
                    <input
                      id="multiple-response-input"
                      type="checkbox"
                      name={id}
                      value={choice.id}
                      onChange={onChangeInput}
                      checked={currentResponse.choices[choice.id]}
                      data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                        SurveyElement.QUESTION,
                        { response: choice.option, question: rawContent, type }
                      )}
                    />
                    <span
                      className={classNames('check', {
                        [classes['checkbox-error']]: error,
                      })}
                    />
                    <span
                      id="multiple-response-content"
                      className={classNames(
                        'control-label',
                        classes['question-choice']
                      )}
                    >
                      {choice.option}
                    </span>
                  </label>
                )}
                {isSingleResponse && (
                  <label className="b-radio radio">
                    <input
                      id="single-response-input"
                      type="radio"
                      name={id}
                      value={choice.id}
                      onChange={onChangeInput}
                      checked={currentResponse.choices[choice.id]}
                      data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                        SurveyElement.QUESTION,
                        { response: choice.option, question: rawContent, type }
                      )}
                    />
                    <span
                      className={classNames('check', {
                        [classes['radio-error']]: error,
                      })}
                    />
                    <span
                      id="single-response-content"
                      className="control-label"
                    >
                      {choice.option}
                    </span>
                  </label>
                )}
              </div>
              {currentResponse.choices[choice.id] && choice.openEnded && (
                <input
                  className={classNames('input', classes['open-ended-input'])}
                  onChange={(e) => {
                    onChangeInput({
                      target: {
                        choiceId: choice.id,
                        value: e.target.value,
                        name: id,
                        type: 'open-ended',
                      },
                    });
                  }}
                  value={
                    currentResponse.openEndedChoiceAnswers?.[choice.id] || ''
                  }
                />
              )}
            </div>
          ))}
        {isSingleResponse && isAnswered && !disableDeselect && (
          <div className={classNames('control', classes['question-choice'])}>
            <button
              id="deselect-button"
              className="button is-link"
              type="button"
              onClick={() => onClearAnswer(id)}
              data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                SurveyElement.QUESTION,
                { response: '[deselect]', question: rawContent, type }
              )}
            >
              {deselectTranslated}
            </button>
          </div>
        )}
        {isMultipleResponse && error && (
          <span className={classes['required-checkbox']}>
            Please choose at least one option.
          </span>
        )}
        {isSingleResponse && error && (
          <span className={classes['required-checkbox']}>
            Please choose one option.
          </span>
        )}
        {isDropdown && (
          <div className={classNames('control', classes.dropdown)}>
            <Select
              styles={selectErrorStyle(error)}
              options={visibleChoices.map(({ option, id: choiceId }) => ({
                label: option,
                value: choiceId,
                customProps: {
                  'data-sentry-custom-breadcrumb':
                    generateLiveSurveyUIBreadcrumb(SurveyElement.QUESTION, {
                      choice: option,
                      question: rawContent,
                      type,
                    }),
                },
              }))}
              isClearable
              onChange={(select) =>
                onChangeInput({
                  target: {
                    value: select?.value,
                    name: id,
                    type: QuestionType.DROPDOWN,
                  },
                })
              }
            />
            {error && (
              <span className={classes['required-checkbox']}>
                Please answer to this question.
              </span>
            )}
          </div>
        )}
        {isRankOrder && (
          <>
            {visibleChoices.map((choice) => {
              return (
                <div
                  key={choice.id}
                  className={classNames('control', classes.control)}
                >
                  <div
                    className={classNames(
                      'field is-grouped',
                      classes['rank-question']
                    )}
                  >
                    <div className={classNames('control', classes.order)}>
                      <div style={{ width: '100%' }}>
                        <Select
                          styles={selectErrorStyle(
                            error && !currentResponse.choices[choice.id]
                          )}
                          options={Array.from(Array(choiceCount)).map(
                            (_, index) => ({
                              label: index + 1,
                              value: index + 1,
                              customProps: {
                                'data-sentry-custom-breadcrumb':
                                  generateLiveSurveyUIBreadcrumb(
                                    SurveyElement.QUESTION,
                                    {
                                      response: index + 1,
                                      choice: choice.option,
                                      question: rawContent,
                                      type,
                                    }
                                  ),
                              },
                            })
                          )}
                          value={
                            currentResponse.choices[choice.id]
                              ? {
                                  label: currentResponse.choices[choice.id],
                                  value: currentResponse.choices[choice.id],
                                }
                              : null
                          }
                          isClearable
                          onChange={(select) => {
                            onChangeInput({
                              target: {
                                type: QuestionType.RANK_ORDER,
                                name: id,
                                value: {
                                  rank: select?.value,
                                  choiceId: choice.id,
                                },
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="control is-expanded"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {choice.option}
                    </div>
                  </div>
                </div>
              );
            })}
            {error && (
              <span className={classes['required-checkbox']}>
                Please answer to this question.
              </span>
            )}
          </>
        )}
        {isTextArea && (
          <div className="control">
            {singleLine ? (
              <input
                id="text-area-input"
                className={classNames('input', {
                  [classes['text-area-error']]: error,
                })}
                name={id}
                onChange={onChangeTextArea}
                data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                  SurveyElement.QUESTION,
                  {
                    response: currentResponse.openAnswer,
                    question: rawContent,
                    type,
                  }
                )}
                value={currentResponse.openAnswer}
              />
            ) : (
              <textarea
                id="text-area-input"
                className={classNames('textarea', {
                  [classes['text-area-error']]: error,
                })}
                name={id}
                data-sentry-custom-breadcrumb={generateLiveSurveyUIBreadcrumb(
                  SurveyElement.QUESTION,
                  {
                    response: currentResponse.openAnswer,
                    question: rawContent,
                    type,
                  }
                )}
                onChange={onChangeTextArea}
                value={currentResponse.openAnswer}
              />
            )}
            {error && (
              <span className={classes['required-checkbox']}>
                Please answer to this question.
              </span>
            )}
          </div>
        )}
        {isSlider && (
          <div className={classes.slider}>
            <Slider
              mode={1}
              trackStyle={trackStyle}
              handleStyle={handleStyle}
              domain={domain}
              step={sliderStep}
              {...sliderPropsRest}
              handleSuffixInLastValue={plusToMax}
              onUpdate={onUpdateSlider}
              onChange={onChangeSlider}
              values={ranges.values}
              rootProps={{
                'data-sentry-custom-breadcrumb': generateLiveSurveyUIBreadcrumb(
                  SurveyElement.QUESTION,
                  {
                    response: ranges.values,
                    question: rawContent,
                    type,
                  }
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Question.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    QuestionType.SINGLE_RESPONSE,
    QuestionType.MULTIPLE_RESPONSE,
    QuestionType.TEXT_AREA,
    QuestionType.DROPDOWN,
    QuestionType.RANK_ORDER,
    QuestionType.SLIDER,
  ]).isRequired,
  singleLine: PropTypes.bool,
  content: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  sliderProps: PropTypes.shape({
    domain: PropTypes.arrayOf(PropTypes.number),
    step: PropTypes.number,
  }),
  onChangeInput: PropTypes.func.isRequired,
  currentResponse: PropTypes.shape({
    openAnswer: PropTypes.string.isRequired,
    choices: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  required: PropTypes.bool.isRequired,
  onClearAnswer: PropTypes.func.isRequired,
  disableDeselect: PropTypes.bool,
};

Question.defaultProps = {
  singleLine: false,
};

export default Question;
