import { toastr } from 'react-redux-toastr';

import { copySurvey } from 'services/surveys';
import { saveErrorLog } from 'services/utils';
import { ServiceOperations, ServiceSubOperations } from 'utils/enums';
import {
  SURVEYS_COPY_SURVEY_INIT,
  SURVEYS_COPY_SURVEY_SUCCESS,
  SURVEYS_COPY_SURVEY_FAIL,
} from 'state/actionCreators/surveys';

const createSurveyCopy = ({
  surveyId,
  surveyData,
  organizationId,
  newSurveyData,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_COPY_SURVEY_INIT());

    const { firstName, lastName } = getState()?.auth?.userData ?? {};

    const createdBy = `${firstName} ${lastName}`;

    try {
      await copySurvey(
        { surveyId, surveyData, organizationId, newSurveyData },
        { dispatch, getState }
      );

      toastr.success('Success', 'Survey copied successfully');
      dispatch(SURVEYS_COPY_SURVEY_SUCCESS());
    } catch (error) {
      console.error('Error Copying Survey:', error);

      await saveErrorLog(error, {
        operation: ServiceOperations.COPY_SURVEY,
        subOperation:
          ServiceSubOperations[ServiceOperations.COPY_SURVEY].COPY_SURVEY,
        surveyId: surveyId ?? null,
        organizationId: organizationId ?? null,
        createdBy: createdBy ?? null,
      });

      toastr.error('Error Copying Survey', error.message);
      dispatch(SURVEYS_COPY_SURVEY_FAIL({ error }));
    }
  };
};

export default createSurveyCopy;
