import { QuestionType, SurveyElement } from 'utils/surveyBuilder/enums';

const generateQuestionBreadcrumb = (props) => {
  const { type, question, response } = props;
  if (
    type === QuestionType.SINGLE_RESPONSE ||
    type === QuestionType.MULTIPLE_RESPONSE ||
    type === QuestionType.DROPDOWN
  ) {
    return `Question: ${question} - Choice: ${response}`;
  }

  if (type === QuestionType.TEXT_AREA) {
    return `Question: ${question} - Answer: ${response}`;
  }

  if (type === QuestionType.RANK_ORDER) {
    const { choice } = props;

    return `Question: ${question} - Choice: ${choice} - Rank: ${response}`;
  }

  if (type === QuestionType.SLIDER) {
    return `Question: ${question} - value: ${response}`;
  }

  return null;
};

const generateQuestionGroupBreadcrumb = (props) => {
  const { question, response } = props;

  return `Question Group: ${question} - Response: ${response}`;
};

export const generateLiveSurveyUIBreadcrumb = (elementType, props = {}) => {
  if (elementType === SurveyElement.QUESTION) {
    return generateQuestionBreadcrumb(props);
  }

  if (elementType === SurveyElement.QUESTION_GROUP) {
    return generateQuestionGroupBreadcrumb(props);
  }

  return null;
};
