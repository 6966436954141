import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import {
  getAuth,
  signOut,
  updatePassword,
  onAuthStateChanged,
  signInWithEmailLink,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
} from 'firebase/auth';
import * as Sentry from '@sentry/react';
import firebase from 'firebase.js';

import { ERROR_RESPONSE, firebaseError, FIREBASE_RESPONSE, roles } from 'utils';
import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';
import { usersCleanUp } from './users';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction(
  'AUTH_FETCH_USER_DATA_INIT'
);
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction(
  'AUTH_FETCH_USER_DATA_SUCCESS'
);
export const AUTH_FETCH_USER_DATA_FAIL = createAction(
  'AUTH_FETCH_USER_DATA_FAIL'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

const appAuth = getAuth();

export const logout = () => {
  return async (dispatch) => {
    dispatch(AUTH_LOGOUT_INIT());

    dispatch(usersCleanUp());
    await signOut(appAuth);

    dispatch(AUTH_LOGOUT_SUCCESS());
  };
};

export const verifyAuth = () => {
  return (dispatch) => {
    onAuthStateChanged(appAuth, (user) => {
      dispatch(AUTH_RESTORE_SESSION_INIT());

      if (user !== null) {
        Sentry.setUser({ email: user.email });

        return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
      }

      dispatch(AUTH_RESTORE_SESSION_FAIL());
      Sentry.setUser(null);
      return dispatch(logout());
    });
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(AUTH_FETCH_USER_DATA_INIT());

    const { uid } = appAuth.currentUser;

    let user;

    try {
      user = (await collection(Collections.USERS).doc(uid).get()).data();
    } catch (error) {
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }

    if (!user) {
      return dispatch(logout());
    }

    return dispatch(
      AUTH_FETCH_USER_DATA_SUCCESS({
        id: uid,
        ...user,
      })
    );
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const auth = (email, password) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_SIGN_IN_INIT());
    const { locale } = getState().preferences;

    try {
      await signInWithEmailAndPassword(appAuth, email, password);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);

      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }
    const { emailVerified } = appAuth.currentUser;

    if (!emailVerified) {
      const errorMessage = firebaseError(
        FIREBASE_RESPONSE.USER_DISABLED,
        locale
      );
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }

    let idTokenResult;

    try {
      idTokenResult = await appAuth.currentUser.getIdTokenResult();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }

    if (idTokenResult.claims.role === roles.REPORT_VIEWER) {
      const errorMessage = firebaseError(
        ERROR_RESPONSE.USER_IS_REPORT_VIEWER,
        locale
      );
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }

    return dispatch(fetchUserData());
  };
};

export const setPassword = (email, password, url) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_SET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await signInWithEmailLink(appAuth, email, url);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    const user = appAuth.currentUser;

    try {
      await updatePassword(user, password);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    dispatch(AUTH_SET_PASSWORD_SUCCESS());

    return dispatch(fetchUserData());
  };
};

export const resetPassword = (email) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await sendPasswordResetEmail(appAuth, email);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword = (currentPassword, newPassword) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_CHANGE_PASSWORD_INIT());
    const { locale } = getState().preferences;

    const user = appAuth.currentUser;

    const { email } = user;

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    try {
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    try {
      await updatePassword(user, newPassword);
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password changed successfully');
    return dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
  };
};
