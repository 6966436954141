import useDispatchState from 'hooks/useDispatchState';
import { useStore } from 'react-redux';
import {
  SET_IS_PREVIEW,
  SET_ORGANIZATION_NAME,
  SET_PAGE_INDEX,
  SET_PAGES_WITH_ACTIONS,
  SET_QUESTION_RESPONSES,
  SET_RESPONSE,
} from 'state/actionCreators/liveSurvey';
import {
  selectIsPreview,
  selectOrganizationName,
  selectPageIndex,
  selectPagesWithActions,
  selectQuestionGroupsDemographics,
  selectQuestionResponses,
  selectQuestionsDemographics,
  selectResponse,
} from 'state/selectors/liveSurvey';

const useSurveyResponseStates = ({
  surveyPages: pagesWithActionsInitialState,
  questionResponses: questionResponsesInitialState,
  organizationName: organizationNameInitialState,
  pageIndex: pageIndexInitialState,
  response: responseInitialState,
  isPreview: isPreviewInitialState,
  readOnly,
} = {}) => {
  const store = useStore();

  const [pagesWithActions, setPagesWithActions] = useDispatchState({
    selector: selectPagesWithActions,
    action: SET_PAGES_WITH_ACTIONS,
    initialState: pagesWithActionsInitialState,
    readOnly,
  });

  const [questionResponses, setQuestionResponses] = useDispatchState({
    selector: selectQuestionResponses,
    action: SET_QUESTION_RESPONSES,
    initialState: questionResponsesInitialState,
    readOnly,
  });

  const [organizationName, setOrganizationName] = useDispatchState({
    selector: selectOrganizationName,
    action: SET_ORGANIZATION_NAME,
    initialState: organizationNameInitialState,
    readOnly,
  });

  const [pageIndex, setPageIndexInitialState] = useDispatchState({
    selector: selectPageIndex,
    action: SET_PAGE_INDEX,
    initialState: pageIndexInitialState,
    readOnly,
  });

  const [response, setResponse] = useDispatchState({
    selector: selectResponse,
    action: SET_RESPONSE,
    initialState: responseInitialState,
    readOnly,
  });

  const [isPreview, setIsPreview] = useDispatchState({
    selector: selectIsPreview,
    action: SET_IS_PREVIEW,
    initialState: isPreviewInitialState,
    readOnly,
  });

  // Should be getters as they are only readed once and we don't want them to ve evaluated every time
  const getQuestionsDemographicsState = () => {
    const state = store.getState();

    return selectQuestionsDemographics({ isPreview })(state);
  };

  const getQuestionGroupsDemographicsState = () => {
    const state = store.getState();

    return selectQuestionGroupsDemographics({ isPreview })(state);
  };

  return {
    pagesWithActionsStateDispatcher: [pagesWithActions, setPagesWithActions],
    questionResponsesStateDispatcher: [questionResponses, setQuestionResponses],
    organizationNameStateDispatcher: [organizationName, setOrganizationName],
    pageIndexStateDispatcher: [pageIndex, setPageIndexInitialState],
    responseStateDispatcher: [response, setResponse],
    isPreviewStateDispatcher: [isPreview, setIsPreview],
    getQuestionsDemographicsState,
    getQuestionGroupsDemographicsState,
  };
};

export default useSurveyResponseStates;
