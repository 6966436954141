/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Collapse from 'components/Collapse';
import {
  addItemSelectOptions as selectOptions,
  addItemSelectStyles,
} from 'utils/surveyBuilder';
import { DEFAULT_SLIDER_PROPS } from 'constants/SurveyBuilder';
import { SurveyElement, QuestionType } from 'utils/surveyBuilder/enums';
import QuestionChoice from 'components/SurveyBuilder/QuestionBuilder/QuestionChoice';
import { ModalType, OrderTypes } from 'utils/enums';
import TextEditor from 'components/TextEditor';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classes from './Item.module.scss';

import './Item.scss';

const ChoicesOrder = {
  [OrderTypes.RANDOM]: (
    <span className={classes['choice-order']}>
      <i className="mdi mdi-shuffle-variant" />
    </span>
  ),
  [OrderTypes.ASC]: (
    <span className={classes['choice-order']}>
      <i className="mdi mdi-sort-alphabetical-ascending" />
    </span>
  ),
  [OrderTypes.DESC]: (
    <span className={classes['choice-order']}>
      <i className="mdi mdi-sort-alphabetical-descending" />
    </span>
  ),
};

const Item = ({
  survey,
  setSurvey,
  itemId,
  pageIndex,
  sectionIndex,
  itemIndex,
  type,
  visible,
  richText,
  condensed,
  onDeleteItem,
  onSelectItemOption,
  onItemPropertyChange,
  question,
  onCondenseQuestion,
  deploymentId,
  onCheckHasAction,
  onClickSeeActions,
  onOpenModal,
}) => {
  const isQuestion = useMemo(() => type === SurveyElement.QUESTION, [type]);

  const onTextBoxChangeHandler = useCallback(
    (value) =>
      setSurvey((prevState) => {
        const { items, selectedLanguage } = prevState;
        const item = items[itemId];

        return {
          ...prevState,
          items: {
            ...items,
            [item.id]: {
              ...item,
              content: {
                ...item.content,
                [selectedLanguage]: value,
              },
            },
          },
        };
      }),
    [itemId, setSurvey]
  );

  const onTextBoxRichTextChangeHandler = useCallback(
    (value) =>
      setSurvey((prevState) => {
        const { items, selectedLanguage } = prevState;
        const item = items[itemId];
        return {
          ...prevState,
          items: {
            ...items,
            [item.id]: {
              ...item,
              richText: {
                ...item.richText,
                [selectedLanguage]: value,
              },
            },
          },
        };
      }),
    [itemId, setSurvey]
  );

  const onClickUpdateQuestionHandler = useCallback(() => {
    const { question: itemQuestion } = survey?.items[itemId];
    onOpenModal(ModalType.SURVEY_QUESTION_BUILDER, {
      itemId,
      previousQuestion: {
        ...itemQuestion,
      },
    });
  }, [onOpenModal, survey?.items, itemId]);

  const onVisibilityChangeHandler = useCallback(
    () => onItemPropertyChange({ visible: !visible }, itemId),
    [onItemPropertyChange, itemId, visible]
  );

  const onDeleteItemHandler = useCallback(
    () => onDeleteItem(itemIndex),
    [onDeleteItem, itemIndex]
  );

  const onClickSeeActionsHandler = useCallback(
    () => onClickSeeActions({ itemId: question.id }),
    [onClickSeeActions, question.id]
  );

  const hasAction = useMemo(
    () => onCheckHasAction(question.id),
    [onCheckHasAction, question.id]
  );

  const addItemSelectOptions = useMemo(
    () =>
      deploymentId
        ? selectOptions.filter((option) => option.label === 'Text Box')
        : selectOptions,
    [deploymentId]
  );

  const [textEditorState, setTextEditorState] = useState({
    editorState: EditorState.createEmpty(),
  });

  const [questionTextEditor, setQuestionTextEditor] = useState({
    editorState: EditorState.createEmpty(),
  });

  const onChangeQuestionRichText = useCallback(
    (value) =>
      survey.selectedLanguage &&
      setSurvey((prevState) => ({
        ...prevState,
        items: {
          ...prevState.items,
          [itemId]: {
            ...prevState.items[itemId],
            question: {
              ...prevState.items[itemId].question,
              richText: {
                ...prevState.items[itemId].question.richText,
                [survey.selectedLanguage]: value,
              },
            },
          },
        },
      })),
    [survey.selectedLanguage, itemId, setSurvey]
  );

  const onChangeQuestionContent = useCallback(
    (value) =>
      survey.selectedLanguage &&
      setSurvey((prevState) => ({
        ...prevState,
        items: {
          ...prevState.items,
          [itemId]: {
            ...prevState.items[itemId],
            question: {
              ...prevState.items[itemId].question,
              content: {
                ...prevState.items[itemId].question.content,
                [survey.selectedLanguage]: value,
              },
            },
          },
        },
      })),
    [survey.selectedLanguage, itemId, setSurvey]
  );

  const getEditorState = useCallback(() => {
    if (richText) {
      const blocksFromHtml = htmlToDraft(richText[survey.selectedLanguage]);

      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      const editorStatePlainText = editorState
        .getCurrentContent()
        .getPlainText();
      const editorStateRichText = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      const textEditorPlainText = textEditorState.editorState
        .getCurrentContent()
        .getPlainText();

      const textEditorRichText = draftToHtml(
        convertToRaw(textEditorState.editorState.getCurrentContent())
      );

      if (
        editorStatePlainText !== textEditorPlainText ||
        editorStateRichText !== textEditorRichText
      ) {
        setTextEditorState({
          editorState,
        });
      }
    }
  }, [survey.selectedLanguage, richText]);

  useEffect(() => {
    if (type === QuestionType.TEXT_BOX) {
      getEditorState();
    }
  }, [getEditorState, type]);

  const getQuestionTextEditorContent = useCallback(() => {
    if (question.richText) {
      const blocksFromHtml = htmlToDraft(question.richText);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      const editorState = EditorState.createWithContent(contentState);

      setQuestionTextEditor({
        editorState,
      });
    }
  }, [survey.selectedLanguage, question.richText]);

  useEffect(() => {
    if (isQuestion) {
      const questionTextEditorPlainText = questionTextEditor.editorState
        .getCurrentContent()
        .getPlainText();
      const questionTextEditorRichText = draftToHtml(
        convertToRaw(questionTextEditor.editorState.getCurrentContent())
      );

      if (
        (question.content || question.richText) &&
        (questionTextEditorPlainText !== question.content ||
          questionTextEditorRichText !== question.richText)
      ) {
        getQuestionTextEditorContent();
      }
    }
  }, [
    getQuestionTextEditorContent,
    survey.selectedLanguage,
    question.content,
    question.richText,
    isQuestion,
  ]);

  const onEditorStateChange = useCallback(
    (editorState) => {
      setTextEditorState({
        editorState,
      });

      onTextBoxRichTextChangeHandler(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
      onTextBoxChangeHandler(editorState.getCurrentContent().getPlainText());
    },
    [onTextBoxRichTextChangeHandler, onTextBoxChangeHandler]
  );

  const onQuestionTextEditorChange = useCallback(
    (editorState) => {
      setQuestionTextEditor({
        editorState,
      });

      const editorToHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      onChangeQuestionRichText(editorToHtml);

      onChangeQuestionContent(editorState.getCurrentContent().getPlainText());
    },
    [onChangeQuestionRichText, onChangeQuestionContent]
  );

  const sliderProps = useMemo(
    () => question.sliderProps ?? DEFAULT_SLIDER_PROPS,
    [question.sliderProps]
  );

  const pageIndexToRender = Number.isInteger(pageIndex)
    ? `${pageIndex + 1}.`
    : '';

  return (
    <div
      className={classNames('item-container', {
        'survey-item--hidden': !visible,
      })}
    >
      <div className="columns is-vcentered is-mobile is-multiline">
        <div className="column is-full-mobile is-full-tablet is-half-desktop">
          <div className={classes['page-count']}>
            {`${pageIndexToRender}${sectionIndex + 1}.${itemIndex + 1}`}
          </div>
          {question && (
            <div
              className={classNames(
                'subtitle field is-grouped is-grouped-multiline',
                classes['question-title']
              )}
            >
              {isQuestion && (
                <TextEditor
                  textEditorState={questionTextEditor}
                  onEditorStateChange={onQuestionTextEditorChange}
                  editorClassName={classes.editor}
                  toolbarClassName={classes.toolbar}
                />
              )}
              {question.required && (
                <span className="tag is-required">Required</span>
              )}
              {question.regionalBreakdown && (
                <span
                  className={classNames(
                    'tag',
                    classes['is-regional-breakdown']
                  )}
                >
                  Regional Breakdown
                </span>
              )}
              {ChoicesOrder[question.choicesOrder]}
            </div>
          )}
        </div>
        <div
          className="column is-full-mobile is-full-tablet is-half-desktop"
          style={{ alignSelf: 'flex-start' }}
        >
          <div className="field is-grouped is-grouped-multiline item-action-bar">
            <div
              className="control item-action-bar__button"
              style={{ minWidth: '150px' }}
            >
              <Select
                placeholder="Add Item"
                styles={addItemSelectStyles}
                value={null}
                isSearchable={false}
                options={addItemSelectOptions}
                onChange={({ value }) =>
                  onSelectItemOption(value, itemIndex + 1)
                }
              />
            </div>
            <Button
              className="control item-action-bar__button"
              icon={classNames('mdi', {
                'mdi-eye': visible,
                'mdi-eye-off': !visible,
              })}
              onClick={onVisibilityChangeHandler}
            />
            {isQuestion && hasAction && (
              <Button
                className="control item-action-bar__button"
                onClick={onClickSeeActionsHandler}
              >
                See actions
              </Button>
            )}
            {isQuestion && (
              <Button
                className="control item-action-bar__button"
                onClick={onClickUpdateQuestionHandler}
              >
                Edit
              </Button>
            )}
            <Button
              className="control item-action-bar__button"
              onClick={onDeleteItemHandler}
              disabled={deploymentId && !(type === SurveyElement.TEXT_BOX)}
            >
              Delete
            </Button>
            {isQuestion && (
              <Collapse
                className="item-action-bar__button"
                collapsed={condensed}
                onCollapse={() => onCondenseQuestion(itemId, !condensed)}
              />
            )}
          </div>
        </div>
        {question?.tags.length > 0 && (
          <div className="column">
            <div className="field">
              <label className="label">Tags</label>
              <div className="control">
                <div className="field is-grouped is-grouped-multiline">
                  {question.tags.map(({ displayName, weight }) => (
                    <div key={displayName} className="control">
                      <div className="tags has-addons">
                        <span key={displayName} className="tag is-link">
                          {displayName}
                        </span>
                        {weight && <span className="tag">{weight}</span>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {type === SurveyElement.TEXT_BOX && (
        <TextEditor
          id="text-box-item"
          textEditorState={textEditorState}
          onEditorStateChange={onEditorStateChange}
        />
      )}

      {question.type === QuestionType.SLIDER && !condensed && (
        <div className={classes['slider-inputs-container']}>
          <label htmlFor="min">
            Min
            <input
              className="input"
              id="min"
              name="min"
              type="text"
              value={sliderProps.min}
              disabled
            />
          </label>
          <label htmlFor="max">
            Max
            <input
              className="input"
              id="max"
              name="max"
              type="text"
              value={sliderProps.max}
              disabled
            />
          </label>
          <label htmlFor="step">
            Step
            <input
              className="input"
              id="step"
              name="step"
              type="text"
              value={sliderProps.step}
              disabled
            />
          </label>
          <label htmlFor="defaultValue">
            Default Value
            <input
              className="input"
              id="defaultValue"
              name="defaultValue"
              type="text"
              value={sliderProps.defaultValue ?? ''}
              disabled
            />
          </label>
        </div>
      )}

      {isQuestion &&
        (question.type === QuestionType.MULTIPLE_RESPONSE ||
          question.type === QuestionType.SINGLE_RESPONSE ||
          question.type === QuestionType.DROPDOWN ||
          question.type === QuestionType.RANK_ORDER) &&
        !condensed &&
        question.choices.map(
          ({
            id,
            option,
            visible: questionChoiceVisibility,
            openEnded,
            notApplicable,
          }) => (
            <QuestionChoice
              key={id}
              choiceValue={option}
              questionType={question.type}
              isDisplaying
              openEnded={openEnded}
              notApplicable={notApplicable}
              visible={questionChoiceVisibility}
            />
          )
        )}
      {isQuestion &&
        question.type === QuestionType.TEXT_AREA &&
        !condensed &&
        (question.singleLine ? (
          <input className="input" type="text" disabled />
        ) : (
          <textarea className="textarea" rows="2" disabled />
        ))}
    </div>
  );
};

Item.propTypes = {
  itemId: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  type: PropTypes.oneOf([SurveyElement.TEXT_BOX, SurveyElement.QUESTION])
    .isRequired,
  visible: PropTypes.bool.isRequired,
  onSelectItemOption: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onItemPropertyChange: PropTypes.func.isRequired,
  question: PropTypes.shape({
    content: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      QuestionType.MULTIPLE_RESPONSE,
      QuestionType.SINGLE_RESPONSE,
      QuestionType.TEXT_AREA,
      QuestionType.DROPDOWN,
      QuestionType.RANK_ORDER,
      QuestionType.SLIDER,
    ]).isRequired,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        option: PropTypes.string.isRequired,
      })
    ).isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        weight: PropTypes.number,
      })
    ).isRequired,
  }),
};

Item.defaultProps = {
  question: {
    type: QuestionType.SINGLE_RESPONSE,
    content: '',
    choices: [],
    tags: [],
  },
};

export default Item;
