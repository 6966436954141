import { toastr } from 'react-redux-toastr';

import { createSurveyVersionService } from 'services/surveys';
import { selectSurveyVersion } from 'state/actions/surveys/control';
import {
  SURVEYS_CREATE_SURVEY_VERSION_FAIL,
  SURVEYS_CREATE_SURVEY_VERSION_INIT,
  SURVEYS_CREATE_SURVEY_VERSION_SUCCESS,
} from 'state/actionCreators/surveys';

export const createSurveyVersion = (surveyData) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_SURVEY_VERSION_INIT());

    let surveyVersionId = null;

    try {
      surveyVersionId = await createSurveyVersionService(
        { surveyData },
        { getState }
      );
    } catch (error) {
      const errorMessage = error.message;

      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_CREATE_SURVEY_VERSION_FAIL({
          error: errorMessage,
        })
      );
    }

    dispatch(SURVEYS_CREATE_SURVEY_VERSION_SUCCESS());

    return dispatch(selectSurveyVersion(surveyVersionId));
  };
};
