const { ConditionOperators } = require("utils/enums");

const meetCondition = (condition, questionResponses) => {
  const { subject, criteria, values } = condition;

  const questionResponse = questionResponses[subject];

  if (criteria === ConditionOperators.HAS_A_RESPONSE && questionResponse) {
    const hasResponse = Object.values(questionResponse.choices).some(
      (choice) => choice
    );

    return hasResponse;
  }

  if (criteria === ConditionOperators.HAS_NO_RESPONSE && questionResponse) {
    const hasNoResponse = Object.values(questionResponse.choices).every(
      (choice) => !choice
    );

    return hasNoResponse;
  }

  if (criteria === ConditionOperators.CONTAINS && questionResponse) {
    const choice = values[0].split('.');

    if (choice.length > 1) {
      const containsResponse = questionResponse.choices[choice[0]][choice[1]];

      return containsResponse;
    }

    const containsResponse = questionResponse.choices[values[0]];

    return containsResponse;
  }

  if (criteria === ConditionOperators.DOES_NOT_CONTAIN && questionResponse) {
    const choice = values[0].split('.');

    if (choice.length > 1) {
      const notContainsResponse =
        !questionResponse.choices[choice[0]][choice[1]];

      return notContainsResponse;
    }

    const notContainsResponse = !questionResponse.choices[values[0]];

    return notContainsResponse;
  }

  if (criteria === ConditionOperators.CONTAINS_ONE_OF && questionResponse) {
    const containsOneOfResponse = values.some((value) => {
      const choice = value.split('.');

      if (choice.length > 1) {
        return questionResponse.choices[choice[0]][choice[1]];
      }

      return questionResponse.choices[value];
    });

    return containsOneOfResponse;
  }

  if (
    criteria === ConditionOperators.DOES_NOT_CONTAIN_ANY_OF &&
    questionResponse
  ) {
    const containsNoneOfResponse = values.every((value) => {
      const choice = value.split('.');

      if (choice.length > 1) {
        return !questionResponse.choices[choice[0]][choice[1]];
      }

      return !questionResponse.choices[value];
    });

    return containsNoneOfResponse;
  }

  if (criteria === ConditionOperators.CONTAINS_ALL_OF && questionResponse) {
    const containsAllOfResponse = values.every((value) => {
      const choice = value.split('.');

      if (choice.length > 1) {
        return questionResponse.choices[choice[0]][choice[1]];
      }

      return questionResponse.choices[value];
    });

    return containsAllOfResponse;
  }
  return true;
};

export default meetCondition;