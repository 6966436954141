import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import {
  RegularOperators,
  ActionBuilderPages,
  ActionsSelectValues,
  ActionBuilderConditionTypes,
  ConditionOperators,
  AvailableActions,
} from 'utils/enums';
import {
  getAllQuestions,
  mapActionConditionValuesToIds,
} from 'utils/actionBuilder';
import classes from './ActionBuilderModal.module.scss';
import CreateAction from './CreateAction';
import ActionsList from './ActionsList';

const ActionBuilderModal = ({
  onClose,
  onCreateAction,
  onEditAction,
  onDeleteAction,
  sections,
  questions,
  questionGroups,
  textBoxes,
  pages,
  pagesOrder,
  actionsList,
  searchItem = null,
}) => {
  const [pageToShow, setPageToShow] = useState(ActionBuilderPages.ACTIONS_LIST);
  const [conditions, setConditions] = useState([
    {
      type: ActionBuilderConditionTypes.CONDITION,
      subject: null,
      criteria: null,
      values: [],
    },
  ]);
  const [actions, setActions] = useState([{ action: null, subject: null }]);
  const [id, setId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searching, setSearching] = useState(searchItem);

  const allQuestions = useMemo(
    () =>
      getAllQuestions(pages, pagesOrder, sections, questions, questionGroups),
    [pages, pagesOrder, sections, questions, questionGroups]
  );

  const onShowListActionHandler = useCallback(() => {
    setConditions([
      {
        type: ActionBuilderConditionTypes.CONDITION,
        subject: null,
        criteria: null,
        values: [],
      },
    ]);
    setActions([{ action: null, subject: null }]);
    setId(null);
    setIsEditing(false);
    setPageToShow(ActionBuilderPages.ACTIONS_LIST);
  }, []);

  const onShowCreateActionHandler = useCallback(() => {
    setPageToShow(ActionBuilderPages.CREATE_ACTION);
  }, []);

  const onAddConditionHandler = useCallback(() => {
    setConditions((prevState) => [
      ...prevState,
      {
        type: ActionBuilderConditionTypes.OPERATOR,
        operator: RegularOperators.AND,
      },
      {
        type: ActionBuilderConditionTypes.CONDITION,
        subject: null,
        criteria: null,
        values: null,
      },
    ]);
  }, []);

  const onDeleteConditionHandler = useCallback(
    (index) => {
      const newConditions = [...conditions];
      if (index === 0) {
        newConditions.splice(index, 2);
      } else {
        newConditions.splice(index - 1, 2);
      }
      setConditions(newConditions);
    },
    [conditions]
  );

  const onChangeOperatorHandler = useCallback(
    (index) => {
      const newConditions = [...conditions];
      let changedOperator = newConditions[index];
      if (RegularOperators[changedOperator.operator] === RegularOperators.AND) {
        changedOperator = RegularOperators.OR;
      } else {
        changedOperator = RegularOperators.AND;
      }
      newConditions[index] = {
        type: ActionBuilderConditionTypes.OPERATOR,
        operator: changedOperator,
      };
      setConditions(newConditions);
    },
    [conditions]
  );

  const onChangeConditionHandler = useCallback(
    (index, attribute, changedCondition) => {
      const newConditions = [...conditions];
      const condition = newConditions[index];
      if (attribute === ActionsSelectValues.SUBJECT) {
        newConditions[index] = {
          type: ActionBuilderConditionTypes.CONDITION,
          subject: changedCondition,
          values: [],
          criteria: null,
        };
      } else if (attribute === ActionsSelectValues.CRITERIA) {
        newConditions[index] = {
          ...condition,
          criteria: changedCondition,
          values: [],
        };
      } else {
        newConditions[index] = { ...condition, [attribute]: changedCondition };
      }
      setConditions(newConditions);
    },
    [conditions]
  );

  const onAddActionHandler = useCallback(() => {
    setActions((prevState) => [...prevState, { action: null, subject: null }]);
  }, []);

  const onDeleteActionHandler = useCallback(
    (index) => {
      const newActions = [...actions];
      newActions.splice(index, 1);
      setActions(newActions);
    },
    [actions]
  );

  const onChangeActionHandler = useCallback(
    (index, attribute, changedAction) => {
      const newActions = [...actions];
      const action = newActions[index];
      if (attribute === ActionsSelectValues.ACTION) {
        newActions[index] = {
          action: changedAction,
          subject: null,
        };
      } else if (attribute === ActionsSelectValues.SUBJECT) {
        newActions[index] = {
          action: action.action,
          subject: changedAction,
        };
      } else if (
        action.action.value === AvailableActions.RANDOMIZE_CHOICES &&
        attribute !== ActionsSelectValues.CHOICES
      ) {
        newActions[index] = {
          ...action,
          [attribute]: changedAction,
        };
      } else {
        newActions[index] = {
          action: action.action,
          subject: action.subject,
          [attribute]: changedAction,
        };
      }
      setActions(newActions);
    },
    [actions]
  );

  const onEditActionHandler = useCallback(
    (changedActions, changedConditions, actionId) => {
      const conditionsOptions = [];
      changedConditions.forEach((condition) => {
        if (condition.type === ActionBuilderConditionTypes.CONDITION) {
          const values = condition.values.map((value) => ({
            label: value.title,
            value: { id: value.id, title: value.title },
          }));
          conditionsOptions.push({
            ...condition,
            subject: {
              label: condition.subject.title,
              value: {
                id: condition.subject.id,
                choices: allQuestions[condition.subject.id].choices,
              },
            },
            criteria: {
              label: condition.criteria,
              value: condition.criteria,
            },
            values,
          });
        } else {
          conditionsOptions.push({
            ...condition,
          });
        }
      });

      const actionsOptions = changedActions.map((action) => {
        if (action.choices) {
          const transformedChoices = action.choices.map((choice) => ({
            label: choice.title,
            value: { id: choice.id, title: choice.title },
          }));

          if (action.action === AvailableActions.RANDOMIZE_CHOICES) {
            const allChoices =
              allQuestions[action.subject.id]?.choices ||
              questionGroups[action.subject.id]?.questionGroup.choices;
            return {
              ...action,
              action: {
                label: action.action,
                value: action.action,
              },
              subject: {
                label: action.subject.title,
                value: {
                  id: action.subject.id,
                  choices: allChoices,
                },
              },
              choices: transformedChoices,
              randomize: { label: action.randomize, value: action.randomize },
            };
          }

          return {
            ...action,
            action: {
              label: action.action,
              value: action.action,
            },
            subject: {
              label: action.subject.title,
              value: {
                id: action.subject.id,
                choices:
                  allQuestions[action.subject.id]?.choices ||
                  questionGroups[action.subject.id]?.questionGroup.choices,
              },
            },
            choices: transformedChoices,
          };
        }
        if (action.randomize) {
          const transformedSubject = action.subject.map((question) => ({
            label: question.title,
            value: { id: question.id, title: question.title },
          }));
          return {
            action: {
              label: action.action,
              value: action.action,
            },
            subject: transformedSubject,
            randomize: { label: action.randomize, value: action.randomize },
          };
        }
        return {
          ...action,
          action: {
            label: action.action,
            value: action.action,
          },
          subject: {
            label: action.subject.title,
            value: {
              id: action.subject.id,
            },
          },
        };
      });

      setIsEditing(true);
      setConditions(conditionsOptions);
      setActions(actionsOptions);
      setId(actionId);
      onShowCreateActionHandler();
    },
    [onShowCreateActionHandler, questionGroups, allQuestions]
  );

  const canSubmit = useMemo(
    () =>
      conditions.every((condition) => {
        if (condition.type === ActionBuilderConditionTypes.CONDITION) {
          return (
            condition.subject !== null &&
            ((condition.criteria?.label !== ConditionOperators.HAS_A_RESPONSE &&
              condition.criteria?.label !==
                ConditionOperators.HAS_NO_RESPONSE &&
              condition.values.length > 0) ||
              ((condition.criteria?.label ===
                ConditionOperators.HAS_A_RESPONSE ||
                condition.criteria?.label ===
                  ConditionOperators.HAS_NO_RESPONSE) &&
                condition.values.length === 0))
          );
        }
        return true;
      }) &&
      actions.every((action) => {
        return (
          action.action !== null &&
          action.subject !== null &&
          ((action.action.value !== AvailableActions.RANDOMIZE_QUESTIONS &&
            action.action.value !== AvailableActions.RANDOMIZE_SECTIONS &&
            action.action.value !== AvailableActions.RANDOMIZE_PAGES &&
            action.action.value !== AvailableActions.RANDOMIZE_CHOICES) ||
            action.randomize) &&
          ((action.action.value !== AvailableActions.SHOW_CHOICES &&
            action.action.value !== AvailableActions.HIDE_CHOICES &&
            action.action.value !== AvailableActions.RANDOMIZE_CHOICES) ||
            action.choices?.length > 0)
        );
      }),
    [actions, conditions]
  );

  const onSubmitCreateHandler = useCallback(() => {
    const transformedConditions = conditions.map((condition) => {
      if (condition.type === ActionBuilderConditionTypes.CONDITION) {
        const values = mapActionConditionValuesToIds(condition);

        return {
          type: condition.type,
          subject: condition.subject.value.id,
          criteria: condition.criteria.label,
          values,
        };
      }
      return condition;
    });

    const transformedActions = actions.map((action) => {
      if (action.choices) {
        const transformedChoices = action.choices.map(
          (choice) => choice.value.id
        );
        if (action.randomize) {
          return {
            action: action.action.label,
            subject: action.subject.value.id,
            choices: transformedChoices,
            randomize: action.randomize.value,
          };
        }
        return {
          action: action.action.label,
          subject: action.subject.value.id,
          choices: transformedChoices,
        };
      }
      if (action.randomize) {
        const transformedSubject = action.subject.map(
          (subject) => subject.value.id
        );
        return {
          action: action.action.label,
          subject: transformedSubject,
          randomize: action.randomize.value,
        };
      }
      return {
        action: action.action.label,
        subject: action.subject.value.id,
      };
    });
    const createdAction = {
      id: uuid(),
      conditions: transformedConditions,
      actions: transformedActions,
    };
    onCreateAction(createdAction);
    setConditions([
      {
        type: ActionBuilderConditionTypes.CONDITION,
        subject: null,
        criteria: null,
        values: [],
      },
    ]);
    setActions([{ action: null, subject: null }]);
    onShowListActionHandler();
  }, [onCreateAction, conditions, actions, onShowListActionHandler]);

  const onSubmitEditHandler = useCallback(() => {
    const transformedConditions = conditions.map((condition) => {
      if (condition.type === ActionBuilderConditionTypes.CONDITION) {
        const values = mapActionConditionValuesToIds(condition);

        return {
          type: condition.type,
          subject: condition.subject.value.id,
          criteria: condition.criteria.label,
          values,
        };
      }
      return condition;
    });

    const transformedActions = actions.map((action) => {
      if (action.choices) {
        const transformedChoices = action.choices.map(
          (choice) => choice.value.id
        );
        if (action.randomize) {
          return {
            action: action.action.label,
            subject: action.subject.value.id,
            choices: transformedChoices,
            randomize: action.randomize.value,
          };
        }
        return {
          action: action.action.label,
          subject: action.subject.value.id,
          choices: transformedChoices,
        };
      }
      if (action.randomize) {
        const transformedSubject = action.subject.map(
          (subject) => subject.value.id
        );
        return {
          action: action.action.label,
          subject: transformedSubject,
          randomize: action.randomize.value,
        };
      }
      return {
        action: action.action.label,
        subject: action.subject.value.id,
      };
    });
    const editedAction = {
      id,
      conditions: transformedConditions,
      actions: transformedActions,
    };
    onEditAction(editedAction);
    onShowListActionHandler();
    setSearching(null);
  }, [onEditAction, id, conditions, actions, onShowListActionHandler]);

  return (
    <div className={classNames('modal', 'is-active', classes.modal)}>
      <div
        id="action-builder-background"
        className="modal-background"
        onClick={onClose}
      />
      <div className={classNames('modal-card', classes['modal-card'])}>
        <header className="modal-card-head">
          <div
            className={classNames(
              'level',
              'is-mobile',
              classes['modal-header']
            )}
          >
            <div className="level-left">
              <div className="level-item">
                <p
                  className={classNames(
                    'modal-card-title',
                    'title',
                    'is-4',
                    classes['modal-title']
                  )}
                >
                  Actions
                </p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                {pageToShow === ActionBuilderPages.ACTIONS_LIST && (
                  <button
                    id="action-builder-show-create"
                    className="button is-primary"
                    aria-label="create"
                    onClick={onShowCreateActionHandler}
                  >
                    Create action
                  </button>
                )}
              </div>
              <div className="level-item">
                <button
                  id="action-builder-close"
                  className="delete"
                  aria-label="close"
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
        </header>
        {pageToShow === ActionBuilderPages.ACTIONS_LIST && (
          <ActionsList
            actions={actionsList}
            allQuestions={allQuestions}
            questions={questions}
            questionGroups={questionGroups}
            textBoxes={textBoxes}
            sections={sections}
            pages={pages}
            pagesOrder={pagesOrder}
            onEditAction={onEditActionHandler}
            onDeleteAction={onDeleteAction}
            searchItem={searching}
          />
        )}
        {pageToShow === ActionBuilderPages.CREATE_ACTION && (
          <CreateAction
            onShowListAction={onShowListActionHandler}
            conditions={conditions}
            onAddCondition={onAddConditionHandler}
            onDeleteCondition={onDeleteConditionHandler}
            onChangeOperator={onChangeOperatorHandler}
            onChangeCondition={onChangeConditionHandler}
            actions={actions}
            onAddAction={onAddActionHandler}
            onDeleteAction={onDeleteActionHandler}
            onChangeAction={onChangeActionHandler}
            isEditing={isEditing}
            onCreate={onSubmitCreateHandler}
            onEdit={onSubmitEditHandler}
            canSubmit={canSubmit}
            questions={questions}
            questionGroups={questionGroups}
            allQuestions={allQuestions}
            textBoxes={textBoxes}
            sections={sections}
            pages={pages}
            pagesOrder={pagesOrder}
          />
        )}
      </div>
    </div>
  );
};

ActionBuilderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreateAction: PropTypes.func.isRequired,
  onEditAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  sections: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  textBoxes: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  questions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  questionGroups: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pages: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pagesOrder: PropTypes.arrayOf(PropTypes.string),
  actionsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default ActionBuilderModal;
